@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap");

body {
  font-family: "Nunito", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #222324;
  background-color: #edf2f6;
}
a p {
  font-size: 16px !important;
}

.allcomment .commentin.commentset {
  border-bottom: 1px solid #e9e9e9;
}
.send {
  margin-right: 10px;
}
.send img {
  width: 18px !important;
}
.replayimg img {
  width: 30px !important;
  height: 30px;
  border-radius: 50%;
  object-fit: contain;
}
.replyin {
  position: relative;
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
}
.replyin h5 {
  font-size: 14px !important;
}

/*iframe{
    position: relative !important;
}*/
.mb-3,
.my-3 {
  margin-bottom: 1.8rem !important;
}
.displayed-text {
  word-break: break-word;
}

.commentin p {
  word-break: break-word;
}

.inbanner span.uploadImg {
  position: absolute;
  right: 15px;
  bottom: 15px;
  background: #396afb;
  padding: 10px 15px;
  border-radius: 3px;
  width: 150px;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}

.inbanner span.uploadImg img.camerai {
  width: 25px;
  margin-right: 6px;
}

.inbanner span.uploadImg input#userimage {
  position: absolute;
  left: 0;
  height: 100%;
  top: 0;
  opacity: 0;
}

.listusr.favoriteclass.notification .test {
  background: #016afa1c;
  height: 100%;
  margin: 0;
}

.listusr.favoriteclass.notification .test.active {
  background: #fff;
}

.side_right.nav_right {
  padding: 15px 0 0 0;
  height: auto;
}

.side_right.nav_right .asuser {
  height: 64px;
}

/* .emoji-picker-react{
    display: none!important;
} */
.setsearchdata ul li + li a,
.setsearchdata ul li.postsearch + li.postsearch {
  border-top: 1px solid #ffffff4a;
}

.setsearchdata ul li a:hover,
.setsearchdata ul li.postsearch:hover {
  background: #000000ad;
}

.setsearchdata ul li a,
.setsearchdata ul li.postsearch {
  color: #fff;
  padding: 10px 20px;
  display: block;
  width: 100%;
  float: left;
  background: #016afb;
}

.setsearchdata ul li {
  display: block;
  width: 100%;
  float: left;
}

.fa-flag {
  color: red;
  z-index: 99;
  position: relative;
  top: 5px;
}

.setsearchdata {
  position: absolute;
  background: #fff;
  top: 56px;
  box-shadow: 0 0 20px 0 #eaeff4;
  width: 96%;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 9;
  max-height: 218px;
  overflow-y: auto;
}

.setsearchdata ul {
  display: grid;
  margin-bottom: 0;
}

.setsearchdata ul li a i,
.setsearchdata ul li.postsearch i {
  float: right;
  margin-top: 4px;
}

.showposts {
  float: left;
  width: 100%;
  margin-top: -60px;
}

.showposts li {
  float: left;
  background: #ccc;
  padding: 10px 15px;
  width: 150px;
  text-align: center;
  margin-right: 10px;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
}

.allctg {
  margin-bottom: 25px;
}

.allctg .text img {
  height: 60px;
}

.allctg .text {
  text-align: center;
  border: 1px solid #ddd;
  padding: 30px 10px;
  border-radius: 16px;
  box-shadow: 0 0 25px 0 #d5d5d5bf;
  cursor: pointer;
}

.allctg .text h3 {
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 0;
}

.showposts li.active {
  background: #016afb;
  color: #fff;
}

.norecord {
  text-align: center;
  margin-top: 80px;
}

.norecord img {
  width: 50%;
  opacity: 0.6;
}

.loadingicon img {
  width: 122px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
}

.loadingicon {
  position: fixed;
  width: 100%;
  background: #ffffff5e;
  left: 0;
  top: 0;
  height: 100%;
  text-align: center;
  line-height: 100%;
  z-index: 999999999;
  display: none;
}

.bus_det button {
  background: #fff;
  border: navajowhite;
  padding: 10px 44px;
  font-weight: 600;
  border-radius: 5px;
  font-size: 16px;
}

.bus_det .tes h4 .fa-edit {
  float: right;
  margin-top: 7px;
}

.bus_det .tes h4 .form-control {
  width: 100%;
  display: inline-block;
  margin-left: 0;
  border: none;
}

.input-tag__tags {
  display: inline-flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  width: 100%;
}

.input-tag__tags li {
  align-items: center;
  background: #85a3bf;
  border-radius: 2px;
  color: white;
  display: flex;
  font-weight: 300;
  list-style: none;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px 10px;
}

.input-tag__tags li button {
  align-items: center;
  appearance: none;
  background: #333333;
  border: none;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  display: inline-flex;
  font-size: 16px;
  height: 15px;
  justify-content: center;
  line-height: 0;
  margin-left: 8px;
  padding: 0;
  transform: rotate(45deg);
  width: 15px;
}

.input-tag__tags li.input-tag__tags__input {
  background: none;
  flex-grow: 1;
  padding: 0;
}

.phone-icon {
  display: none;
}

.loginpage.vipsignup .form-group input.form-control {
  margin-bottom: 10px;
}

.loginpage.vipsignup .right_login form {
  margin-top: 30px;
}

.App {
  text-align: left !important;
}

.dashboard .user.usernew .uphead .userimg img {
  width: 100%;
}

.dashboard .user .userimg img {
  height: 100%;
}

#errorlogin {
  display: none;
}

#successlogin {
  display: none;
}

#errorcoupon {
  display: none;
  color: red;
}

#successcoupon {
  display: none;
  color: rgb(80, 238, 80);
}

#addtag {
  display: none;
}

#addfriendtag {
  display: none;
}

.replyid {
  display: none;
}

ul {
  padding: 0;
}

ul li {
  list-style: none;
}

a {
  text-decoration: none !important;
}

a:hover {
  opacity: 0.8;
}

.profile_int .container {
  max-width: 1140px;
}

.profile_int {
  margin: 60px 0;
}

.profile_int .form-group {
  text-align: left;
}

.profile_int .form-group input {
  height: auto;
  padding: 10px 15px;
}

.profile_int .form-group label {
  font-weight: 600;
}

.profile_int button.btn.btn-primary {
  background: #016afa;
  padding: 10px 40px;
  font-weight: 600;
}

.download li {
  list-style: none;
  border-bottom: 1px solid #f5f5f5;
  padding: 18px 10px;
}

.download {
  padding: 0;
}

.reviews .test {
  padding-left: 100px;
  position: relative;
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 20px;
  padding-top: 20px;
  margin: 10px 0;
}

.reviews .test img {
  border-radius: 50%;
  height: 60px;
  width: 60px;
  position: absolute;
  left: 0;
}

.reviews p span {
  color: #a6b0b9;
  margin-left: 10px;
}

.reviews h4 {
  font-size: 16px;
  color: #ebc50c;
}

.reviews h6 {
  font-size: 16px;
  color: #626262;
}

.profile_int .card h3 {
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid #cccc;
  padding: 20px 0;
}

.Steve .userimg i.fas.fa-camera {
  position: absolute;
  bottom: 13px;
  z-index: 999999999999;
  left: 0;
  right: 0;
  margin: auto;
  font-size: 40px;
  color: #fed500;
}

.profile_int .card {
  padding-left: 270px;
  position: relative;
  padding-right: 30px;
  min-height: 600px;
}

.profile_int .card .profile_side {
  position: absolute;
  left: 0;
  background: #4a4a4a;
  height: 100%;
  width: 250px;
}

.profile_int .card .profile_side .userimg i {
  position: absolute;
  bottom: 11px;
  left: 0;
  right: 0;
  font-size: 30px;
  color: white;
  z-index: 9;
  margin: auto;
  text-align: center;
}

.profile_int .card ul.tab-btn li .btn-link {
  font-weight: 600;
  color: #016afb;
  width: 100%;
  text-align: left;
  padding: 10px 20px;
  background: #fff;
  border-radius: 0;
  margin-bottom: 1px;
  text-decoration: none;
}

.profile_int form {
  margin-top: 80px;
}

.profile_int .card .profile_side .userimg input[type="file"] {
  width: 100%;
  position: absolute;
  height: 100%;
  opacity: 0;
}

.profile_int .card .profile_side .userimg {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #fff;
  margin: 30px auto 0;
  position: relative;
}

.profile_int .card .profile_side h3 {
  color: #fff;
  text-align: center;
  font-size: 16px;
  border: none;
  padding: 0;
  margin-top: 20px;
}

.profile_int .card .profile_side p {
  color: #ffffffc4;
  text-align: center;
  font-size: 16px;
  padding: 0 15px 20px;
  border-bottom: 1px solid #828282;
  margin-bottom: 0;
}

.chatstart .container_left img:first-child {
  width: 30px;
  position: absolute;
  left: 10px;
  border-radius: 50%;
}

.chatstart .container_left.darker {
  padding: 10px 65px 10px 10px;
  text-align: left;
  margin-left: 15%;
}

.chatstart .container_left {
  position: relative;
  width: 80%;
  border: 1px solid #e8e8e8;
  margin: 10px 0;
  padding: 10px 10px 4px 68px;
  font-size: 16px;
  margin-right: 15%;
  border-radius: 10px;
}

.chatstart .container_left.darker img:first-child {
  width: 30px;
  position: absolute;
  right: 10px;
  left: inherit;
  border-radius: 50%;
}

.chatstart {
  height: 270px;
  overflow-y: scroll;
}

.chatstart .container_left p {
  margin-bottom: 3px;
  line-height: 19px;
  color: #626262;
}

.chatstart .container_left span {
  color: #d5d3d3;
  font-size: 12px;
}

.chat-popup h1 {
  background: #fff;
  margin: 0;
  padding: 15px;
  border-bottom: 1px solid #e8e8e8;
  font-size: 16px;
}

.appendchatuser.minimize form.form-container {
  display: none;
}

.chat-popup h1 span .fa-window-maximize {
  display: none;
}

.chat-popup h1 span {
  float: right;
  margin-right: 10px;
  position: absolute;
  right: 53px;
  color: #396afb;
  margin-top: -4px;
}

.chat-popup h1 button.btn.cancel {
  float: right;
  color: #ccc;
  font-size: 16px;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: -4px;
}

.appendchatuser.minimize h1 span .fa-window-minimize {
  display: none;
}

.appendchatuser.minimize h1 span .fa-window-maximize {
  display: block;
  margin-top: 6px;
}

/* Button used to open the chat form - fixed at the bottom of the page */
.open-button {
  background-color: #555;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  opacity: 0.8;
  position: fixed;
  bottom: 23px;
  right: 28px;
  width: 280px;
  z-index: 999;
}

/* The popup chat - hidden by default */
.chat-popup {
  /* display: none; */
  position: fixed;
  bottom: 0;
  right: 15px;
  z-index: 9;
}

.chat-popup .appendchatuser {
  float: left;
  margin-left: 12px;
  box-shadow: 0 0 12px #3387fa33;
  border: 1px solid #ddd;
  width: 320px;
  position: relative;
  height: 100%;
  /* height: 580px; */
}

.appendchatuser.minimize {
  box-shadow: none;
  border: none;
  position: relative;
}

.appendchatuser.minimize h1 {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  border: 1px solid #ddd;
}

/* Add styles to the form container */
.form-container {
  max-width: 100%;
  padding: 10px 15px;
  background-color: white;
}

/* Full-width textarea */
.form-container textarea {
  width: 100%;
  padding: 10px 15px;
  margin: 5px 0 5px 0;
  border: none;
  background: #f1f1f1;
  resize: none;
  min-height: 50px;
}

/* When the textarea gets focus, do something */
.form-container textarea:focus {
  background-color: #ddd;
  outline: none;
}

/* Set a style for the submit/send button */
.form-container .btn {
  background-color: #016afa;
  color: white;
  padding: 12px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom: 10px;
  opacity: 0.8;
}

.commingsoon .commingin h3 {
  font-size: 50px;
  font-weight: 400;
}

.commingsoon.text-center {
  background: #016afb;
  height: 100%;
  display: ruby-text-container;
  width: 100%;
  position: fixed;
  color: #fff;
}

.commingsoon .commingin {
  position: absolute;
  width: 350px;
  height: 310px;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.commingsoon .commingin img {
  width: 200px;
}

.commingsoon .commingin h3 {
  font-size: 50px;
  font-weight: 400;
}

.form-container .btn.cancel {
  background: #ff0000a6;
}

.side_b {
  display: none;
  z-index: 9;
}

.add_create .ck-editor__editable_inline {
  height: 200px;
  padding: 0 20px !important;
}

.add_create .ck-editor__editable_inline p {
  padding: 0 !important;
  font-size: 16px !important;
}

.loginpage {
  height: 100%;
  /*    background-image: url(../../public/images/loginbg.jpg);
    background-size: 50%;*/
}

.main_menu ul li {
  float: left;
  margin-right: 50px;
}

.main_menu ul {
  margin: 0;
}

.main_menu {
  display: flex;
  margin-bottom: 0;
  position: relative;
  padding: 40px 30px 60px;
}
.businessddl.nestes h4 span {
  margin-left: 0 !important;
}

.main_menu ul li.active {
  border-bottom: 2px solid #e2e2e2;
}

.main_menu ul li a.active {
  border-bottom: 0px solid #e2e2e2;
  padding: 0 !important;
  background-color: transparent !important;
  color: #000 !important;
}

.main_menu ul li a {
  color: #000;
  font-size: 16px;
  font-weight: 600;
}

.showchatt .testin + .testin {
  border-top: 1px solid #e2e2e2;
  padding-top: 12px;
}

.showchatt .testin {
  position: relative;
  cursor: pointer;
  padding: 8px 12px 8px 84px;
  background: #fff;
}

.showchatt .testin:after {
  left: 70px;
  background: #e2e2e2;
  content: "";
  height: 38px;
  width: 1px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}

/* .chat-popup h1 button.btn.cancel {
    float: right;
    color: #ccc;
    font-size: 16px;
} */
.showchatt .testin:hover {
  background: #fff;
}

.showchatt .all.mmss {
  border: 1px solid #e2e2e2;
  overflow-y: auto;
  max-height: 327px;
  background-color: #fff;
  -webkit-font-smoothing: subpixel-antialiased;
}

.showchatt .testin .images {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 15px;
  border-radius: 50%;
  overflow: hidden;
}

.showchatt .testin h4 {
  font-size: 16px;
  font-weight: 600;
  color: #016afa;
  margin-bottom: 0;
}

.showchatt .testin h6 {
  font-size: 16px;
  color: #626262;
  margin-bottom: 0;
  font-weight: 100;
}

.showchatt .testin p {
  font-size: 16px;
  color: #bdbdbd;
  margin-bottom: 0;
}

.showchatt .testin .images img {
  width: 100%;
}

.loginpage .right_login {
  padding: 0 200px 0 100px;
  padding-top: 10%;
  position: fixed;
  background-size: cover;
  right: 0;
  width: 50%;
  height: 100%;
  overflow-y: auto;
}

.loginpage .right_login h3 {
  font-size: 28px;
  color: #737583;
  font-weight: bold;
}

.loginpage .right_login label {
  font-size: 16px;
  color: #737583;
  opacity: 50%;
  font-weight: 600;
  margin-bottom: 8px;
}

.loginpage .right_login .btn {
  background: #016afb;
  border: navajowhite;
  border-radius: 55px;
  color: #fff;
  padding: 14px 8px;
  width: 100%;
  margin-bottom: 40px;
  font-size: 16px;
  font-weight: 600;
}

.loginpage .imglogin {
  background: #016afb;
  position: fixed;
  left: 0;
  width: 50%;
  height: 100%;
  text-align: center;
  padding-top: 14%;
}

.loginpage p {
  color: #737583;
  font-size: 16px;
  font-weight: 400;
}

.loginpage .gologin {
  position: absolute;
  left: 21px;
  z-index: 9;
  top: 20px;
}

.loginpage h6 {
  color: #737583;
  font-size: 16px;
  font-weight: 400;
}

.loginpage .form-group {
  position: relative;
}

.loginpage .form-group i {
  position: absolute;
  right: 15px;
  bottom: 15px;
  color: #626262;
  font-size: 16px;
}

.loginpage h6 a {
  color: #016afb;
  text-decoration: underline;
  font-weight: 600;
}

.loginpage .form-group .form-control {
  font-size: 16px;
  border: none;
  padding: 12px 20px;
  box-shadow: 0 8px 24px #cacacaa6;
  margin-bottom: 16px;
  border-radius: 8px;
  height: auto;
}

.loginpage .right_signup .btn {
  margin-top: 20px;
  width: 50%;
}

.loginpage .right_login form {
  margin-top: 40px;
}

.lgn {
  padding: 0 100px;
}

.loginpage .imglogin img {
  width: 250px;
}

.loginpage .plan {
  background: #016afb;
  padding: 15px 30px;
  display: inline-block;
  border-radius: 26px;
  color: #fff;
  min-width: 280px;
  margin-bottom: 20px;
  margin-top: -55px;
}

.loginpage .plan h5 {
  font-size: 16px;
}

.loginpage .plan h2 {
  font-size: 30px;
  margin-bottom: 0;
  font-weight: bold;
}

.loginpage .plan h2 span {
  font-size: 16px;
  font-weight: 400;
}

/******************** loginpagetow *********************/

.loginpagetow .gologin {
  position: absolute;
  left: 21px;
  z-index: 9;
  top: 20px;
}

.loginpagetow .gologin img,
.loginpage .gologin img {
  width: 16px;
}

.loginpagetow .signtwo {
  padding: 40px;
  border-radius: 40px;
}

.loginpagetow ul li {
  list-style: none;
  color: #404041;
  font-size: 16px;
  margin-bottom: 18px;
  position: relative;
  padding-left: 32px;
}

.dropedit {
  position: relative;
}
.dropedit i {
  position: absolute;
  top: 20px;
  right: 15px;
  z-index: 99;
}
.loginpagetow h2 {
  color: #016afb;
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 35px;
  padding: 0;
}

.loginpagetow {
  padding-top: 110px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: #fff;
}

.loginpagetow ul {
  margin-top: 40px;
}

.loginpagetow .signone {
  padding: 40px;
}

.signone .images img,
.signtwo .images img {
  width: 90px;
}

.loginpagetow .nextbutton {
  background: #016afb;
  border: navajowhite;
  border-radius: 55px;
  color: #fff;
  padding: 14px 8px;
  width: 250px;
  margin-bottom: 60px;
  font-size: 16px;
  display: inline-block;
  text-align: center;
  margin-top: 30px;
  font-weight: bold;
}

.loginpagetow .container-fluid {
  padding-right: 20px;
  padding-left: 20px;
}
img.vip-feed {
  height: 50px;
}
.loginpagetow ul li:after {
  background-image: url(../../public/images/check1.png);
  position: absolute;
  left: 0;
  width: 22px;
  height: 22px;
  content: "";
  background-repeat: no-repeat;
  top: 0;
  background-size: 22px;
  bottom: 0;
  margin: auto;
}

.loginpagetow ul li span {
  color: #016afb;
  font-weight: bold;
}

.loginpagetow ul li.cls:after {
  background-image: url(../../public/images/close1.png);
  background-size: auto;
  text-align: center;
  background-position: center;
}

.loginpagetow .signtwo .images img.vip {
  vertical-align: top;
  width: 60px;
}

/***************** maindiv *******************/
.maindiv {
  padding: 0 0 0 250px;
  position: relative;
}

.maindiv .sidbar_left {
  position: fixed;
  left: 0;
  background: #016afa;
  width: 250px;
  overflow-y: auto;
  height: 100%;
  top: 0;
  padding-bottom: 40px;
  overflow-x: hidden;
  overflow-y: hidden;
  /* margin-left: -290px;*/
  padding-top: 100px;
}

.maindiv .logo img {
  width: 115px;
}

.maindiv .logo {
  width: 100%;
  text-align: center;
  padding: 25px 0;
  border-bottom: 1px solid #ffffff30;
}

.sidbar_left ul li a {
  width: 100%;
  color: #fff;
  padding: 0px 15px;
  display: inline-block;
  font-size: 16px;
  /* border-bottom: 1px solid #1b7afc; */
  min-height: 44px;
  line-height: 41px;
  text-decoration: none;
}

.sidbar_left ul li a:hover {
  background: #ffffff36;
  color: #fff;
  font-weight: 600;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  border: none;
}

.sidbar_left ul li a.active {
  background: #ffffffad;
  color: #016afb;
  font-weight: 600;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  border: none;
}

.sidbar_left h4 {
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  padding: 15px 20px 20px;
  border-bottom: 1px solid #1b7afc;
}

.sidbar_left ul li a span img {
  max-height: 20px;
}

.sidbar_left .seemore {
  color: #fff;
  text-decoration: underline;
  font-size: 16px;
  margin-left: 20px;
}

.sidbar_left ul li a span {
  width: 55px;
  display: inline-block;
  text-align: center;
}

.in_center .head a.hpl:hover {
  background: #000000ad;
  color: #fff;
}

.in_center.help {
  padding-right: 30px;
}

.in_center .head a.hpl {
  background: #016afb;
  padding: 14px 0;
  color: #fff;
  border-radius: 30px;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 16px;
  min-width: 205px;
  text-align: center;
  text-decoration: none;
  font-weight: 500;
}

.in_center .head a.hpl img {
  width: 28px;
  margin-right: 3px;
}

.in_center .head {
  position: relative;
  padding-right: 220px;
}

.in_center .head form {
  border-radius: 30px;
  height: 55px;
  background: #fff;
  box-shadow: 0 0 20px 0 #eaeff4;
  position: relative;
}

.in_center .head form input {
  border: none;
  height: 100%;
  padding: 10px 30px;
  font-size: 16px;
  border-radius: 30px;
  outline: none;
  box-shadow: none;
}

.in_center {
  padding: 0px 30px 30px;
}

.in_center .head .btn {
  width: 100px;
  padding: 0;
  margin: 0;
}
.in_center .head select {
  background: none;
}

.addpost {
  margin-top: 40px;
  box-shadow: 0 0 20px 0 #eaeff4;
  background: #fff;
  border-radius: 20px;
  padding: 24px 40px 100px 40px;
  margin-bottom: 25px;
}

.addpost h3 {
  color: #737583;
  font-weight: bold;
  margin-bottom: 20px;
  border-bottom: 1px solid #e6eaee;
  padding-bottom: 20px;
  margin-bottom: 25px;
  font-size: 24px;
}

.addhead textarea::placeholder {
  color: #737583;
}

.addpost .btn {
  background: #016afb;
  padding: 10px 50px;
  color: #fff;
  border-radius: 30px;
  font-size: 16px;
  position: absolute;
  right: 0;
  bottom: -75px;
  text-align: center;
  font-weight: 600;
}

.addpost .addhead {
  padding-left: 90px;
  position: relative;
}

.addpost .addhead span.userimg {
  position: absolute;
  left: 0;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  border: 3px solid #edf2f6;
  overflow: hidden;
}

.addpost .addhead span.userimg img {
  width: 100%;
}

.addhead textarea {
  font-size: 16px;
  padding: 10px 20px;
  border: 1px solid #f4f4f4;
  height: 120px;
  outline: none;
  box-shadow: none;
}

.addpost .addhead ul li {
  float: left;
  margin-right: 26px;
  position: relative;
}

.addpost .addhead ul {
  position: absolute;
  bottom: -82px;
}

.addpost .addhead ul li img {
  height: 20px;
}

/* header-top */
.sarech-filed.in_center {
  width: 100%;
  max-width: 1100px;
}
.addpost.addnew-post {
  margin-top: 15px;
}
.Mainheader-wapper {
  background: #016afa;
  display: flex;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  z-index: 9999;
  left: 0;
  align-items: center;
  padding: 13px 30px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.logo-site img {
  max-width: 50px;
  width: 100%;
}
.ticket-top {
  margin-top: 15px;
  box-shadow: 0 0 20px 0 #eaeff4;
  background: #fff;
  border-radius: 20px;
  padding: 20px;
}

.sarech-filed.in_center {
  min-height: unset;
}

.in_center.new-row {
  margin-top: 25px;
}

/* .in_center {
    margin-top: 40px;
} */

.admin-right .side_right {
  padding: 0 !important;
  width: unset !important;
  position: unset;
  height: unset !important;
  justify-content: space-between;
  align-items: center;
}

.admin-right .side_right .asuser {
  height: unset;
}

.sarech-filed.in_center {
  padding-left: 0;
}

.admin-right .logout {
  background: #ffffff;
  border-radius: 8px;
  color: #016afa;
  font-size: 12px;
  font-weight: 600;
  min-width: 35px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.sarech-filed.in_center {
  min-height: unset;
  padding-bottom: 0;
}

.sarech-filed.in_center .head a.hpl {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.sarech-filed.in_center .head form {
  /* background: #00000057; */
  box-shadow: unset !important;
}

.logo-site {
  max-width: 220px;
  width: 100%;
}

/* .sarech-filed.in_center .head form input{
    background:transparent;
} */
/* header-end */

/********************* select_category *********************/
.select_category a.btnfilter img {
  width: 28px;
}

.select_category a.btnfilter {
  position: absolute;
  right: 0;
  padding: 8px 16px;
  color: #fff;
  border-radius: 0 24px 0 15px;
  font-size: 16px;
  top: -45px;
  padding: 0;
}

.select_category {
  position: relative;
  min-height: 0px;
  margin-top: 70px;
}

.select_category .select_category_in {
  border-radius: 25px;
  box-shadow: 0 0 20px 0 #eaeff4;
  background: #fff;
  padding: 25px 15px 0;
}

.select_category h3 {
  color: #016afb;
  text-align: center;
  margin-bottom: 25px;
  font-weight: bold;
  font-size: 24px;
}

.select_category .category_carousel {
  position: relative;
  padding: 0 50px;
}

.select_category .category_carousel .owl-item img {
  width: auto;
  display: inline-block;
  height: 55px;
}

.select_category .category_carousel .owl-item {
  text-align: center;
}

.select_category .category_carousel .owl-item h5 {
  color: #737583;
  font-size: 16px;
  margin-top: 15px;
}

.select_category .category_carousel .owl-nav.disabled {
  display: block;
}

.select_category .category_carousel button.owl-prev {
  width: 28px;
  position: absolute;
  top: 0;
  height: 45px;
  margin: auto;
  border-radius: 8px;
  font-size: 35px !important;
  color: #fff !important;
  line-height: 0 !important;
  left: -0;
  background: #016afb !important;
  bottom: 0;
  outline: none;
}

.select_category .category_carousel button:hover {
  opacity: 0.8;
}

.select_category .category_carousel button.owl-next {
  width: 28px;
  position: absolute;
  top: 0;
  bottom: 0;
  height: 45px;
  margin: auto;
  border-radius: 8px;
  font-size: 35px !important;
  color: #fff !important;
  line-height: 0 !important;
  right: -0;
  background: #016afb !important;
  outline: none;
}

.select_category .category_carousel button span {
  position: absolute;
  left: 9px;
  top: 21px;
}

.listusr .asuser h5 {
  font-size: 16px;
  color: #016afa;
  font-weight: bold;
  text-transform: uppercase;
  word-break: break-all;
}

.listusr .asuser {
  position: relative;
  margin-bottom: 0px;
  padding: 0 20px 0px 75px;
}

.listusr .asuser p {
  margin-bottom: 4px;
}

.listusr {
  margin-top: 25px;
}

.listusr .asuser .userimg {
  position: absolute;
  left: 0;
  width: 21%;
  height: auto;
  top: 0;
  border-radius: 100%;
  overflow: hidden;
}

.listusr .asuser .userimg img {
  width: 100%;
}

.listusr .asuser .dropdown {
  position: absolute;
  right: 17px;
  top: 18px;
}

.listusr .asuser .dropdown span.dropdown-toggle img,
.listusr .asuser .dropdown .dropdown-toggle i {
  display: block;
}

.listusr .asuser .dropdown .dropdown-toggle::after {
  display: none;
}

.listusr .asuser .dropdown .dropdown-toggle {
  text-align: center;
  font-size: 26px;
}

.listusr ul.likecomment li {
  float: left;
  padding-left: 51px;
  margin-right: 30px;
  position: relative;
  height: 38px;
  line-height: 38px;
  font-weight: 600;
  font-size: 16px;
}

.listusr ul.likecomment {
  display: inline-block;
  margin-top: 20px;
  margin-bottom: 0;
}
.react-share__ShareButton svg {
  vertical-align: super;
}

.listusr ul.likecomment li img {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 20px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.listusr .test {
  background: #fff;
  padding: 28px;
  margin-bottom: 35px;
  border-radius: 25px;
  box-shadow: 0 0 20px 0 #eaeff4;
}

.listusr .asuser .dropdown .dropdown-menu {
  background: #016afa;
  right: 0;
  left: inherit !important;
  top: 5px !important;
  transform: translate3d(0px, 26px, 0px) !important;
  padding: 0;
  border: none;
}

.listusr .asuser .dropdown .dropdown-menu a:hover {
  background: #ffffff36;
  opacity: 1;
}

.listusr .asuser .dropdown .dropdown-menu a {
  color: #fff;
  opacity: 0.6;
  padding: 7px 16px;
  font-size: 16px;
}

.listusr .asuser .dropdown .dropdown-menu:after {
  background: #016afa;
  height: 16px;
  width: 16px;
  content: "";
  position: absolute;
  right: 2px;
  top: -7px;
  border-radius: 3px;
  transform: rotate(45deg);
}

.select_category .test {
  margin-bottom: 20px;
  padding: 5px 27px;
  border-radius: 15px;
  display: inline-block;
}

.select_category .owl-dots {
  display: none;
}

.select_category .test:hover {
  box-shadow: 0 3px 11px 0px #016afa38;
}

/*.listusr .asuser .dropdown .dropdown-menu a:hover {
    background: transparent;
    opacity: 1;
}*/
.listusr .testin {
  border-radius: 22px;
  overflow: hidden;
  border: 12px solid #ededed;
  margin-top: 20px;
  height: auto;
  /* min-height: 350px;
    min-width: 500px; */
}

.listusr .testin img {
  width: 100%;
}

.listusr .testin video {
  width: 100%;
  height: 100%;
  background: black;
}

.listusr .test img.hearticon {
  float: right;
  margin-top: -48px;
  width: 40px;
  filter: grayscale(100%);
}

.listusr .test img.hearticon.main {
  filter: grayscale(1%);
}

.listusr .test img.hearticon:hover {
  opacity: 0.7;
}

.likeshare ul li {
  float: left;
  padding-left: 60px;
  margin-right: 30px;
  position: relative;
  height: 38px;
  line-height: 38px;
  font-weight: 600;
  font-size: 16px;
}

.likeshare ul {
  display: inline-block;
  margin-bottom: 0;
  position: absolute;
  left: 0;
  top: 43px;
}

.likeshare ul li img {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 34px;
}

.likeshare {
  border-top: 1px solid #e9e9e9;
  margin-top: 0;
  padding-top: 34px;
  padding-bottom: 00px;
  padding-left: 0;
  position: relative;
  display: flex;
}

.likeshare .comment {
  background: #016afb;
  padding: 0px 20px;
  color: #fff;
  border-radius: 30px;
  font-size: 16px;
  float: right;
  text-align: center;
  position: absolute;
  right: 0;
  top: 0;
  border: none;
  height: 52px;
  font-weight: 600;
}

/************ comment **********/
.allcomment .commentin ul.likecomment li {
  float: left;
  padding-left: 40px;
  margin-right: 25px;
  position: relative;
  height: 28px;
  line-height: 27px;
  font-weight: 600;
  font-size: 16px;
  color: #868686;
}

.allcomment .commentin p {
  margin-bottom: 0;
  color: #737583;
  font-size: 16px;
}

.allcomment .commentin ul.likecomment {
  display: inline-block;
  margin-top: 15px;
  margin-bottom: 0;
}

.allcomment .commentin ul.likecomment li img {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 20px;
}

.allcomment .commentin {
  padding: 20px 0px 20px 80px;
  position: relative;
}

.allcomment .commentin + .commentin {
  border-top: 1px solid #e9e9e9;
}

.allcomment .commentin span.userimg {
  width: 50px;
  position: absolute;
  left: 0px;
  overflow: hidden;
  border-radius: 50%;
  height: 50px;
  top: 20px;
}

.messages.chats:after {
  background: #cdcdcd;
  position: absolute;
  height: 100%;
  width: 1px;
  content: "";
  top: 0;
}

.help .commentin span.userimg {
  width: 55px;
  left: 15px;
  top: 0;
  height: 55px;
}

.help .likeshare {
  padding-top: 20px;
}

.allcomment .commentin .replyid input {
  height: 50px;
  border-radius: 10px;
  padding: 10px 20px;
  box-shadow: 0 5px 30px 0 #d6d6d6;
  border: none;
}

.allcomment .commentin .replyid {
  padding-right: 180px;
  position: relative;
  margin-top: 10px;
}

.allcomment .commentin .replyid button.comment {
  position: absolute;
  right: 30px;
  top: 0;
  background: #9e9e9e;
  border: navajowhite;
  padding: 13px;
  height: 50px;
  border-radius: 30px;
  color: #fff;
  font-weight: 500;
  width: 136px;
}

.allcomment .commentin h5 {
  color: #016afb;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 2px;
}

.allcomment.singlediscussionsing .commentin {
  padding-top: 20px;
}

.allcomment.singlediscussionsing {
  padding-top: 0;
}

.allcomment.singlediscussionsing .commentin span.userimg {
  top: 20px;
}

.allcomment.singlediscussionsing .commentin .replyid button.comment {
  position: absolute;
  right: 0;
}

.allcomment.singlediscussionsing .commentin .replyid {
  padding-right: 150px;
}

.allcomment {
  background: #fff;
  border-top: 1px solid #e9e9e9;
  margin-top: 20px;
  padding-top: 0;
}

.allcomment .commentin h5 .reportbtn {
  float: right;
  background: #016afa;
  color: #fff;
  font-size: 16px;
  padding: 6px 14px;
  border-radius: 5px;
  margin-right: 0px;
}

.likeshare form {
  width: 100%;
  float: right;
  padding-right: 170px;
  position: relative;
  margin-top: 15px;
}

.pcheck {
  position: absolute;
  top: -32px;
  padding-left: 20px;
}

.pcheck input {
  float: left;
  position: absolute;
  left: 0;
  top: 4px;
  height: auto !important;
}

.likeshare form input {
  height: 52px;
  border-radius: 10px;
  padding: 10px 24px;
  box-shadow: 0 5px 30px 0 #d6d6d6;
  border: none;
  font-size: 16px;
}

.likeshare form input::placeholder {
  color: #73758382;
}

/**************** side_right *********************/
/* .side_right {
    position: absolute;
    right: 0;
    width: 300px;
    top: 0;
    padding: 0px 15px 35px 0;
    height: 100%;
} */

.side_right {
  position: fixed;
  right: 0;
  width: 300px;
  bottom: 0;
  padding: 0px 15px 0px 0;
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.side_right .asuser {
  position: relative;
  padding-left: 78px;
  height: 85px;
  padding-top: 2px;
  /* border-bottom: 1px solid #e9e8e8cc; */
}

.side_right .asuser .userimg {
  position: absolute;
  left: 0;
  width: 50px;
  top: -3px;
  border-radius: 50%;
  overflow: hidden;
  height: 50px;
  border: 2px solid #fff;
}

.side_right .asuser img.vip {
  height: 24px;
}

.side_right .asuser .dropdown .dropdown-menu {
  background: #016afa;
  right: 0;
  left: inherit !important;
  top: 53px !important;
  transform: initial !important;
  padding: 0;
  border: none;
}

.side_right .asuser .dropdown .dropdown-menu .dropdown-item {
  color: #fff;
  opacity: 1;
  z-index: 9;
  position: relative;
  padding: 6px 16px;
  font-size: 16px;
}

.side_right .asuser .dropdown .dropdown-menu .dropdown-item:hover {
  background: #fff;
  opacity: 1;
  color: #016afb;
}

.side_right .asuser .dropdown-toggle::after {
  display: none;
}

.side_right .asuser .dropdown {
  position: absolute;
  right: 15px;
  top: 15px;
}

.side_right .test h3 {
  font-size: 16px;
  color: #ffffff;
  font-weight: bold;
  margin-bottom: 0;
  padding: 10px;
  background: #016afa;
  border-radius: 8px 8px 0px 0px;
}

.side_right .suggested .btn:hover {
  background: #016afa;
  color: #fff;
}

.side_right .test {
  margin-top: 18px;
}

.side_right .asuser .dropdown .dropdown-menu:after {
  background: #016afa;
  height: 16px;
  width: 16px;
  content: "";
  position: absolute;
  right: 2px;
  top: -7px;
  border-radius: 3px;
  transform: rotate(45deg);
}

.side_right .asuser h5 {
  font-size: 16px;
  color: #fff;
  font-weight: bold;
  margin-top: 12px;
}

.add_carousel .owl-dots {
  display: none;
}

.add_carousel {
  position: relative;
}

.add_carousel .owl-nav {
  position: absolute;
  right: 0;
  top: -72px;
}

.add_carousel .owl-nav button span {
  font-size: 42px;
  color: #737583;
}

.add_carousel .owl-nav button {
  margin-left: 30px;
  outline: none;
}

.add_carousel .owl-nav button span:hover {
  color: #016afb;
}

.side_right .popular_user {
  position: relative;
  padding-left: 72px;
  height: 60px;
  padding-top: 10px;
}

.side_right .asuser .dropdown img {
  height: 28px;
}

.side_right .test p {
  color: #737583;
  margin-top: 2px;
  font-size: 16px;
  font-weight: 400;
}

.side_right .popular_user .userimg {
  position: absolute;
  left: 0;
  width: 60px;
  height: 60px;
  top: 0;
  border-radius: 50%;
  overflow: hidden;
}

.side_right .popular_user .btns .btn {
  border: 2px solid #016afa;
  width: 66px;
  color: #016afa;
  font-weight: 600;
  font-size: 13px;
  padding: 4px 0;
}

.side_right .popular_user .btns .btn.follow {
  background: #016afa;
  color: #fff;
}

.side_right .popular_user h5 {
  margin-top: -12px;
  font-size: 16px;
  color: #737583;
  font-weight: 600;
}

.side_right .test .testin1 + .testin1 {
  border-top: 1px solid #d6dee6;
  padding-top: 28px;
  margin-top: 24px;
}

.side_right .suggested {
  border-radius: 16px;
  padding: 40px 24px;
}

.side_right .suggested h4 {
  color: #fff;
  font-size: 16px;
}

.side_right .suggested h4 span {
  width: 70px;
  background: #fff;
  height: 70px;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  line-height: 70px;
}

.side_right .suggested h4 span img {
  width: 40px;
}

.side_right .suggested h6 {
  color: #fff;
  font-weight: 600;
  font-size: 16px;
}

.side_right .suggested .btn {
  background: #fff;
  padding: 7px 20px;
  font-weight: 600;
  border-radius: 7px;
}

.side_right .stories span.usr {
  width: 45px;
  height: 45px;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  overflow: hidden;
  border: 2px solid #fff;
}

.side_right .stories span.usr.up {
  height: 36px;
  width: 36px;
  border: none;
}

.side_right .stories {
  border-radius: 14px;
  padding: 15px;
  height: 160px;
  background-size: cover;
}

.Postall .singleposttest .contants p {
  min-height: 43px;
}

/********************* discussion **********************/

.discussion .categoryhd h3 {
  color: #016afb;
  margin-bottom: 25px;
  font-weight: 600;
  font-size: 24px;
}

.discussion .categoryhd h5 a {
  background: #016afb;
  padding: 10px 54px;
  color: #fff;
  border-radius: 30px;
  font-size: 16px;
  text-align: center;
}

.discussion .categoryhd h5 span {
  background: #000000;
  padding: 5px 22px;
  color: #fff;
  border-radius: 9px;
  font-size: 16px;
  text-align: center;
  margin-left: 10px;
}

.discussion .categoryhd h6 ul li a {
  padding: 6px 20px;
  font-size: 16px;
  color: #016afb;
  border-radius: 30px;
  background: #fff;
  box-shadow: 0 0 20px 0 #eaeff4;
  font-weight: 600;
}

.discussion .categoryhd h6 ul li {
  float: left;
  margin-right: 15px;
  display: inline-flex;
}

.discussion .categoryhd h6 ul {
  display: inline-flex;
  margin-bottom: 0;
}

.discussion .categoryhd h5 {
  display: inline-block;
}

.discussion .categoryhd h6 {
  margin: 25px 0;
}

.listusr .asuser h5 span {
  color: #737583;
  font-weight: normal;
  margin: 0 15px;
}

.listusr .asuser h5 span a {
  margin-right: 8px;
  font-size: 16px;
  text-decoration: underline !important;
  color: #016afba3;
}

.allcomment .commentin p span {
  color: #016afa;
  font-weight: 600;
}

.listusr .asuser h5 .d_report {
  background: #016afa;
  color: #fff;
  font-size: 16px;
  padding: 6px 20px;
  border-radius: 5px;
  margin-left: 50px;
}

.listusr ul.likecomment li.group_user {
  font-size: 16px;
  padding-left: 65px;
}

.listusr ul.likecomment li.group_user img {
  height: 40px;
}

.listusr .asuser .join {
  background: #016afa;
  width: 100px;
  display: block;
  text-align: center;
  color: #fff;
  padding: 6px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  right: 70px;
  top: 0;
  text-decoration: none;
}

.listusr .asuser .join img {
  height: 28px;
}

.listusr .asuser .join:hover {
  opacity: 0.8;
}

.select_category a.btnfilter:hover {
  color: #fff;
  opacity: 0.8;
}

/******************** messages ******************/
.in_center {
  min-height: 1310px;
  padding-right: 320px;
  position: relative;
}

.test.showchatt {
  margin-top: 5px;
}

.messages {
  margin-top: 65px;
  position: relative;
}

.messages .test {
  background: #fff;
  border-radius: 12px;
  margin-bottom: 15px;
  padding-left: 88px;
  position: relative;
  min-height: 45px;
  overflow: hidden;
  padding-right: 15px;
  color: #737583;
  border: 1px solid #d3d3d3;
}

.in_center.in_center_discussion {
  min-height: unset;
}

.messages a.close img {
  width: 24px;
}

.messages .test .images {
  width: 75px;
  position: absolute;
  overflow: hidden;
  left: 0;
  height: 100%;
}

.messages .test .images img {
  height: 100%;
}

.messages a.seemore,
.my_followers a.seemore {
  font-weight: 600;
  margin: auto;
  display: table;
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;
}

.messages .test h4 {
  font-weight: normal;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 2px;
  text-transform: capitalize;
}

.messages .test h6 {
  font-size: 16px;
  opacity: 0.5;
  margin-top: 4px;
}

.messages .test p {
  margin-bottom: 0;
  font-size: 16px;
}

.messages a.close,
.singlepost a.close,
.my_followers a.close {
  position: absolute;
  right: 0;
  top: -45px;
}

.nwekaptest {
  max-height: 630px;
  overflow-y: auto;
  padding: 15px 15px 15px 0;
}

/*************** singlepost ******************/
.singlepost {
  position: relative;
  margin-top: 25px;
}

.singlepost .asuser h5 {
  color: #737583 !important;
}

.singlepost h4 {
  position: absolute;
  z-index: 99999;
  color: #fff;
  left: 30px;
  top: 40px;
}

.singlepost .carousel-control-prev-icon {
  background-image: url(../../public/images/c_arrow1.png);
  height: 52px;
  width: 25px;
}

.singlepost .carousel-control-next-icon {
  background-image: url(../../public/images/c_arrow.png);
  height: 52px;
  width: 25px;
}

/***************** my_followers ******************/
.my_followers {
  margin-top: 65px;
  position: relative;
}

.my_followers .test {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0px 6px 19px 0 #00000014;
  margin-bottom: 24px;
  padding-left: 120px;
  position: relative;
  min-height: 110px;
  overflow: hidden;
  padding-right: 10px;
  padding-top: 15px;
}

.my_followers .test .userimg {
  width: 130px;
  position: absolute;
  left: 0;
  height: 100%;
  overflow: hidden;
  border-radius: 12px;
  top: 0;
}

.my_followers .test .userimg img {
  width: 100%;
  height: auto;
  border-radius: 50%;
  max-width: 100px;
}

.my_followers .test h5 {
  color: #737583;
  font-weight: bold;
  font-size: 16px;
}

.my_followers .test p {
  margin-bottom: 0;
  color: #737583;
  font-size: 16px;
}

.my_followers .test .userimg span {
  background: #016afa;
  position: absolute;
  right: 0;
  padding: 4px 11px;
  border-radius: 6px 0 0 6px;
  color: #fff;
}

.my_followers ul.followmessage li a {
  background: #016afa;
  padding: 4px 0;
  color: #fff;
  font-size: 13px;
  display: block;
  text-align: center;
  border-radius: 7px;
  border: 1px solid #016afa;
  margin-right: 5%;
  font-weight: 500;
}

.my_followers ul.followmessage li {
  width: 50%;
  float: left;
}

.my_followers ul.followmessage li a.mg {
  color: #016afa;
  background: transparent;
}

/***************** create_post ****************/
.create_post h3 {
  color: #737583;
  font-weight: 600;
  margin-top: 34px;
  font-size: 24px;
}

.create_post .create_in {
  background: #fff;
  box-shadow: 0 0 20px 0 #dfe9f3;
  padding: 28px;
  border-radius: 25px;
}

.create_post .add_create textarea {
  width: 100%;
  height: 280px;
  box-shadow: 0 6px 23px 0 #dae0e4;
  border: none;
  padding: 20px;
  border-radius: 16px;
  outline: none;
  text-decoration: none;
}

.create_post .create_in h4 {
  color: #737583;
  font-weight: 600;
  margin: 15px 0;
  font-size: 16px;
}

.create_post .create_in .input {
  color: #737583;
  font-weight: 600;
  width: 100%;
  border: navajowhite;
  border-bottom: 1px solid #e3e3e6;
  font-size: 16px;
  padding: 10px 0;
  text-decoration: none;
  outline: none;
}

.create_post .create_in ul.select_in li label {
  color: #737583;
  font-size: 16px;
  font-weight: 600;
}

.create_post .create_in ul.select_in li {
  float: left;
  margin-right: 50px;
  text-align: center;
}

.create_post .create_in ul.select_in li select {
  outline: none;
  background: #016afa;
  color: #fff;
  border: navajowhite;
  width: 190px;
  align-content: center;
  padding: 9px 20px;
  display: block;
  border-radius: 30px;
  font-size: 16px;
}

.create_post .create_in .addbtn i {
  background: #016afa;
  color: #fff;
  font-size: 16px;
  padding: 16px;
  border-radius: 10px;
  width: 45px;
  height: 45px;
}

.create_post .create_in .addbtn {
  width: 85px;
  margin: auto;
  border-radius: 12px;
  height: 85px;
  text-align: center;
  line-height: 85px;
  box-shadow: 0 0 30px 0 #d1dce6;
  margin-top: 15%;
  position: relative;
}

.swal-footer {
  text-align: center !important;
}

.create_post .create_in .addbtn input[type="file"] {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.create_post .add_create textarea::placeholder {
  color: #b1b1b1;
}

.create_post .create_in ul.select_in {
  display: -webkit-box;
  margin-bottom: 20px;
}

.create_post .create_in img.upim {
  border-radius: 15px;
}

.create_post .create_in ul.add_tag li i {
  position: absolute;
  right: 0;
  cursor: pointer;
  top: -11px;
  color: #737583;
}

.create_post .create_in ul.add_tag li {
  border-radius: 30px;
  padding: 12px;
  box-shadow: 0 6px 23px 0 #dae0e4;
  display: inline-block;
  min-width: 185px;
  margin-right: 14px;
  text-align: center;
  color: #016afa;
  font-size: 16px;
  position: relative;
  font-weight: 600;
}

.create_post .create_in ul.add_tag .btn {
  border-radius: 12px;
  padding: 12px;
  box-shadow: 0 6px 23px 0 #dae0e4;
  display: inline-block;
  min-width: 60px;
  color: #737583;
  font-size: 16px;
  height: 50px;
  margin-top: -3px;
}

.create_post .create_in h4 .btn {
  border-radius: 12px;
  padding: 12px;
  box-shadow: 0 6px 23px 0 #dae0e4;
  display: inline-block;
  min-width: 60px;
  color: #737583;
  font-size: 16px;
  height: 50px;
  margin-top: -3px;
  margin-left: 20px;
}

.create_post .create_in h4 img {
  margin-right: 15px;
  height: 22px;
}

.create_post .create_in ul.add_tag {
  margin-bottom: 20px;
}

.create_post .create_in .url img {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 22px;
  top: 0;
  margin: auto;
}

.create_post .create_in p.vipp {
  margin-top: 20px;
}

.create_post .create_in .url {
  padding-left: 50px;
  position: relative;
}

.create_post .create_in ul.save_draft {
  margin: 35px 0;
  display: flow-root;
}

.create_post .create_in ul.save_draft li {
  width: 30%;
  float: left;
  margin-right: 28px;
}

.create_post .create_in ul.save_draft li .btn {
  width: 100%;
  float: left;
  text-align: center;
  border-radius: 40px;
  padding: 12px;
  box-shadow: 0 6px 23px 0 #dae0e4;
  font-size: 16px;
  text-decoration: none;
  font-weight: 600;
}

.create_post .create_in ul.save_draft li a.postbtn:hover {
  opacity: 0.8;
}

.create_post .create_in ul.save_draft li a.postbtn {
  background: #016afa;
  color: #fff;
}

.create_post .create_in p {
  color: #737583;
  font-weight: 600;
  font-size: 16px;
  position: relative;
  padding-left: 45px;
}

.create_post .create_in p .checkcontainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.create_post .create_in p input:checked ~ .radiobtn {
  background-color: #016afa;
  border: 2px solid #016afa;
}

.create_post .create_in p .radiobtn {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border: 2px solid #e3e3e6;
  border-radius: 7px;
}

.create_post .create_in p .checkcontainer input:checked ~ .radiobtn:after {
  display: block;
}

.create_post .create_in p .checkcontainer input:checked ~ .radiobtn::after {
  background-image: url(../../public/images/true.png);
  position: absolute;
  content: "";
  width: auto;
  height: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 50%;
  margin: auto;
  background-position: center;
  background-repeat: no-repeat;
}

/************ chats ***********/
.messages.chats {
  padding-left: 310px;
  margin-top: 0;
  position: relative;
}

.messages.chats .list {
  position: absolute;
  left: 0;
  width: 290px;
  height: auto;
  overflow: auto;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
}

.messages.chats .test.active {
  background: #016afa;
  color: #fff;
}

.messages.chats .list form {
  margin-bottom: 40px;
  position: relative;
}

.messages.chats .list form input {
  padding: 12px;
  height: auto;
  border: navajowhite;
  padding-right: 55px;
}

.messagesmain {
  min-height: 950px;
}

.messages.chats .list form input::placeholder {
  color: #8c8c8c;
}

.messages.chats .list form button:hover {
  background: #016afa;
  color: #fff;
}

.messages.chats .list form button {
  position: absolute;
  right: 0;
  height: 100%;
  border: navajowhite;
  padding: 0 18px;
  color: #8c8c8c;
}

.chats .intype {
  border-top: 2px solid #b0d1fe;
  padding: 20px 230px 20px 20px;
  position: relative;
  margin-top: 30px;
}

.chats .intype input {
  background: transparent;
  border: none;
  font-size: 16px;
}

.chats .intype input[type="file"] {
  position: absolute;
  left: 20px;
  width: 30px;
  height: 31px;
  background: red;
  top: 0;
  bottom: 0;
  margin: auto;
  opacity: 0;
}

.chats .intype span.paperclip {
  position: absolute;
  right: 180px;
  bottom: 0;
  width: 40px;
  height: 40px;
  margin: auto;
  top: 0;
  font-size: 16px;
  background: #016afa;
  border-radius: 50%;
  line-height: 40px;
  text-align: center;
  color: #fff;
}

.chats .intype button {
  position: absolute;
  border: 1px solid #016afa;
  right: 30px;
  bottom: 0;
  width: 135px;
  margin: auto;
  top: 0;
  background: transparent !important;
  color: #016afa !important;
  font-size: 16px;
  height: 40px;
  border-radius: 30px;
}

.chats .inleft .chatin {
  /* padding-left: 70px; */
  position: relative;
}

.chats .inleft .chatin img {
  width: 50px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  height: 50px;
}

.chats .innchat h4 {
  background: #016afb;
  color: #fff;
  font-size: 16px;
  margin-bottom: 25px;
  border-radius: 5px;
  overflow: hidden;
}

.chats .innchat h4 img {
  width: 60px;
  margin-right: 15px;
  border-radius: 5px 0 0 5px;
}

.chats .innchat {
  background: #ffff;
  margin-left: 20px;
  width: 60%;
  margin: auto;
}

.chats .innchat .dddd {
  padding: 0 25px;
  max-height: 483px;
  overflow-y: auto;
}

.chats .inleft .chatin p {
  background: #797979;
  padding: 8px 20px;
  border-radius: 10px;
  display: inline-block;
  color: #fff;
  max-width: 400px;
  text-align: left;
  margin-bottom: 2px;
  font-style: italic;
}

.chats .inright .chatin p {
  background: #016afb;
  padding: 8px 20px;
  border-radius: 10px;
  display: inline-block;
  color: #fff;
  max-width: 400px;
  text-align: left;
  margin-bottom: 2px;
}

.chats .inleft .chatin span {
  font-weight: 400;
  color: #737583;
  display: block;
  font-style: italic;
}

.chats .inright .chatin span {
  font-weight: 400;
  color: #737583;
  display: block;
  font-style: italic;
}

/*.messagesmain {
    min-height: auto;
}*/
/***************** profile *******************/
.inbanner {
  height: 400px;
  background-size: cover;
  background-position: center;
  position: relative;
}

.Steve .userimg {
  overflow: hidden;
  height: 240px;
  width: 240px;
  margin: auto;
  border-radius: 50%;
  position: relative;
  border: 2px solid #fff;
}

.Steve .userimg input {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
}

.Steve {
  position: relative;
  margin-top: -140px;
}

.Steve .userimg img {
  width: 100%;
  min-height: 100%;
}

.randem_profile .psotiv {
  padding-top: 60px;
}

.Steve ul li:first-child {
  background-color: #016afa;
  color: #fff;
}

.Steve ul li {
  border: 2px solid #016afa;
  display: inline-block;
  padding: 10px 55px;
  margin: 0 25px;
  border-radius: 30px;
  min-width: 200px;
  font-size: 16px;
  font-weight: 600;
}

.Steve h3 {
  color: #016afa;
  margin-top: 20px;
  font-weight: 600;
  font-size: 28px;
}

.Steve h3 .report_btn2 {
  background: #ff0000;
  color: #fff;
  padding: 8px 40px;
  border-radius: 6px;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-left: 50px;
  position: absolute;
  margin-top: 0;
}

.Steve p {
  color: #737583;
}

.psotiv .asuser {
  position: relative;
  margin-bottom: 20px;
  padding: 22px 20px 22px 130px;
}

.randem_profile .psotiv .asuser .userimg {
  position: absolute;
  left: 0;
  width: 100px;
  top: 0;
  border-radius: 50%;
  overflow: hidden;
  height: 100px;
}

.Steve a.report_btn {
  background: #016afa;
  color: #fff;
  padding: 10px 12px;
  position: absolute;
  right: 0;
  top: 160px;
  border-radius: 6px;
  text-decoration: none;
}

.Steve a.report_btn:hover {
  background: #000;
}

.randem_profile .psotiv .testin {
  border-radius: 22px;
  overflow: hidden;
  position: relative;
}

.randem_profile .psotiv .testin a.sharei {
  position: absolute;
  right: 25px;
  top: 25px;
}

.randem_profile .psotiv ul.likecomment li {
  float: left;
  padding-left: 51px;
  margin-right: 30px;
  position: relative;
  height: 38px;
  line-height: 38px;
  font-weight: 600;
  font-size: 16px;
}

.randem_profile .psotiv ul.likecomment {
  display: inline-block;
  margin-top: 30px;
  margin-bottom: 0;
}

.randem_profile .psotiv ul.likecomment li img {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 30px;
}

.randem_profile .psotiv .test {
  background: #fff;
  padding: 30px;
  margin-bottom: 40px;
  border-radius: 25px;
  box-shadow: 0 0 20px 0 #eaeff4;
}

.randem_profile .psotiv .asuser .dropdown {
  position: absolute;
  right: 17px;
  top: 18px;
}

.randem_profile .psotiv .asuser .dropdown .dropdown-toggle {
  text-align: center;
  font-size: 26px;
}

.randem_profile .psotiv .asuser .dropdown .dropdown-menu {
  background: #016afa;
  right: 0;
  left: inherit !important;
  top: 5px !important;
}

.randem_profile .psotiv .dropdown .dropdown-menu a {
  color: #fff;
  opacity: 0.6;
}

.randem_profile .psotiv .asuser p {
  margin-bottom: 0;
}

.randem_profile .psotiv .asuser .dropdown .dropdown-menu a:hover {
  background: transparent;
  opacity: 1;
}

.randem_profile .psotiv .asuser .dropdown .dropdown-toggle::after {
  display: none;
}

.randem_profile .psotiv .asuser .dropdown .dropdown-menu:after {
  background: #016afa;
  height: 16px;
  width: 16px;
  content: "";
  position: absolute;
  right: 2px;
  top: -7px;
  border-radius: 3px;
  transform: rotate(45deg);
}

.randem_profile .psotiv .asuser h5 {
  font-size: 22px;
  color: #737583;
  font-weight: bold;
}

.randem_profile .psotiv ul.alltk {
  margin-bottom: 40px;
}

.randem_profile .psotiv ul.alltk li a {
  font-size: 16px;
  color: #737583;
  text-decoration: none;
}

.chat .chat-history .my-message:after {
  top: -19px;
  left: 35px;
}
.chat .chat-history .my-message {
  margin: 11px -4px;
}

img.chat-img {
  float: inline-start;
}

.chat-about small {
  display: flex;
  left: 66px;
  position: absolute;
  top: 17px;
  color: #edd9d9;
}

.randem_profile .psotiv ul.alltk li {
  display: inline-block;
  vertical-align: top;
  text-align: center;
  margin: 0 40px;
}

.randem_profile .psotiv ul.alltk li a.active,
.randem_profile .psotiv ul.alltk li a:hover {
  color: #016afa;
  border-bottom: 2px solid;
}

.randem_profile .psotiv ul.alltk li a span {
  display: block;
  font-size: 16px;
}

.randem_profile {
  padding: 0 310px;
  margin-top: 40px;
  border-top: 1px solid #ccc;
  position: relative;
  margin-bottom: 80px;
}

.randem_profile .psotiv_left {
  width: 300px;
  position: absolute;
  left: 0;
  top: 40px;
}
form.d-flex.w-100 {
  padding: 14px 0px;
}
.randem_profile .psotiv_right {
  width: 300px;
  position: absolute;
  right: 0;
  top: 40px;
}

.randem_profile .psotiv_left .inpost a {
  background: #0c8b00;
  padding: 0;
  border-radius: 10px;
  color: #fff;
  font-weight: 600;
  position: absolute;
  left: 0;
  right: 0;
  display: inline-block;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 170px;
  height: 50px;
  line-height: 48px;
  text-align: center;
}

.randem_profile .psotiv_left .inpost {
  position: relative;
  border-top: 3px solid #016afa;
}

.randem_profile .psotiv_right .inpost {
  margin-bottom: 3px;
}

.randem_profile .psotiv_left h5,
.randem_profile .psotiv_right h5,
.randem_profile .psotiv_left2 h5,
.psotiv_right2 h5 {
  text-align: center;
  font-weight: 600;
  font-size: 16px;
}

.randem_profile .psotiv_right .inpossuper {
  background: #000;
  padding: 80px 0 25px;
  text-align: center;
}

.randem_profile .psotiv_right .inpossuper a {
  background: #fff;
  padding: 13px 45px;
  border-radius: 30px;
  color: #000;
  font-weight: 600;
  display: inline-block;
}

.randem_profile .psotiv_right .inpossuper h3 {
  font-size: 46px;
  color: #fff;
  font-weight: bold;
  margin-bottom: 28px;
}

.randem_profile .psotiv_right .fa-times {
  float: right;
  margin-top: -35px;
  font-size: 23px;
}

.randem_profile .psotiv_left .fa-times {
  float: right;
  margin-top: -35px;
  font-size: 23px;
}

.randem_profile .psotiv_left2 {
  width: 300px;
  position: absolute;
  left: 0;
  top: 40px;
}

.psotiv_right2 {
  width: 300px;
  position: absolute;
  right: 0;
  top: 40px;
}

#myTabContent .col-sm-6.col-lg-4.mb-3 {
  display: table;
}

.Postall .singleposttest {
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  background: #fff;
  display: table-cell;
  position: relative;
  overflow: hidden;
}

.dash_topmain.dash2 {
  padding-right: 280px;
  min-height: 975px;
}

.dashboard .user.usernew .uphead a.help span,
.Steve h3 .report_btn2 span {
  position: absolute;
  margin-top: -50px;
}

.dash_topmain.dash2 .psotiv_right2 {
  width: 250px;
  position: absolute;
  right: 0px;
  top: 40px;
}

.dash_topmain.dash2 .psotiv_right2 h5 {
  background: #fff;
  margin: 0;
  padding-top: 8px;
}

.dash_topmain.dash2 .psotiv_right2 i {
  float: right;
  margin-top: -24px;
  margin-right: 4px;
}

.randem_profile .testimd ul.likecomment {
  display: inline-block;
  margin-top: 15px;
  margin-bottom: 15px;
}

.randem_profile .testimd ul.likecomment li {
  float: left;
  padding-left: 40px;
  margin-right: 30px;
  position: relative;
  height: 38px;
  line-height: 40px;
  font-weight: 600;
  font-size: 16px;
}

.randem_profile .testimd ul.likecomment li img {
  position: absolute;
  left: 0;
  height: 30px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.randem_profile .testimd .imgi {
  border-radius: 34px;
  overflow: hidden;
  box-shadow: 0 0 20px 0 #e4edf7;
}

.psotiv_right2 .inpost .intap {
  position: absolute;
  bottom: 30%;
  margin: auto;
  text-align: center;
  width: 80%;
  left: 0;
  right: 0;
  background: #e00202;
  height: 140px;
  padding: 27px 0;
  border-radius: 15px;
}

.psotiv_right2 .inpost {
  position: relative;
  box-shadow: 0 0 20px 0 #dee8f3;
}

.psotiv_right2 .inpost .intap h2 {
  color: #fff;
  font-weight: bold;
  font-size: 25px;
}

.psotiv_right2 .inpost .intap h6 {
  color: #fff;
  font-weight: 600;
}

.psotiv_right2 .inpost a {
  background: #000;
  padding: 10px 32px;
  color: #fff;
  border-radius: 30px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 16%;
  width: 145px;
  text-align: center;
  margin: auto;
}

.randem_profile .psotiv_left2 .testside {
  position: relative;
  margin-bottom: 110px;
}

.randem_profile .psotiv_left2 .testside img {
  box-shadow: 0 0 20px 0 #dee8f3;
}

.randem_profile .testimd .imgi {
  border-radius: 34px;
  overflow: hidden;
  box-shadow: 0 0 20px 0 #dee8f3;
}

.randem_profile .psotiv_left2 .testside .inpost h4 {
  font-weight: bold;
  color: #fff;
  margin-bottom: 0;
  font-size: 30px;
}

.randem_profile .psotiv_left2 .testside .inpost {
  position: absolute;
  right: 0;
  bottom: 10px;
  width: 50%;
}

.randem_profile .psotiv_left2 .testside .inpost a {
  color: #fff;
  text-decoration: underline;
  font-size: 16px;
}

.randem_profile .psotiv_left2 .testside .inpost2 {
  position: absolute;
  top: 70px;
  background: #82b5de;
  color: #fff;
  width: 80px;
  text-align: center;
  padding: 20px 5px;
  border-radius: 10px;
}

.randem_profile .psotiv_left2 .testside .inpost2 h4 {
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 0;
}

.Steve .report_btni {
  position: absolute;
  left: 32%;
  top: 116px;
  right: 0;
  width: 200px;
  margin: auto;
}

.Steve .report_btni .report_btn1 {
  background: #016afa;
  color: #fff;
  padding: 10px 12px;
  border-radius: 6px;
  text-decoration: none;
  margin-top: 15px;
  display: inline-block;
}

.Steve .report_btni .report_btn2 {
  background: #ff0000;
  color: #fff;
  padding: 10px 12px;
  border-radius: 6px;
  text-decoration: none;
  margin-top: 15px;
  display: inline-block;
}

/******** bus_det ***********/
.bus_det {
  padding: 0 0px;
}

.bus_det .tes {
  padding: 10px 0;
  position: relative;
}

.bus_det .tes ul li {
  float: left;
  margin-right: 20px;
  line-height: 40px;
  color: #fff;
  font-size: 16px;
  position: relative;
}

.bus_det .tes ul {
  position: absolute;
  left: 215px;
  top: 15px;
}

.bus_det .tes button.btn2 {
  border: navajowhite;
  font-weight: bold;
  padding: 4px 8px;
  font-size: 26px;
  border-radius: 6px;
  margin-top: 30px;
  margin-left: 23px;
}

.bus_det .tes button.btn2ul {
  border: navajowhite;
  font-weight: bold;
  padding: 5px 8px;
  font-size: 16px;
  border-radius: 6px;
  margin-top: 4px;
}

.bus_det .tes ul li:after {
  content: "";
  background: #fff;
  height: 30px;
  width: 2px;
  position: absolute;
  left: -11px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.bus_det .tes ul li span {
  position: relative;
}

.bus_det .tes ul li span .close2 {
  position: absolute;
  right: -30px;
}

.bus_det .tes + .tes {
  border-top: 1px solid #d5d9df;
}

.bus_det .tes h4 {
  color: #016afa;
  font-weight: 600;
  font-size: 16px;
  padding-left: 0px;
  position: relative;
  margin-bottom: 8px;
}

.bus_det.businessddl .tes h4 {
  padding-left: 180px;
  margin-bottom: 0px;
}

.bus_det .tes h4 span {
  color: #737583;
  font-size: 16px;
  margin-left: 32px;
}

.bus_det .tes ul li span img {
  height: 35px;
}

.bus_det .tes p {
  color: #737583;
  font-size: 16px;
}

.randem_profile .psotiv {
  padding-top: 60px;
  min-height: 1100px;
}

.bus_detedit label {
  display: block;
  color: #016afb;
  font-size: 16px;
}

.bus_detedit h5 {
  color: #016afb;
  font-size: 16px;
}

.bus_detedit input {
  width: 100%;
  height: auto;
  padding: 12px 15px;
  box-shadow: 0 8px 17px 0 #cedae8;
  border: navajowhite;
  margin-bottom: 80px;
  border-radius: 10px;
}

.bus_detedit textarea {
  width: 100%;
  height: 120px;
  padding: 12px 15px;
  box-shadow: 0 8px 17px 0 #cedae8;
  border: navajowhite;
  margin-bottom: 70px;
  border-radius: 10px;
}

.bus_detedit .userimg {
  width: 100%;
  height: 120px;
  padding: 12px 15px;
  box-shadow: 0 8px 17px 0 #cedae8;
  border: navajowhite;
  margin-bottom: 70px;
  border-radius: 10px;
  background: #fff;
  position: relative;
  overflow: hidden;
}

.bus_detedit .userimg input {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: 99;
}

.bus_detedit .userimg span {
  text-align: center;
  top: 0;
  height: 100%;
  position: absolute;
  left: 0;
  line-height: 46px;
  right: 0;
  color: #b0b1b7;
  font-size: 16px;
  padding: 25px 0;
}

.bus_detedit .userimg img {
  width: 100%;
  z-index: 9;
  position: absolute;
  left: 0;
  top: 0;
}

.bus_detedit .userimg span i {
  display: block;
  color: #016afa;
  font-size: 34px;
}

.bus_detedit ul li {
  float: left;
  margin-right: 30px;
}

.bus_detedit ul li img {
  width: 50px;
}

.bus_detedit ul li a:hover {
  opacity: 0.8;
}

.signone.bird,
.signtwo.bird {
  border: 2px solid #016afa;
  padding: 40px;
  border-radius: 40px;
  height: 100%;
}

/************* membership ***************/
.membership {
  padding: 60px 0;
  background-size: 100%;
}

.membership .test h4 {
  background: #404041;
  color: #fff;
  font-size: 16px;
  text-align: center;
  padding: 15px;
  margin-bottom: 0;
}

.membership .test .head_me {
  background: #404041;
  color: #fff;
  padding: 55px 25px 25px 25px;
}

.membership .test .head_me h3 {
  margin-bottom: 0;
}

.membership .test .head_me h5 {
  font-size: 24px;
}

.membership .test .head_me h3 {
  margin-bottom: 0;
  font-size: 34px;
  font-weight: bold;
}

.membership .test ul {
  padding: 0 40px;
}

.membership .test ul li {
  color: #404041;
  margin: 18px 0;
  position: relative;
  font-size: 15.5px;
  padding-left: 10px;
}

.membership .test:hover .head_me {
  padding: 70px 30px 30px 30px;
}

.membership .test:hover ul li {
  margin: 24px 0;
}

.membership .test ul li::after {
  background-image: url(../../public/images/arrow_right.png);
  background-repeat: no-repeat;
  width: 12px;
  height: 17px;
  position: absolute;
  left: -15px;
  content: "";
  top: 4px;
}

.membership .test {
  box-shadow: 0 8px 17px 0 #cedae8;
  border-radius: 40px;
  overflow: hidden;
  width: 72%;
  margin: auto;
}

.membership .testup:hover .head_me h3 {
  font-size: 43px;
}

.membership .testup:hover .head_me h5 {
  font-size: 28px;
}

.membership .testup:hover .test li {
  font-size: 16px;
}

.membership .next {
  background: #016afb;
  padding: 10px;
  color: #fff;
  width: 460px;
  display: block;
  text-align: center;
  border-radius: 30px;
  font-size: 24px;
  margin: 110px auto 0;
}

.membership .testup:hover .test h4 {
  font-size: 30px;
}

.membership h2 {
  color: #016afb;
  font-size: 47px;
  font-weight: bold;
  margin: 80px 0 130px;
  padding: 0 40px;
  position: relative;
}

.membership h2 span {
  top: -80px;
  position: absolute;
}

.membership .testup:hover {
  border: 2px solid #016afb;
  padding: 63px 0;
  border-radius: 30px;
  margin-top: -120px;
  transition: 0.5s all;
  animation-direction: reverse;
}

.membership .testup:hover .head_me,
.membership .testup:hover h4 {
  background: #016afb;
}

.membership .testup:hover .test {
  width: 88%;
}

/************************** dashboard ***********************/
.dashboard .user {
  width: 400px;
  padding-left: 90px;
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 25px;
  margin-left: 90px;
  margin-top: -60px;
  box-shadow: 0 3px 7px 0 #00000024;
  background: #016de4;
}

.dashboard .user.usernew {
  box-shadow: none;
}

.dashboard .user.usernew .uphead ul li:first-child {
  background-color: #ffffff;
  color: #016afa;
}

.dashboard .user.usernew .uphead ul li {
  border: 2px solid #ffffff;
  display: inline-block;
  padding: 10px 12px;
  margin: 0 6px;
  border-radius: 30px;
  min-width: 200px;
  font-size: 16px;
  font-weight: 500;
  background: transparent;
  color: #fff;
}

.dashboard .user.usernew {
  background: transparent;
  width: auto;
  padding: 0;
  margin-top: -80px;
}

.dashboard .user.usernew .uphead {
  padding-left: 175px;
  position: relative;
  min-height: 160px;
  margin-bottom: 10px;
  padding-top: 30px;
}

.dashboard .user.usernew .uphead .report_btni {
  left: 0;
  top: -15px;
}

.dashboard .user .userimg .uploadImg {
  position: absolute;
  bottom: 10px;
  right: 0;
  left: 0;
  margin: auto;
  width: 50px;
  z-index: 99999999;
}

.dashboard .user .userimg .uploadImg .form-control {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
}

.dashboard .user .uphead .report_btni {
  width: 250px;
  position: absolute;
  left: -100px;
  right: 0;
  z-index: 9;
  text-align: center;
  top: 00;
}

.dashboard .user .uphead .report_btni img {
  height: 40px;
}

.dashboard .user.usernew .uphead .userimg {
  position: absolute;
  left: 0;
  top: 0px;
}

.dashboard .user.usernew .userimg {
  position: inherit;
  left: inherit;
  top: inherit;
}

.dashboard .user h5 a {
  color: #fff;
  margin-left: 12px;
  text-decoration: underline;
  font-size: 16px;
}

.dashboard .user h6 {
  font-size: 15px;
  color: #fff;
  margin-top: 10px;
  font-weight: 600;
}

.dashboard .user h3 {
  font-size: 22px;
  color: #fff;
  font-weight: 800;
}

.dashboard .user p {
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  margin-bottom: 0;
}

.dashboard .user h5 {
  color: #fff;
  font-size: 16px;
  text-decoration: underline;
}

.dashboard .tab-content {
  margin-top: 40px;
}

.dashboard .user .userimg {
  width: 125px;
  position: absolute;
  left: -90px;
  border-radius: 50%;
  overflow: hidden;
  height: 125px;
  top: 0;
  border: 2px solid #fff;
  bottom: 0;
  margin: auto;
  text-align: center;
  background-color: #fff;
}

.dashboard .user .userimg input {
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
}

.dashboard {
  background-size: cover;
  min-height: 700px;
  padding: 0 20px;
}

.dash_topmain .dash_sidebar {
  position: absolute;
  left: 0;
  width: 250px;
  border-radius: 15px;
  box-shadow: 0 3px 7px 0 #00000024;
}

.dashboard .user ul li {
  float: left;
  background: #fff;
  padding: 4px 12px;
  margin-right: 10px;
  border-radius: 30px;
  font-size: 13px;
  text-align: center;
  position: relative;
}

.dashboard .user ul li span {
  position: absolute;
  top: -22px;
}

.dashboard .user ul {
  display: flex;
  margin-bottom: 0;
}

.dashboard .user2 h3 {
  font-size: 16px;
  color: #fff;
  margin-top: 30px;
}

.dashboard .user2 ul li:hover {
  border: 1px solid #ffffff8f;
}

.dashboard .user2 ul li {
  width: 24%;
  float: left;
  margin-left: 1%;
  border-radius: 10px;
  border: 1px solid transparent;
  padding: 6px;
}

.dashboard .user2 ul li span {
  height: 50px;
  width: 100%;
  float: left;
  border-radius: 8px;
  margin-right: 10px;
}

.dashboard .user2 {
  width: 45%;
  float: right;
}

.dashboard .user2 a.change {
  margin-top: -50px;
  color: #016afb;
  font-size: 22px;
}

.bus_det .tes h4 b {
  font-weight: 600;
  width: 180px;
  display: inline-block;
  position: absolute;
  left: 0;
  line-height: 36px;
}

.dash_topmain .tab-content h3 i {
  margin-left: 15px;
}

.dash_topmain {
  padding-left: 280px;
  position: relative;
  padding-bottom: 50px;
  min-height: 100vh;
}

.dashboard .bus_det .tes h4,
.dashboard .bus_det .tes h4 span {
  color: #fff;
}

.dashboard .bus_det .tes p {
  color: #ffffffb5;
}

.dashboard .bus_det .tes h4 span {
  font-weight: 400;
  line-height: 36px;
}

.dash_topmain .dash_sidebar ul li a {
  padding: 12px 40px;
  color: #fff;
  display: block;
  font-size: 16px;
  /* border-radius: 12px; */
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  text-decoration: none;
}

.dash_topmain .dash_sidebar ul li {
  float: left;
  width: 100%;
}

.dash_topmain .dash_sidebar ul.nav-tabs {
  border: none;
  height: 100vh;
  overflow: auto;
}

.dash_topmain .dash_sidebar ul li a.active,
.dash_topmain .dash_sidebar ul li a:hover {
  background: #ffffff36;
  color: #ffffff;
}

.dash_topmain .bus_det .tes + .tes {
  border-top: 1px solid #ffffff57;
}

.dashboard .user.usernew .uphead a.help {
  background: #ff0000;
  color: #fff;
  padding: 9px 40px;
  border-radius: 30px;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: 600;
}

#Advertisement .card {
  display: table-cell;
  margin-bottom: 0;
}
#Advertisement .col-sm-4.mt-1 {
  display: table;
  margin-bottom: 10px;
}

.singleposttest .contants {
  padding: 10px;
  padding-bottom: 60px;
}

.singleposttest .contants p {
  margin-top: 5px;
  max-height: 10px;
  overflow: hidden;
  font-size: 10px;
}

.singleposttest .contants a {
  /* background: #f0f0f0;
    display: block;
    text-align: center;
    color: #1a1919;
    padding: 10px;
    margin: 10px 0px;
    font-weight: 600;
    text-decoration: none;
    border-radius: 5px;
    font-size: 12px;
    position: absolute; */
  left: 0;
  right: 0;
  bottom: 0px;
}

.listusr .singleposttest .asuser h5 {
  font-size: 16px;
  margin-bottom: 2px;
}

.singleposttest .contants a:hover {
  background: #016afa;
  color: #ffffff;
}

.singleposttest {
  border: 1px solid #e6e6e6;
  border-radius: 20px;
}

.listusr .singleposttest .asuser {
  padding: 5px 15px 0 70px;
  margin-top: 15px;
}

.listusr .singleposttest .asuser h5 .d_report {
  padding: 6px 15px;
  float: right;
}

.listusr .singleposttest .asuser p {
  font-size: 13px;
}

/*.singleposttest:hover {
    border: 2px solid #016afa3d;
}
*/
.listusr .singleposttest .asuser .userimg {
  position: absolute;
  left: 15px;
  width: 55px;
  top: 0;
  border-radius: 50%;
  overflow: hidden;
}

.monweek .checkcontainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.monweek input:checked ~ .radiobtn {
  border: 2px solid #016afa;
}

.monweek .radiobtn {
  position: absolute;
  top: 0px;
  left: 0;
  height: 100% !important;
  width: 100% !important;
  border: 2px solid #e3e3e6;
  border-radius: 12px;
}

.monweek label.checkcontainer {
  width: 100%;
  position: relative;
  text-align: center;
  padding: 20px 0;
  height: 130px;
  border-radius: 12px;
  overflow: hidden;
}

.monweek h2 {
  margin-bottom: 0;
  font-size: 40px;
  font-weight: bold;
}

.monweek h3 {
  font-size: 16px;
  margin-top: 22px;
}

.monweek .checkcontainer input:checked ~ .radiobtn:after {
  display: block;
}

.monweek {
  width: 60%;
  margin-top: 40px;
}

.monweek .checkcontainer input:checked ~ .radiobtn::after {
  position: absolute;
  content: "";
  width: auto;
  height: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 50%;
  margin: auto;
  background-position: center;
  background-repeat: no-repeat;
}

.monweek .checkcontainer input:checked ~ .radiobtn {
  background-color: #016afa;
  border: 2px solid #016afa;
}

.monweek .checkcontainer input:checked ~ .radiobtn h2,
.monweek .checkcontainer input:checked ~ .radiobtn h3 {
  position: relative;
  color: #fff;
}

#Gallery .imagetest {
  border-radius: 15px;
  overflow: hidden;
}

.dash_topmain .tab-content h3 {
  color: #fff;
  margin-bottom: 25px;
  font-size: 24px;
}

.uplodim {
  border: 1px solid #fff;
  padding: 11px;
  margin-bottom: 20px;
  position: relative;
  width: 400px;
  border-radius: 6px;
  color: #fff;
  overflow: hidden;
}

.uplodim button {
  position: absolute;
  right: 0;
  background: #016afb;
  width: 129px;
  height: 100%;
  top: 0;
  border: navajowhite;
  color: #fff;
  border-left: 1px solid #fff;
}

.uplodim input {
  position: absolute;
  left: 0;
  height: 100%;
  top: 0;
  opacity: 0;
}

#Information h5,
#Information p {
  color: #fff;
}

.addfrbfil .testfrnd {
  border: 1px solid #ccc;
  background: #ffffffe8;
  border-radius: 15px;
  padding: 15px 15px 15px 144px;
  position: relative;
  min-height: 150px;
}

.addfrbfil .testfrnd .userimg {
  width: 130px;
  position: absolute;
  left: 0;
  height: 100%;
  overflow: hidden;
  border-radius: 12px;
  top: 0;
}

.addfrbfil .testfrnd .userimg img {
  width: 100%;
  height: auto;
  border-radius: 50%;
  max-width: 35px;
}

.addfrbfil .testfrnd .userimg span {
  background: #016afa;
  position: absolute;
  right: 0;
  padding: 4px 11px;
  border-radius: 6px 0 0 6px;
  color: #fff;
}

.addfrbfil .testfrnd h5 {
  color: #737583;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 4px;
}

.addfrbfil .testfrnd p {
  margin-bottom: 8px;
  color: #737583;
  font-size: 14px;
}

.addfrbfil .testfrnd ul.followmessage li {
  width: 33%;
  float: left;
}

.flowmsg li {
  width: 42% !important;
  float: left;
}
.addfrbfil .testfrnd ul.followmessage li a {
  background: #016afa;
  padding: 5px 10px;
  color: #fff;
  margin: 5px 0px;
  font-size: 14px;
  display: inline-flex;
  text-align: center;
  border-radius: 7px;
  border: 1px solid #016afa;
  margin-right: 5%;
  width: auto;
  min-width: 100px;
  justify-content: center;
  align-items: center;
}
div#Friends ul.followmessage.flowmsg li,
div#Followers ul.followmessage.flowmsg li,
div#Following ul.followmessage.flowmsg li {
  width: auto !important;
  float: none;
}
div#Friends ul.followmessage.flowmsg,
div#Followers ul.followmessage.flowmsg,
div#Following ul.followmessage.flowmsg {
  display: flex;
  align-items: center;
  gap: 10px;
}

.addfrbfil .testfrnd ul.followmessage li a.mg {
  color: #016afa;
  background: transparent;
}

#Membership .testup {
  background: #ffffffe8;
  padding: 25px;
  border-radius: 15px;
  height: 100%;
}

#Advertisement p {
  color: #fff;
  font-size: 16px;
}

#Membership .testup .test button.btn {
  width: 100%;
  margin: 5px 0;
  padding: 9px;
  border-radius: 30px;
}

#Membership .testup h4 {
  color: #016afa;
  font-size: 28px;
  font-weight: bold;
}

#Membership .testup h6 {
  color: #000;
  font-size: 16px;
  font-weight: bold;
}

#Membership .testup ul li {
  color: #626262;
  margin-bottom: 5%;
}

#Membership .testup h5 {
  font-size: 16px;
}

a.see_more {
  color: #949494;
  font-weight: 600;
  margin: auto;
  text-align: center;
  font-size: 16px;
  border: 1px solid #edf2f6;
  padding: 10px 32px;
  border-radius: 5px;
  display: inline-block;
}

a.see_more:hover {
  color: #016afb;
}

.HelpForm select,
.HelpForm input {
  width: 100%;
  /* padding: 10px; */
  border: 1px solid #dedede;
  border-radius: 6px;
  color: #626262;
}

.help .singleposttest .asuser {
  padding: 0px 15px 0 80px;
}

.help .singleposttest .asuser p {
  margin-bottom: 0;
  display: inline-block;
}

.HelpForm textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #dedede;
  border-radius: 6px;
  color: #626262;
  height: 110px;
}

.HelpForm .userimgmain {
  position: relative;
  display: inline-block;
}

.HelpForm .userimg {
  width: 160px;
  height: 160px;
  margin: auto;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  border: 8px solid #016afb1f;
}

.HelpForm .userimgmain input[type="file"] {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  padding: 0;
  opacity: 0;
}

.HelpForm .userimgmain img {
  width: 100%;
}

.HelpForm h5 {
  font-size: 25px;
  color: #016afb;
  font-weight: 700;
}

.HelpForm label {
  font-weight: 600;
  font-size: 16px;
}

.HelpForm .modal-header {
  padding: 20px 40px;
}

.HelpForm .modal-body {
  padding: 40px;
}

.HelpForm .modal-footer button {
  width: 100%;
  padding: 10px;
}

.HelpForm .userimgmain img.camerai {
  position: absolute;
  bottom: 32px;
  width: 34px;
  left: 0;
  right: 0;
  margin: auto;
}

.HelpForm .modal-header .close span {
  font-size: 32px;
}

.HelpForm .modal-footer button.submit {
  background: #016afb;
}

header.header .main_menu {
  margin: 35px 0 32px;
}

header.header img {
  width: 80px;
}

header.header {
  background: #016afb;
  padding: 10px 0;
}

header.header .main_menu ul {
  margin-bottom: 0;
}

.Ticket-header {
  align-items: center;
  display: flex;
}

.planpopup {
  justify-content: space-between;
}

.planpopup h5 {
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 22px;
}

.planpopup h4 {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 50px;
}

.custom-header {
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #eee;
}
.planpopup button {
  background: unset !important;
  box-shadow: unset !important;
}

.custom-header h5 {
  font-weight: 700 !important;
  font-size: 22px;
  margin-bottom: 0 !important;
}
:focus-visible,
:focus {
  outline: 0 !important;
}

.modal-Membership {
  border: 0;
  border-radius: 9px;
}

.close-btn-rm {
  position: absolute;
  right: 20px;
  font-size: 33px;
  color: #016afb;
}
.planpopup button span {
  background: #016afb !important;
  padding: 10px 50px !important;
  color: #fff !important;
  border-radius: 30px !important;
  text-align: center;
  font-weight: 600 !important;
  border: 0 !important;
  height: unset !important;
  box-shadow: none !important;
  line-height: unset !important;
}

.active.non-active {
  border: 0 !important;
}

ul.ul_discussion select {
  padding: 8px 12px;
  margin-left: 18px;
  border: 1px solid #ccc;
  border-radius: 5px;
  color: #ababab;
  outline: none;
  width: 100%;
  max-width: 272px;
}

ul.ul_discussion {
  text-align: right;
  margin-bottom: 22px;
}

ul.ul_discussion li {
  display: contents;
}

ul.ul_discussion li img {
  height: 23px;
  margin-left: 12px;
  opacity: 0.4;
  margin-top: -5px;
}

.listpro ul.pagination li.page-item {
  display: inline-block;
}

.listpro ul.pagination {
  margin: auto;
  display: table;
  margin-top: 30px;
}

.listpro ul.pagination li.page-item.arrow a {
  color: #232323;
  font-weight: 600;
}

.listpro ul.pagination li.page-item a {
  padding: 12px 18px;
  font-weight: 500;
}

.listpro {
  margin-bottom: 45px;
}

.listpro .list1 span.userimg img {
  height: 100%;
}

.listpro .list1 span.userimg {
  width: 120px;
  position: absolute;
  left: 0;
  height: 100%;
  overflow: hidden;
}

.listpro .list1 {
  padding-left: 140px;
  position: relative;
  border: 1px solid #eae8e8;
  margin-bottom: 12px;
  background: #ffffffad;
  border-radius: 10px;
  overflow: hidden;
  min-height: 120px;
  box-shadow: 0 5px 1px 0 #00000005;
}

.listpro .list1:hover {
  background: #fff;
  border: 1px solid #0068f72b;
  box-shadow: 0 5px 1px 0 #0068f72b;
}

.listpro .list1 h6 {
  padding-top: 16px;
  font-size: 16px;
  font-weight: 600;
  color: #016afa;
}

.listpro .list1 p {
  margin-bottom: 0;
  color: #ccc;
  font-size: 16px;
}

.listpro .list1 ul {
  display: inline-block;
  margin-bottom: 0;
  margin-top: 10px;
}

.listpro .list1 ul li {
  float: left;
  margin-right: 30px;
  font-size: 16px;
  color: #626262;
}

.listpro .list1 ul li img {
  height: 24px;
  margin-right: 6px;
}

.editInformation form .form-control {
  height: 33px;
  background: transparent;
  color: #ffffff;
}

.editInformation form .form-control::placeholder {
  color: #ffffffad;
}

.editInformation textarea.form-control {
  height: 140px;
}

.editInformation button {
  background: #fff;
  border: navajowhite;
  padding: 10px 44px;
  font-weight: 600;
  border-radius: 5px;
  font-size: 16px;
}

.bus_det .tes ul.input-tag__tags li {
  line-height: 27px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.bus_det .tes ul.input-tag__tags {
  left: inherit;
  top: inherit;
  bottom: 0;
  height: 34px;
  margin-top: 15px;
  position: relative;
  margin-bottom: 0;
  display: block;
}

.bus_det .tes ul.input-tag__tags li:after {
  display: none;
}

/************ main_footer ***********/
footer.main_footer {
  padding: 30px;
  background: #016afa;
  text-align: center;
  box-shadow: 0 0 25px 0 #00000024;
}

footer.main_footer p {
  color: #fff;
  font: 18px;
  margin-bottom: 0;
}

.addpost .addhead ul li input {
  position: absolute;
  opacity: 0;
  width: 100%;
  left: 0;
  height: 100%;
}

.listusr .asuser h5 span p {
  font-size: 16px;
  margin-bottom: 0;
  margin-top: 4px;
}

.maindivkap {
  width: 100%;
  float: left;
  display: block;
  padding: 0;
  text-align: center;
}

.maindivkap .imgg {
  padding: 0;
  position: relative;
  border: 1px solid #e8e8e8;
  margin: 0;
  border-radius: 10px;
  height: 120px;
  line-height: 120px;
  margin-top: 12px;
  overflow: hidden;
}

.maindivkap img {
  width: 85% !important;
}

.maindivkap .imgg i {
  position: absolute;
  right: 8px;
  top: 8px;
  font-size: 26px;
  color: #ff000070;
}

.addpost .addhead form.d-flex {
  display: grid !important;
}

.maindivkap .imgg video {
  width: 100%;
  height: 100%;
}

.addpost .addhead ul li .dropdown-menu ul {
  position: relative;
  bottom: inherit;
}

.addpost .addhead ul li .dropdown-menu {
  padding: 0;
  margin-top: 15px;
  min-height: 40px;
}

.addpost .addhead ul li .dropdown-menu ul li + li {
  border-top: 1px solid #ccc;
}

.addpost .addhead ul li .dropdown-menu ul li {
  width: 100%;
  margin: 0;
  padding: 5px 0 6px 30px;
  font-size: 16px;
  position: relative;
}

.addpost .addhead ul li .dropdown-menu ul li input {
  top: 0;
}

.addpost .addhead ul li .dropdown-menu ul li img {
  margin-right: 10px;
  height: 100%;
}

/* .addpost .addhead ul li .dropdown-menu ul li:hover {
    background: #016afb;
    color: #fff;
} */
.addpost .addhead ul li .dropdown-menu ul li b {
  width: 34px;
  display: inline-block;
  overflow: hidden;
  float: left;
  margin-right: 10px;
  height: 26px;
  text-align: center;
}

.in_center aside.emoji-picker-react {
  position: absolute;
  margin-left: 322px;
  margin-top: -23px;
  z-index: 9999;
}

.addpost .addhead ul li .dropdown-menu input.linkurl {
  opacity: 1;
  border: none;
  font-size: 16px;
  outline: none;
  box-shadow: none;
}

.addpost .addhead ul li .dropdown-menu ul li .checkcontainer {
  position: relative;
  cursor: pointer;
  width: 100%;
  margin: 0;
  float: left;
}

.addpost .addhead ul li .dropdown-menu ul li .checkcontainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.addpost .addhead ul li .dropdown-menu ul li .radiobtn {
  position: absolute;
  top: 5px;
  left: -24px;
  height: 18px;
  width: 18px;
  border: 1px solid #cecece;
  border-radius: 3px;
}

.addpost .addhead ul li .dropdown-menu ul li input:checked ~ .radiobtn {
  background-color: #016afb;
  border: 2px solid #016afb;
  background-image: url(../../public/images/true.png);
  background-size: 11px;
  background-repeat: no-repeat;
  text-align: center;
  background-position: center;
}

.addpost
  .addhead
  ul
  li
  .dropdown-menu
  ul
  li
  .checkcontainer
  input:checked
  ~ .radiobtn:after {
  display: block;
}

.tagfriends ul {
  position: relative;
  bottom: inherit;
}

.tagfriends ul li {
  width: 100%;
  margin: 0;
  padding: 5px 0 6px 30px;
  font-size: 16px;
  position: relative;
}

.tagfriends ul li input {
  top: 0;
}

.tagfriends li img {
  margin-right: 10px;
  height: 100%;
}

.tagfriends ul li b {
  width: 34px;
  display: inline-block;
  overflow: hidden;
  float: left;
  margin-right: 10px;
  height: 26px;
  text-align: center;
}

.tagfriends input.linkurl {
  opacity: 1;
  border: none;
  font-size: 16px;
  outline: none;
  box-shadow: none;
}

.tagfriends ul li .checkcontainer {
  position: relative;
  cursor: pointer;
  width: 100%;
  margin: 0;
  float: left;
}

.tagfriends ul li .checkcontainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.tagfriends li .radiobtn {
  position: absolute;
  top: 5px;
  left: -24px;
  height: 18px;
  width: 18px;
  border: 1px solid #cecece;
  border-radius: 3px;
}

.tagfriends ul li input:checked ~ .radiobtn {
  background-color: #016afb;
  border: 2px solid #016afb;
  background-image: url(../../public/images/true.png);
  background-size: 11px;
  background-repeat: no-repeat;
  text-align: center;
  background-position: center;
}

.tagfriends ul li .checkcontainer input:checked ~ .radiobtn:after {
  display: block;
}

.loginpage .right_login .checkcontainer {
  position: relative;
  cursor: pointer;
  float: left;
  height: 100%;
  margin-right: 6px;
  padding: 0 0 0 32px;
  width: 100%;
  margin: 0;
  color: #827e7e;
  font-weight: 400;
}

.loginpage .right_login .checkcontainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 18px;
  left: 0;
  width: 18px;
  top: 3px;
  z-index: 99999;
}

.loginpage .right_login .radiobtn {
  position: absolute;
  top: 3px;
  left: 0px;
  height: 18px;
  width: 18px;
  border: 1px solid #cecece;
  border-radius: 3px;
}

.loginpage .right_login input:checked ~ .radiobtn {
  background-color: #016afb;
  border: 2px solid #016afb;
  background-image: url(../../public/images/true.png);
  background-size: 11px;
  background-repeat: no-repeat;
  text-align: center;
  background-position: center;
}

.loginpage .right_login .checkcontainer input:checked ~ .radiobtn:after {
  display: block;
}

.socialicon {
  width: 350px;
}

.socialicon span {
  width: 45px;
  height: 45px;
  background: #fff;
  margin-right: 4px;
  display: inline-block;
  margin-top: 10px;
  color: #ccc;
  border-radius: 50%;
}

.socialicon span a {
  width: 100%;
  height: 100%;
  line-height: 45px;
  float: left;
  margin: 0 !important;
  text-align: center;
}

.socialicon span a i {
  color: #6f6f6f;
  font-size: 16px;
}

.allvideoimages .imagetest input {
  position: absolute;
  top: 10px;
  left: 10px;
  border: none;
  z-index: 9;
}

.allvideoimages .imagetest {
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden !important;
  height: 155px;
}

.allvideoimages .imagetest.first-imagetest {
  overflow: inherit !important;
}

.allvideoimages .imagetest img {
  height: 100%;
  border-radius: 15px;
}

.allvideoimages .imagetest video {
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

.removemultipleimages {
  position: absolute;
  top: -58px;
  padding-left: 30px;
  width: 400px;
  color: #fff;
}

.row.allvideoimages {
  margin-top: 80px;
}

.removemultipleimages input {
  left: 0 !important;
}

.removemultipleimages .btn {
  margin-left: 12px;
  padding: 7px 20px;
}

.listusr.favoriteclass .test .likecomment {
  margin-top: 14px;
}

.listusr.favoriteclass .test .asuser {
  padding: 0 0px 0px 75px;
  min-height: 65px;
}

input[type="date"] {
  color: #333; /* Text color */
  background-color: #fff; /* Background color */
}
.masseage-area button {
  border: 0;
}
.listusr.favoriteclass .test {
  padding: 20px;
  border-radius: 15px;
  position: relative;
  height: 100%;
}
.StripeCheckout {
  border: 0 !important;
  box-shadow: unset !important;
  border-radius: 50px !important;
  padding: 0;
  margin-bottom: 10px;
}
.StripeCheckout span {
  background: #396afb !important;
  padding: 5px 38px !important;
  height: unset !important;
  font-weight: 400 !important;
}
.listusr.favoriteclass .userimg {
  box-shadow: 0 0 20px #a2a2a26e;
}

.listusr.favoriteclass .test img.hearticon {
  margin-top: 0;
  width: 26px;
  position: absolute;
  right: 15px;
  bottom: 20px;
  z-index: 99999999;
}

.listusr.favoriteclass .test .asuser h5 {
  margin-bottom: 0;
}

.listusr.favoriteclass .asuser h5 span {
  font-size: 16px;
  display: table-cell;
}

.listusr.favoriteclass ul.likecomment li {
  padding-left: 30px;
  margin-right: 15px;
  height: 32px;
}

.listusr.favoriteclass ul.likecomment li img {
  height: 18px;
}

.listusr.favoriteclass .test .asuser h5 span p {
  display: inline-block;
  margin-left: 6px;
  font-size: 16px;
}

#Referral .table tr td,
#Referral .table tr th {
  color: #fff;
}

.bus_det.editInformation p {
  color: #fff;
}

.main_menu .side_right {
  width: 250px;
  height: 60px;
  bottom: 0;
  margin: auto;
  margin-right: 100px;
}

.main_menu .side_right span.logout {
  background: #016afb;
  padding: 10px 0;
  color: #fff;
  border-radius: 30px;
  position: absolute;
  right: -50px;
  top: 0;
  font-size: 16px;
  min-width: 100px;
  text-align: center;
  text-decoration: none;
  font-weight: 500;
}

.diplay1 {
  display: none !important;
}

.main_menu ul li span {
  color: #000;
  font-weight: 600;
  font-size: 16px;
}

.main_menu ul li .dropdown-menu {
  width: 220px;
  padding: 0;

  position: absolute;
  will-change: transform;
  overflow-y: visible;
  height: auto;
  width: 267px;
  max-height: 400px;
  top: 0px;
  left: 0px;
  transform: translate3d(0px, 22px, 0px);
}

.main_menu ul li .dropdown-menu li {
  margin: 0;
  float: inherit;
}

.main_menu ul li .dropdown-menu li + li {
  border-top: 1px solid #e7e7e7;
}

.main_menu ul li .dropdown-menu li a {
  padding: 10px 15px;
  display: block;
  color: #626262;
  font-weight: 400;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
}
ul.testul {
  direction: ltr !important;
  background-color: #fff;
}

.main_menu ul li .dropdown-menu li a:hover {
  background: #396afb;
  color: #fff;
}

/* 8/11/2022 */

.bus_det.editInformation.view1 .tes {
  overflow: hidden;
}

.bus_det.editInformation.view1 .tes p {
  color: #ffffffb5;
  margin-bottom: 0;
}

.bus_det.editInformation.view1 .tes {
  padding: 8px 15px;
  position: relative;
  background: #00000026;
  margin-bottom: 8px;
  border-radius: 10px;
  border: 1px solid #ffffff2e;
}

span.days {
  font-size: 12px;
  padding: 2px 6px;
  border-radius: 5px;
  border: 1px solid #a3a3a3;
  margin-right: 2px;
}

.btn-report {
  font-size: 12px;
  padding: 2px 16px;
  border-radius: 5px;
  border: 1px solid #a3a3a3;
  width: 100%;
}

.bottomreport {
  margin: 10px 0;
}

.para p {
  font-size: 15px !important;
  font-weight: 600;
}

img.chatimage {
  height: auto !important;
  border-radius: 2px !important;
  width: 100% !important;
}

.chatuploadfile {
  width: auto;
  height: 28px;
  margin-bottom: 5px;
}

.chatvideo {
  width: 100%;
  height: auto;
}

.listusr.favoriteclass {
  margin-top: 30px !important;
}

.favoriteclass .col-sm-6.col-lg-4.col-xl-3 {
  margin-bottom: 10px;
}

.listusr .asuser p {
  margin-bottom: 5px;
  font-size: 16px;
}

.listusr .asuser .userimg {
  position: absolute;
  left: 0;
  width: 45px;
  height: 45px;
  top: 0;
  border-radius: 50%;
  overflow: hidden;
}

.listusr.favoriteclass.notification .test {
  background: #016afa1c;
  height: 85%;
}

.coupon {
  border: 5px dotted #bbb;
  width: 100%;
  border-radius: 15px;
  margin: 0 auto;
  max-width: 600px;
}

.containerpopup {
  padding: 2px 16px;
  background-color: #f1f1f1;
}

.promo {
  background: #ccc;
  padding: 3px;
}

.expire {
  color: red;
}

div#popup-2 {
  width: auto;
}

.appendusers {
  display: flex;
  gap: 20px;
  align-items: center;
}

.appendusers .item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.appendusers .item .w-100 {
  width: 100% !important;
  max-width: 30px;
}

.appendusers .item h5 {
  font-size: 16px;
  margin-bottom: 0;
}

.card {
  background: #fff;
  transition: 0.5s;
  border: 0;
  margin-bottom: 230px;
  border-radius: 0.55rem;
  position: relative;
  width: 100%;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
}

.chat-app .people-list {
  width: 280px;
  position: absolute;
  left: 0;
  top: 0;
  padding: 20px;
  z-index: 7;
}

.chat-app .chat {
  margin-left: 280px;
  border-left: 1px solid #eaeaea;
}

.people-list {
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.people-list .chat-list li {
  padding: 8px 3px;
  list-style: none;
  border-radius: 3px;
}

.people-list .chat-list li:hover {
  background: #efefef;
  cursor: pointer;
}

.people-list .chat-list li.active {
  background: #efefef;
}

.people-list .chat-list li .name {
  font-size: 16px;
  font-weight: 600;
}

.people-list .chat-list img {
  width: 35px;
  border-radius: 50%;
}

.people-list img {
  float: left;
  border-radius: 50%;
}

.people-list .about {
  float: left;
  padding-left: 8px;
}

.testul {
  background-color: #fafafa;
  display: none;
  left: 5px;
  position: absolute;
  background-color: #ddd;
  min-width: 240px;
  box-shadow: 0px 8px 18px 0px rgba(0, 0, 0, 0.2);
  padding: 6px 5px;
  z-index: 1;
  color: black;
}

.people-list .status {
  color: #bac2c2;
  font-size: 16px;
}

.chat .chat-header {
  padding: 15px 20px;
  border-bottom: 2px solid #f4f7f6;
}

.chat .chat-header img {
  float: left;
  border-radius: 40px;
  width: 40px;
}

.chat .chat-header .chat-about {
  float: left;
  padding-left: 10px;
}

.chat .chat-history {
  padding: 20px;
  border-bottom: 2px solid #fff;
  max-height: 340px;
  overflow-y: auto;
  min-height: 320px;
}

.chat .chat-history ul {
  padding: 0;
}

.chat .chat-history ul li {
  list-style: none;
  margin-bottom: 14px;
}

.chat .chat-history ul li:last-child {
  margin-bottom: 0px;
}

.chat .chat-history .message-data {
  margin-bottom: 15px;
}

.chat .chat-history .message-data img {
  border-radius: 40px;
  width: 32px;
}

.chat .chat-history .message-data-time {
  color: #434651;
  padding-left: 6px;
}
.message.my-message p {
  font-size: 16px;
}
.chat .chat-history .message {
  color: #444;
  padding: 18px 20px;
  line-height: 26px;
  font-size: calc(8px + 0.4vw);
  border-radius: 7px;
  display: inline-block;
  position: relative;
}

.chat .chat-history .message:after {
  bottom: 100%;
  left: 7%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #fff;
  border-width: 10px;
  margin-left: -10px;
}

.chat .chat-history .my-message {
  background: #efefef;
}

.chat .chat-history .my-message:after {
  bottom: 100%;
  left: 30px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #efefef;
  border-width: 10px;
  margin-left: -10px;
}

.chat .chat-history .other-message {
  background: #e8f1f3;
  text-align: right;
}

.chat .chat-history .other-message:after {
  border-bottom-color: #e8f1f3;
  left: 93%;
}

.chat .chat-message {
  padding: 20px;
}

.online,
.offline,
.me {
  margin-right: 2px;
  font-size: 16px;
  vertical-align: middle;
}

.online {
  color: #86c541;
}

.offline {
  color: #e47297;
}

.me {
  color: #1d8ecd;
}

.float-right {
  float: right;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

notification-ui_dd-content {
  margin-bottom: 30px;
}

#marital_status option {
  color: black;
}

.testul {
  display: none;
}

li:hover > .testul {
  display: block;
}

.notification-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 20px;
  margin-bottom: 7px;
  background: #fff;
  -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.06);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.06);
}

.notification-list--unread {
  border-left: 2px solid #016afa;
}

.notification-list .notification-list_content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.notification-list .notification-list_content .notification-list_img img {
  height: 48px;
  width: 48px;
  border-radius: 50px;
  margin-right: 20px;
}

.notification-list .notification-list_content .notification-list_detail p {
  margin-bottom: 5px;
  line-height: 1.2;
}

.notification-list .notification-list_feature-img img {
  height: 48px;
  width: 48px;
  border-radius: 5px;
  margin-left: 20px;
}

.head select {
  border: none;
}
.listusr .singleposttest .asuser .userimg {
  width: 35px;
  height: 35px;
}

/** Large devices (desktops, less than 1600px) **/
@media screen and (min-width: 1200px) and (max-width: 1800px) {
  .my_followers .col-lg-6.col-xl-4 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .chatuser {
    display: none;
  }
}

@media only screen and (max-width: 1799.98px) {
  .chatuser {
    display: none;
  }
  .listusr .asuser .join {
    width: 90px;
    right: 45px;
  }

  .listusr .asuser h5 span {
    font-size: 16px;
  }

  .listusr .asuser .dropdown {
    right: 0;
  }

  .randem_profile .psotiv ul.alltk li {
    margin: 0 24px;
  }

  .listusr .singleposttest .asuser h5 .d_report {
    padding: 6px 8px;
  }

  .listusr .singleposttest .asuser p {
    font-size: 16px;
  }

  .listusr .singleposttest .asuser {
    padding: 5px 15px 2px 82px;
  }

  .listusr .singleposttest .asuser .userimg {
    width: 50px;
    height: 50px;
  }
  .listusr .singleposttest .asuser .userimg {
    width: 35px;
    height: 35px;
  }

  .dashboard .user {
    width: 359px;
    padding-left: 75px;
    padding-top: 5px;
    padding-bottom: 11px;
  }

  .addfrbfil .testfrnd {
    padding: 10px 10px 10px 112px;
  }
  .viewprofile .addfrbfil .testfrnd {
    padding: 10px 10px 10px 80px;
  }
  .userprofile .addfrbfil .testfrnd {
    padding: 10px 10px 10px 80px;
  }

  .chat .chat-history .message-data-time {
    font-size: 16px;
    color: #bac2c2;
    padding-left: 6px;
  }

  .chat .chat-history .message {
    padding: 0px 16px;
  }

  .chat .chat-history .message:after {
    margin-left: -24px;
  }
}
@media only screen and (max-width: 1600px) {
  .dashboard .user .userimg {
    width: 115px;
    left: -59px;
    height: 115px;
  }

  img.camerai {
    width: 32px;
  }

  .listusr .singleposttest .asuser .userimg {
    width: 30px;
    height: 30px;
  }

  .listusr .singleposttest .asuser .userimg {
    left: 22px;
    top: 7px;
  }

  .addfrbfil .testfrnd .userimg img {
    max-width: 35px;
  }

  #Membership .testup {
    text-align: center;
    padding: 17px 18px;
  }

  .editInformation button {
    margin: 29px 0px;
  }

  .my_followers .test .userimg {
    width: 50px;
    left: 28px;
    top: 25px;
  }

  .my_followers ul.followmessage li a {
    padding: 6px 3px;
    font-size: 16px;
  }

  .w-100 {
    height: 100%;
    width: 100% !important;
    object-fit: cover;
  }

  .my_followers .test .userimg {
    width: 50px;
    left: 24px;
    top: 21px;
  }

  .list1 a .userimg {
    width: 200px;
    height: 200px;
    background-color: blueviolet;
  }
}

@media only screen and (max-width: 1599.98px) {
  .container {
    max-width: 1200px;
  }
  .likeshare .comment {
    padding: 0px 35px;
  }
  .my_followers .test p {
    font-size: 16px;
  }

  .sidbar_left ul li a span {
    width: 70px;
  }

  .sidbar_left h4 {
    font-size: 16px;
    padding: 10px 15px 25px;
  }
  .sidbar_left .seemore {
    font-size: 16px;
    margin-left: 15px;
  }

  .side_right .popular_user {
    padding-left: 70px;
    height: 60px;
  }

  .side_right .popular_user .userimg {
    width: 60px;
    height: 60px;
  }

  .side_right .popular_user .btns {
    top: 10px;
  }

  .in_center .head a.hpl {
    padding: 13px 33px;
  }

  .in_center .head form {
    height: 55px;
  }

  .side_right .asuser {
    position: relative;
    padding-left: 72px;
    height: 70px;
  }

  .side_right .asuser .userimg {
    width: 45px;
    height: 45px;
  }

  .side_right .asuser .dropdown {
    top: 18px;
  }

  .addpost h3 {
    font-size: 24px;
  }

  .select_category .select_category_in {
    padding: 30px 15px 15px;
  }

  .select_category h3 {
    font-size: 22px;
  }

  .select_category .category_carousel .owl-item h5 {
    font-size: 16px;
  }

  .select_category .category_carousel .owl-item img {
    height: 50px;
  }

  .likeshare ul li img {
    height: 32px;
  }

  .likeshare ul li {
    font-size: 16px;
  }

  .likeshare form {
    width: 100%;
    padding-right: 150px;
  }

  .likeshare .comment {
    font-size: 16px;
    height: 48px;
  }

  .likeshare form input {
    height: 48px;
  }

  .listusr .asuser h5 {
    font-size: 16px;
  }
  .maindivkap .row {
    margin-right: -6px;
    margin-left: -6px;
  }

  .lgn {
    padding: 0 20px;
  }

  .maindivkap .imgg {
    height: 72px;
    line-height: 72px;
  }

  .listusr .testin {
    border-radius: 11px;
    border: 6px solid #ededed;
    max-height: 500px;
  }

  .maindivkap .row .col-sm-3.col-lg-2 {
    padding-right: 6px;
    padding-left: 6px;
  }

  .likeshare ul li {
    float: left;
    padding-left: 45px;
    margin-right: 20px;
  }

  .likeshare {
    padding-left: 0;
    padding-top: 30px;
    padding-bottom: 0;
  }

  .allcomment .commentin span.userimg {
    width: 70px;
    height: 70px;
  }

  .allcomment .commentin h5 {
    font-size: 16px;
  }

  .listusr .test img.hearticon {
    width: 45px;
  }

  .listusr .asuser h5 .d_report {
    padding: 6px 14px;
  }

  .membership .test .head_me {
    padding: 40px 15px 15px 15px;
  }

  .membership .test {
    width: 90%;
  }

  .membership .test ul li {
    margin: 16px 0;
    font-size: 16px;
  }

  .membership .test .head_me h3 {
    margin-bottom: 0;
    font-size: 30px;
    font-weight: bold;
  }

  .membership .testup:hover .head_me h5 {
    font-size: 22px;
  }

  .membership .testup:hover .test li {
    font-size: 16px;
  }

  .membership .testup:hover .head_me h3 {
    font-size: 35px;
  }

  .membership .testup:hover .test h4 {
    font-size: 24px;
  }

  .membership .testup:hover {
    padding: 40px 0;
  }

  .randem_profile .psotiv ul.alltk li {
    margin: 0 14px;
  }

  .randem_profile .psotiv ul.alltk li a {
    font-size: 16px;
    font-weight: bold;
  }

  .randem_profile .psotiv ul.alltk li a span {
    font-weight: 500;
  }

  .Steve .report_btni {
    left: 40%;
  }

  .inbanner {
    height: 430px;
  }

  .bus_det .tes ul li span img {
    height: 38px;
  }

  .bus_det .tes ul li {
    margin-right: 30px;
  }

  .bus_det .tes ul li span .close2 {
    height: 16px;
    right: -20px;
  }

  .bus_det .tes p {
    font-size: 16px;
  }

  .addfrbfil .testfrnd {
    min-height: 105px;
  }

  .addfrbfil .friend {
    min-height: 146px;
  }

  .dash_topmain {
    padding-left: 240px;
  }

  .dash_topmain.dash2 {
    padding-right: 240px;
  }

  .dash_topmain .dash_sidebar {
    width: 210px;
  }

  .dash_topmain.dash2 .psotiv_right2 {
    width: 225px;
  }

  .dash_topmain.dash2 .psotiv_right2 {
    width: 260px;
  }

  .loginpagetow h2 {
    font-size: 30px;
  }

  .dash_topmain.dash2 {
    min-height: 1000px;
  }

  .listusr .asuser p {
    font-size: 16px;
    color: #999595;
  }

  .listusr.favoriteclass .test {
    padding: 15px;
    border-radius: 10px;
    padding-bottom: 10px;
  }

  .notification .col-sm-6,
  .favoriteclass .col-sm-6 {
    flex: 0 0 33.33%;
    max-width: 33.33%;
    margin-bottom: 15px;
  }

  .listusr.favoriteclass .test {
    margin-bottom: 10px;
  }

  .allctg .text h3 {
    font-size: 16px;
  }

  .allctg .text {
    padding: 20px 10px;
    border-radius: 10px;
  }
}

@media only screen and (max-width: 1473px) {
  .main_menu .side_right span.logout {
    padding: 7px 0;
    top: 4px;
    right: -24px;
  }

  .allcomment .commentin span.userimg {
    width: 40px;
    height: 40px;
  }
}

/** Large devices (desktops, less than 1366px) **/
@media only screen and (max-width: 1359.98px) {
  .dash_topmain.dash2 {
    min-height: 860px;
  }

  .messages.chats .list form {
    margin-bottom: 20px;
  }

  .dash_topmain .dash_sidebar ul li a {
    padding: 10px 30px;
  }

  .dash_topmain .tab-content h3 {
    font-size: 24px;
  }

  .addpost h3 {
    font-size: 16px;
  }

  .in_center aside.emoji-picker-react {
    margin-left: 170px;
  }

  .dash_topmain.dash2 .psotiv_right2 {
    width: 215px;
  }

  .dash_topmain.dash2 {
    padding-right: 180px;
  }

  .addpost .addhead {
    padding-left: 110px;
  }

  .addpost .addhead span.userimg {
    height: 50px;
    width: 50px;
  }
  .addpost .addhead span.userimg {
    left: 17px;
  }
  .listusr .asuser {
    padding: 0px 10px 0px 80px;
  }

  .listusr .asuser .userimg {
    width: 45px;
    height: 45px;
  }

  .listpro .list1 h6 {
    font-size: 16px;
  }

  .addfrbfil .testfrnd p {
    font-style: 14px;
  }

  .messages.chats {
    padding-left: 310px;
  }

  .chats .inright .chatin p {
    background: #016afb;
    padding: 8px 20px;
    border-radius: 10px;
    display: inline-block;
    color: #fff;
    max-width: 400px;
    text-align: left;
    margin-bottom: 2px;
    font-style: italic;
  }

  .chats .inleft .chatin p {
    background: #016afb;
    padding: 8px 20px;
    border-radius: 10px;
    display: inline-block;
    color: #fff;
    max-width: 400px;
    text-align: left;
    margin-bottom: 2px;
    font-style: italic;
  }

  .chats .inleft .chatin span {
    font-weight: 400;
    color: #737583;
    display: block;
    font-style: italic;
  }

  .messages .test h4 {
    font-size: 16px;
  }

  .messages .test p {
    font-size: 16px;
  }

  .chats .innchat {
    width: 80%;
  }

  .messages .test h6 {
    font-size: 16px;
  }

  .listusr ul.likecomment li.group_user {
    font-size: 16px;
    padding-left: 40px;
  }

  .listusr ul.likecomment li.group_user img {
    height: 36px;
  }

  .in_center {
    padding: 10px 280px 30px 25px;
  }

  .select_category .category_carousel .owl-item h5 {
    font-size: 16px;
  }

  .maindiv {
    padding: 0 0 0 220px;
  }

  .maindiv .sidbar_left {
    width: 220px;
  }
  .logo-site {
    max-width: 190px;
  }
  .side_right {
    width: 255px;
  }

  .main_menu {
    padding: 30px;
  }

  .side_right .asuser h5 {
    font-size: 16px;
  }

  /*.addhead textarea {
    font-size: 16px;
}*/
  .addpost {
    padding: 20px 20px 75px 20px;
  }

  .addpost .addhead ul li img {
    height: 20px;
  }

  .addpost .btn {
    padding: 2px 23px;
    font-size: 16px;
    bottom: -59px;
  }

  .discussion .categoryhd h5 a {
    padding: 10px 30px;
    font-size: 16px;
  }

  .listusr .asuser h5 span {
    display: block;
    margin: 2px 0 10px;
    font-size: 16px;
  }

  .bus_det .tes ul {
    left: 206px;
  }

  .create_post .add_create textarea {
    height: 220px;
  }

  .loginpage .right_login {
    padding: 40px 130px 0 70px;
  }

  .loginpage .imglogin img {
    width: 220px;
  }

  .loginpage .right_login h3 {
    font-size: 28px;
  }

  .loginpage .right_login form {
    margin-top: 40px;
  }

  .loginpage .right_signup button.btn {
    margin-top: 10px;
    width: 80%;
  }

  .loginpage .plan {
    padding: 10px 20px;
    min-width: 220px;
  }

  .loginpage .plan h5,
  .loginpage .plan h2 span {
    font-size: 16px;
  }

  .loginpage .plan h2 {
    font-size: 16px;
  }

  .right_login.right_signup {
    padding-top: 70px !important;
  }

  .randem_profile {
    padding: 0 230px;
  }

  .randem_profile .psotiv_left2,
  .randem_profile .psotiv_right2,
  .randem_profile .psotiv_left,
  .randem_profile .psotiv_right {
    width: 220px;
  }

  .chats .intype button {
    width: 120px;
    font-size: 16px;
    height: 48px;
  }

  .chats .intype {
    padding: 30px 200px 30px 24px;
  }

  .chats .intype span {
    right: 160px;
  }

  .chats .intype button {
    right: 30px;
  }

  .bus_det .tes ul li span img {
    height: 48px;
  }

  .bus_det .tes ul li {
    margin-right: 42px;
  }

  .dash_topmain .dash_sidebar {
    width: 240px;
  }

  .dash_topmain .dash_sidebar {
    width: 185px;
  }

  .bus_det .tes ul li {
    float: left;
    margin-right: 80px;
    line-height: 75px;
    color: #fff;
    font-size: 16px;
    position: relative;
  }

  .bus_det .tes ul li {
    margin-right: 40px;
  }

  .dashboard .user2 {
    width: 50%;
  }

  .chats .intype span.paperclip {
    right: 165px;
  }

  .listusr .testin {
    height: auto;
  }

  .addpost .addhead ul {
    bottom: -66px;
  }

  .my_followers {
    margin-top: 35px;
  }

  .listusr.favoriteclass .test .asuser {
    padding: 0 0px 0px 66px;
  }

  .dash_topmain .dash_sidebar ul li a {
    padding: 10px 25px;
    font-size: 16px;
  }

  .listusr .asuser .join {
    width: 80px;
    right: 0;
  }

  .showchatt .testin {
    padding: 10px 12px 10px 72px;
  }

  .showchatt .testin .images {
    width: 43px;
    height: 43px;
  }

  .showchatt .testin::after {
    left: 65px;
  }
  .sarech-filed.in_center {
    padding-right: 20px;
  }
}

@media only screen and (max-width: 1200px) {
  .main_menu .side_right span.logout {
    right: -75px;
  }

  .addpost .addhead span.userimg {
    height: 50px;
    width: 50px;
  }

  .addpost .addhead span.userimg {
    left: 20px;
  }
}

@media only screen and (max-width: 1199.98px) {
  .admin-right .side_right {
    width: unset;
  }
  .sarech-filed.in_center {
    padding-top: 0 !important;
  }

  /* .side_right .asuser {
        display: none;
    } */

  .main_menu .side_right {
    padding: 0;
    border: navajowhite;
    width: 200px;
    position: absolute;
    right: 25px;
  }

  .main_menu .side_right .asuser {
    display: block;
  }

  .side_right .asuser .dropdown {
    right: 0;
  }

  .allcomment .commentin {
    padding: 15px 0px 15px 80px;
  }

  .allcomment .commentin span.userimg {
    top: 15px;
  }

  .listusr.favoriteclass .test {
    padding: 15px;
    border-radius: 10px;
  }

  .chats .innchat {
    width: 90%;
  }

  .dash_topmain.dash2 {
    min-height: 950px;
  }

  .chats .innchat .dddd {
    max-height: 400px;
  }

  .messagesmain {
    min-height: 880px;
  }

  .listusr .singleposttest .asuser h5 .d_report {
    float: left;
    width: 100%;
    text-align: center;
    margin: 5px 0;
  }

  .select_category .select_category_in {
    margin-top: 65px;
  }

  #Membership .testup h4 {
    font-size: 24px;
  }

  .addfrbfil .testfrnd {
    min-height: 185px;
  }

  .addfrbfil .testfrnd ul.followmessage li a {
    font-size: 16px;
  }

  .loginpagetow ul li {
    font-size: 16px;
  }

  .bus_det .tes ul li span .close2 {
    height: 12px;
    right: -14px;
  }

  .dash_topmain .dash_sidebar {
    width: 195px;
  }

  .dash_topmain {
    padding-left: 220px;
  }

  .bus_det .tes ul {
    position: relative;
    left: inherit;
    top: inherit;
    margin-bottom: 33px;
  }

  .bus_det .tes ul li:first-child::after {
    display: none;
  }

  .dashboard .user2 ul li {
    margin-right: 10px;
  }

  .dashboard .user ul li {
    padding: 4px 9px;
    font-size: 16px;
  }

  .dashboard .user2 ul li span {
    height: 40px;
    width: 40px;
  }

  .bus_det .tes p {
    font-size: 16px;
  }

  .dash_topmain.dash2 {
    padding-right: 210px;
  }

  .dash_topmain.dash2 .psotiv_right2 {
    width: 245px;
  }

  .dash_topmain .bus_det .tes + .tes img.cart {
    width: 78%;
  }

  .allctg .text img {
    height: 45px;
  }

  .allctg .text h3 {
    font-size: 16px;
    margin-top: 5px;
  }

  .dashboard .user2 ul li {
    width: 18%;
  }

  .in_center {
    padding: 25px;
  }

  .in_center.help {
    padding-right: 25px;
  }

  .main_menu {
    padding: 30px 25px;
  }

  .side_right {
    padding: 0;
  }

  .showchatt .testin {
    padding: 10px 12px 10px 84px;
  }

  .showchatt .testin .images {
    width: 50px;
    height: 50px;
  }

  .showchatt .testin::after {
    left: 74px;
  }

  span.logout {
    background: #016afb;
    padding: 11px 0;

    min-width: 100px;
  }
  .dash_sidebar ul li a {
    padding: 15px 35px;
    font-size: 16px;
  }

  .messages.chats {
    padding-left: 250px;
  }

  .chats .inright .chatin p {
    max-width: 250px;
    font-size: 16px;
  }

  .messages.chats .list {
    width: 250px;
  }

  .randem_profile {
    padding: 0 190px;
  }

  .randem_profile .psotiv_left2,
  .randem_profile .psotiv_right2,
  .randem_profile .psotiv_left,
  .randem_profile .psotiv_right {
    width: 180px;
  }

  .randem_profile .psotiv ul.alltk li {
    margin: 0 7px;
  }

  .psotiv_right2 .inpost .intap h2 {
    font-size: 16px;
  }

  .psotiv_right2 .inpost .intap h6 {
    font-size: 16px;
  }

  .randem_profile .psotiv ul.alltk li a {
    font-size: 16px;
  }

  .randem_profile .psotiv ul.alltk li a span {
    font-size: 13px;
  }

  .inbanner {
    height: 350px;
  }

  .Steve .userimg {
    height: 200px;
    width: 200px;
  }

  .loginpage .right_login {
    padding: 40px 35px;
  }

  .Steve .report_btni .report_btn1,
  .Steve .report_btni .report_btn2 {
    padding: 8px 12px;
  }

  .create_post .create_in ul.add_tag li {
    padding: 10px;
    min-width: 160px;
    font-size: 16px;
  }

  .create_post .add_create textarea {
    height: 200px;
  }

  .create_post h3 {
    font-size: 16px;
  }

  .create_post .create_in ul.select_in li select {
    padding: 9px 20px;
    font-size: 16px;
  }

  .create_post .create_in ul.add_tag .btn {
    padding: 8px;
    min-width: 44px;
    font-size: 16px;
    height: 44px;
  }

  .create_post .create_in ul.save_draft li {
    width: 25%;
  }

  .create_post .create_in ul.save_draft li a {
    font-size: 16px;
  }

  .create_post .create_in p {
    font-size: 16px;
  }

  .maindiv {
    padding: 0 0px 0 220px;
  }

  .side_right {
    width: 100%;
    position: relative;
    padding: 0 0;
    padding-bottom: 30px;
    border-top: 1px solid #016afa;
    padding-top: 30px;
  }

  .side_right.nav_right {
    height: auto;
    position: absolute;
    width: 230px;
    border: none;
  }

  .select_category {
    margin-top: 50px;
  }

  .discussion .categoryhd h3 {
    font-size: 16px;
  }

  .create_post .create_in {
    padding: 30px;
  }

  .loginpage .right_login {
    padding: 30px 35px;
  }

  .loginpage .right_login h3 {
    font-size: 24px;
  }

  .loginpage .right_login form {
    margin-top: 30px;
  }

  .loginpage p {
    font-size: 16px;
  }

  .loginpage .form-group .form-control {
    font-size: 16px;
    padding: 10px 20px;
    margin-bottom: 10px;
  }

  .loginpage h6 {
    font-size: 16px;
  }

  .loginpage .right_login .btn {
    padding: 10px 8px;
    margin-bottom: 20px;
  }

  .loginpage .right_login label {
    font-size: 16px;
    margin-bottom: 4px;
  }

  .loginpagetow .nextbutton {
    padding: 12px 8px;
    margin-bottom: 30px;
    margin-top: 0;
  }

  .loginpagetow h2 {
    font-size: 28px;
  }

  .loginpagetow ul li {
    font-size: 16px;
    margin-bottom: 15px;
  }

  .loginpagetow {
    padding-top: 90px;
  }

  .loginpagetow .signtwo .images img.vip {
    width: 52px;
  }

  .Steve ul li {
    padding: 8px 30px;
    margin: 0 8px;
    font-size: 16px;
  }

  .randem_profile .psotiv_left2 .testside .inpost h4 {
    font-size: 24px;
  }

  .bus_detedit input,
  .bus_detedit textarea {
    margin-bottom: 50px;
  }

  .bus_detedit label {
    font-size: 16px;
  }

  .messages .test h4 {
    margin-top: 10px;
    margin-bottom: 4px;
  }

  .messages.chats .list form {
    margin-bottom: 24px;
  }

  .dashboard .user {
    width: 400px;
    padding-left: 88px;
  }

  .dashboard .user .userimg img {
    width: 100%;
  }

  .dashboard .user p {
    font-size: 16px;
  }

  .dashboard .user h5,
  .dashboard .user h6 {
    font-size: 16px;
  }

  .bus_det .tes ul li span img {
    height: 38px;
  }
}

@media only screen and (max-width: 991.98px) {
  .chat .chat-history {
    max-height: 65vh !important;
    min-height: 65vh !important;
  }
  .in_center {
    margin-top: 38px;
  }
  .sarech-filed.in_center.hidepgo {
    margin-top: 0;
  }
  .asuser.taganger h5 span a {
    display: block;
  }
  .Mainheader-wapper {
    padding: 14px 10px;
  }
  .logo-site {
    max-width: 90px;
  }
  .Postall .singleposttest {
    border-radius: 12px;
  }

  .side_right .asuser .userimg {
    width: 50px;
    height: 50px;
  }

  .side_right .asuser h5 {
    font-size: 16px;
    margin-top: 4px;
  }

  .side_right .asuser .dropdown {
    top: 11px;
  }

  .side_right .asuser img.vip {
    height: 18px;
  }

  .main_menu .side_right {
    width: 155px;
  }

  .side_right .asuser {
    padding-left: 60px;
    height: 50px;
  }

  .allcomment .commentin {
    padding: 10px 0px 10px 80px;
  }

  .in_center .likeshare {
    padding-top: 25px;
  }

  .allcomment .commentin span.userimg {
    top: 10px;
  }

  .allcomment .commentin ul.likecomment {
    margin-top: 10px;
  }

  .allcomment .commentin ul.likecomment li {
    font-size: 16px;
    padding-left: 30px;
  }

  .allcomment .commentin ul.likecomment li img {
    height: 20px;
  }

  .dashboard .user h5,
  .dashboard .user h6 {
    font-size: 16px;
  }

  .listusr .asuser .userimg {
    width: 45px;
    height: 45px;
  }

  .chats .intype span.paperclip {
    right: 62px;
  }

  .dash_topmain.dash2 {
    padding-right: 0;
  }

  .dash_topmain.dash2 .psotiv_right2 {
    width: 190px;
  }

  .dash_topmain.dash2 .psotiv_right2 {
    width: 100%;
    position: relative;
    display: none;
  }

  .dash_topmain.dash2 {
    min-height: auto;
  }

  .dash_topmain.dash2 .bus_det .tes h4 {
    font-size: 16px;
  }

  .in_center .head a.hpl {
    padding: 10px 20px;
    font-size: 16px;
  }

  .in_center .head {
    padding-right: 215px;
  }

  .addpost {
    margin-top: 30px;
  }

  .addhead textarea {
    font-size: 16px;
  }

  .select_category h3 {
    font-size: 16px;
  }

  .allcomment .commentin p,
  body {
    font-size: 16px;
  }

  .select_category {
    margin-top: 30px;
  }

  .listusr {
    margin-top: 40px;
  }

  .listusr ul.likecomment li {
    font-size: 16px;
  }

  .likeshare ul li {
    padding-left: 45px;
  }

  .likeshare ul li img {
    height: 30px;
  }

  .likeshare {
    margin-top: 0;
    padding-top: 0;
    padding-left: 0;
    display: grid;
  }

  .likeshare form {
    width: 100%;
    float: left;
    margin-top: 20px;
  }

  .listusr ul.likecomment {
    margin-top: 15px;
  }

  .listusr ul.likecomment li img {
    height: 20px;
  }

  .allcomment {
    padding-top: 0;
    margin-top: 10px;
  }

  .discussion .categoryhd h5 a {
    padding: 10px 24px;
    font-size: 16px;
  }

  .loginpagetow .gologin img {
    width: 15px;
  }

  .randem_profile {
    padding: 0 0px 0 200px;
  }

  .randem_profile .psotiv_left2 {
    width: 200px;
  }

  .psotiv_right2,
  .randem_profile .psotiv_right {
    width: 100%;
    position: relative;
    right: inherit;
    top: inherit;
  }

  .randem_profile .psotiv ul.alltk li {
    margin: 0 4px;
  }

  .randem_profile .psotiv_right2 {
    width: 100%;
    border-top: 1px solid #ccc;
    margin-top: 20px;
    padding-top: 30px;
  }

  .randem_profile .psotiv ul.alltk li a {
    font-size: 16px;
  }

  .randem_profile .psotiv ul.alltk li a span {
    font-weight: 500;
  }

  .Steve a.report_btn {
    position: inherit;
    right: inherit;
    top: inherit;
    margin-top: 8px;
    display: inline-block;
  }

  .bus_det .tes h4 span {
    font-size: 18px;
  }

  .dashboard .user.usernew .uphead ul li {
    padding: 10px 6px;
    min-width: 146px;
  }

  .dashboard .user.usernew .uphead ul {
    display: flex;
  }

  .dashboard .user.usernew .uphead {
    padding-left: 155px;
  }

  .dashboard .user.usernew .uphead ul li {
    min-width: 125px;
  }

  .right_login.right_signup {
    padding-top: 40px !important;
  }

  .loginpage .right_login label {
    font-size: 16px;
    margin-bottom: 2px;
  }

  .loginpage .form-group .form-control {
    font-size: 16px;
  }

  .addpost .addhead ul li {
    margin-right: 15px;
  }

  .addpost .addhead ul li img {
    height: 20px;
  }

  .addpost .btn {
    padding: 9px 40px;
    bottom: -59px;
  }

  .in_center aside.emoji-picker-react {
    margin-left: 85px;
  }

  .likeshare .comment {
    font-size: 16px;
    padding: 0px 25px;
  }

  .likeshare form {
    padding-right: 130px;
  }

  .in_center .head form {
    height: 48px;
  }

  .addpost .addhead {
    padding-left: 80px;
  }

  .addpost .addhead span.userimg {
    height: 50px;
    width: 50px;
  }

  .listusr .asuser {
    padding: 0px 10px 0px 65px;
  }

  .dashboard .user h3 {
    font-size: 16px;
  }

  section.dashboard.dashboard_pro {
    min-height: auto;
  }

  .listusr.help.Postall {
    margin-top: 20px;
  }

  .listusr .testin {
    height: 78px;
    border-radius: 6px;
    border: 3px solid #ededed;
    margin-top: 4px;
  }

  .my_followers {
    margin-top: 20px;
  }

  .my_followers ul.followmessage li a {
    font-size: 16px;
  }

  .my_followers .test h5 {
    font-size: 16px;
  }

  .notification .col-sm-6,
  .favoriteclass .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .help .test {
    padding-bottom: 5px;
  }

  .dashboard .user .uphead .report_btni img {
    margin-left: -36px;
  }

  .dashboard .user2 {
    width: 60%;
  }

  .editInformation form .form-control {
    height: 40px;
    font-size: 16px;
  }

  .showposts li {
    width: auto;
  }

  .main_menu ul li {
    margin-right: 30px;
  }

  .dash_topmain {
    padding-left: 220px;
  }

  .bus_det .tes ul li {
    margin-right: 30px;
  }

  .bus_det .tes ul li span img {
    height: 35px;
  }

  .bus_det .tes ul li {
    font-size: 16px;
  }

  .dashboard .user {
    width: 100%;
  }

  .dashboard .user .userimg {
    width: 90px;
    height: 90px;
  }

  .dashboard .user {
    padding-left: 60px;
  }

  .dashboard .user ul {
    display: inline-block;
  }

  .dashboard .user {
    padding-left: 58px;
  }

  .bus_det .tes ul li:after {
    width: 1px;
  }

  .bus_det .tes ul li span .close2 {
    height: 12px;
    right: -4px;
  }

  .dashboard .user ul li {
    margin: 6px 6px 6px 0;
  }

  .bus_det .tes p {
    font-size: 16px;
  }

  .bus_detedit h5 {
    font-size: 16px;
  }

  .chats .intype {
    padding: 16px 90px 16px 16px;
  }

  .chats .intype button span {
    display: none;
  }

  .chats .intype button {
    width: 40px;
    font-size: 16px;
    height: 40px;
    right: 15px;
  }

  .chats .intype input {
    font-size: 16px;
  }

  .chats .intype span {
    right: 62px;
  }

  .messages .test p {
    display: none;
  }

  .messages .test h4 {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .messages.chats {
    padding-left: 200px;
  }

  .messages .test .images {
    width: 75px;
  }

  .chats .inright .chatin p {
    max-width: 200px;
    font-size: 16px;
  }

  .messages .test {
    padding-left: 82px;
    font-size: 16px;
    min-height: 76px;
  }

  .messages.chats .list {
    width: 200px;
  }

  .chats .innchat .dddd {
    padding: 0 15px;
  }

  .chats .inleft .chatin {
    padding-left: 50px;
  }

  .chats .inleft .chatin img {
    width: 43px;
    height: 43px;
  }

  .chats .inleft .chatin p,
  .chats .inright .chatin p {
    padding: 10px 15px;
  }

  .chats .innchat h4 {
    font-size: 16px;
  }

  .messages.chats .test h4 {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 0;
  }

  .bus_detedit input,
  .bus_detedit textarea {
    margin-bottom: 20px;
  }

  .bus_detedit label {
    font-size: 16px;
  }

  .randem_profile .psotiv_left2 .testside .inpost h4 {
    font-size: 16px;
  }

  .membership .test {
    width: 95%;
  }

  .loginpage .imglogin img {
    width: 180px;
  }

  .membership h2 span {
    top: -50px;
  }

  .loginpagetow .images img {
    width: 70px;
  }

  .membership .testup:hover {
    padding: 23px 0;
    margin-top: -50px;
  }

  .membership .testup:hover .test h4 {
    font-size: 16px;
  }

  .signone.bird,
  .signtwo.bird {
    border: 2px solid #016afa;
    padding: 30px;
  }

  .membership .test h4 {
    font-size: 16px;
  }

  .membership h2 span img {
    width: 15px;
  }

  .membership .testup:hover .head_me h5 {
    font-size: 16px;
  }

  .membership .testup:hover .test {
    width: 96%;
  }

  .membership .testup:hover .test li {
    font-size: 16px;
  }

  .membership .testup:hover .head_me h3 {
    font-size: 25px;
  }

  .membership h2 {
    font-size: 30px;
    margin: 10px 0 90px;
  }

  .membership .test ul {
    padding: 0 28px;
  }

  .membership .test .head_me h5 {
    font-size: 16px;
  }

  .membership .test .head_me h3 {
    font-size: 24px;
  }

  .membership .test ul li {
    margin: 14px 0;
    font-size: 16px;
  }

  .create_post .create_in {
    padding: 20px;
  }

  .create_post .create_in ul.add_tag li {
    min-width: 125px;
  }

  .create_post .create_in ul.save_draft li a {
    font-size: 16px;
  }

  .discussion .categoryhd h6 ul li {
    margin-right: 12px;
  }

  .listusr .test {
    padding: 20px;
    border-radius: 12px;
  }

  .listusr ul.likecomment li {
    padding-left: 30px;
    margin-right: 20px;
  }

  .listusr ul.likecomment li.group_user {
    padding-left: 40px;
    font-size: 16px;
  }

  .discussion .categoryhd h6 ul li a {
    font-size: 16px;
    padding: 6px 13px;
  }

  .discussion .categoryhd h5 {
    font-size: 16px;
  }

  .in_center .head form input {
    font-size: 16px;
  }
  .main_menu .side_right span.logout {
    right: -98px;
  }

  .side_right .asuser h5 {
    margin: 15px 6px;
  }

  .side_right .asuser .userimg {
    width: 40px;
    height: 40px;
  }

  .side_right .asuser .userimg {
    top: 6px;
  }

  .addpost .addhead span.userimg {
    height: 50px;
    width: 50px;
  }

  .addpost .addhead span.userimg {
    left: 11px;
  }

  .listusr .asuser .userimg {
    left: 4px;
    width: 40px;
    height: 40px;
  }

  .listusr .testin {
    height: 133px;
  }

  .allcomment .commentin span.userimg {
    width: 40px;
    height: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .pagination button {
    font-size: 14px;
    padding: 5px;
    height: 26px;
    width: 30px !important;
    line-height: 15px;
  }
  .pagination {
    gap: 8px !important;
    overflow-x: scroll !important;
    justify-content: flex-start !important;
  }
  .testul {
    height: 250px !important;
    margin-top: 50px !important;
  }
  .ticket-top.main_menu {
    overflow-x: unset !important;
  }
  .dropdown-menu.customfine.show ul {
    margin: 0;
  }
  .planpopup {
    display: block !important;
  }
  .planpopup button span {
    padding: 10px 18px !important;
    font-size: 12px !important;
  }
  .planbox {
    margin-bottom: 30px !important;
    border: 1px solid #eeeeeea3;
    padding: 20px;
  }

  .planpopup.p-5 {
    padding: 25px !important;
  }
  .custom-header h5 {
    font-size: 18px;
  }

  .planpopup h4 {
    margin-bottom: 20px;
  }
  .chat-app .people-list {
    height: 465px;
    width: 100%;
    overflow-x: auto;
    background: #fff;
    left: -400px;
    display: none;
  }
  .in_center {
    padding: 20px 10px !important;
  }
  .side_b.close {
    color: #fff;
    border: none;
    position: absolute;
    width: 34px;
    top: 10px;
    left: 189px;
    border-radius: 0;
  }
  .chatuser {
    display: block;
  }
  .chat-btn {
    width: 30px;
    height: 30px;
  }
  .listusr .testin {
    height: auto !important;
  }
  .chat-app .people-list.open {
    left: 0;
  }

  .chat-app .chat {
    margin: 0;
  }

  .chat-app .chat .chat-header {
    border-radius: 0.55rem 0.55rem 0 0;
  }

  .chat-app .chat-history {
    height: 300px;
    overflow-x: auto;
  }
  .main_menu ul li .dropdown-menu {
    max-height: 450px;
    width: 250px !important;
    left: -20px !important;
    z-index: 9;
  }
  .main_menu ul {
    margin-left: 0px !important;
  }
  .dropdown-menu.show ul {
    width: 248px !important;
  }
  ul.testul {
    left: 20px;
  }
}
@media only screen and (min-width: 100px) and (max-width: 363px) {
  .main_menu ul li .dropdown-menu.Prof {
    left: 25px !important;
  }
  .main_menu ul li .dropdown-menu {
    top: 0px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .chat-app .chat-list {
    height: 650px;
    overflow-x: auto;
  }

  .chat-app .chat-history {
    height: 600px;
    overflow-x: auto;
  }
  .main-wapper-popup input {
    font-size: 18px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .chat-app .chat-list {
    height: 480px;
    overflow-x: auto;
  }

  .chat-app .chat-history {
    height: calc(100vh - 350px);
    overflow-x: auto;
  }
}

@media only screen and (max-width: 1024px) {
  .secondright {
    margin-left: auto;
    width: 90% !important;
  }
}
@media only screen and (max-width: 767.98px) {
  .listusr .singleposttest .asuser h5 {
    font-size: 16px;
  }

  footer.main_footer {
    padding: 18px;
  }

  .singleposttest {
    border-radius: 10px;
  }

  .main_menu {
    padding: 18px 15px;
  }

  .main_menu .side_right {
    height: 50px;
  }

  .side_right .asuser h5 {
    font-size: 16px;
    margin-top: 4px;
  }

  .side_right .asuser .dropdown {
    top: 13px;
  }

  .side_right .asuser .userimg {
    width: 45px;
    height: 45px;
  }

  .side_right .asuser {
    position: relative;
    padding-left: 50px;
    height: 45px;
  }

  .main_menu .side_right span.logout {
    padding: 11px 0;
    font-size: 14px;
    min-width: 85px;
  }

  .messages .test {
    margin-bottom: 12px;
  }

  .dash_topmain .side_b {
    left: 0;
  }

  .dashboard .user .uphead h6 {
    margin: 6px 0;
  }

  .dashboard .user .uphead {
    margin-top: 100px;
  }

  .bus_det .tes {
    padding: 8px 0;
  }

  .dash_sidebar.main {
    display: block !important;
    position: fixed;
    left: 0;
    height: 100%;
    background: #016afa;
    top: 0;
    width: 240px;
    z-index: 99999999;
    border-radius: 0;
    padding: 15px;
    overflow-y: auto;
  }

  .dash_sidebar.main .side_b.close {
    position: fixed;
    left: 205px;
    /* background: #0153dc; */
    border: none;
    z-index: 9999;
    opacity: 1;
  }

  .side_b {
    padding: 5px 7px;
    left: 15px;
    top: 17px;
  }

  .messages .test {
    padding-left: 65px;
    min-height: 60px;
    border-radius: 6px;
  }

  .messages .test .images {
    width: 55px;
  }

  .nwekaptest {
    padding-top: 0;
  }

  .main_menu .side_right {
    width: 100px;
    height: 45px;
  }
  .dashboard .user .userimg {
    top: -125px;
    width: 105px;
    height: 105px;
  }

  .lgn {
    padding: 0 10px;
  }

  .dash_topmain {
    min-height: auto;
  }

  .HelpForm .modal-header {
    padding: 15px 25px;
  }

  .HelpForm h5 {
    font-size: 24px;
  }

  .HelpForm .modal-body {
    padding: 25px;
  }

  .HelpForm label {
    font-size: 16px;
  }

  .HelpForm .modal-footer button {
    padding: 8px;
    font-size: 16px;
  }

  .listusr.discussion.help {
    margin-top: 30px;
  }

  .HelpForm .userimg {
    width: 130px;
    height: 130px;
    border: 6px solid #016afb1f;
  }

  .HelpForm .userimgmain h6 {
    font-size: 16px;
  }

  .HelpForm select,
  .HelpForm input {
    padding: 8px 12px;
  }

  .in_center .head a.hpl {
    min-width: 202px;
    height: 48px;
    line-height: 48px;
    padding: 0;
  }

  .main_menu ul li {
    margin-right: 20px;
  }

  .main_menu ul {
    margin-left: 60px;
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .main_menu ul li a {
    font-size: 16px;
  }

  .dash_topmain .tab-content h3 {
    font-size: 16px;
    margin-top: 28px;
    margin-bottom: 15px;
  }

  .dashboard .user.usernew .uphead ul li {
    min-width: 134px;
    padding: 8px 6px;
  }

  .dashboard .user.usernew .uphead ul {
    display: inline-block;
    margin-top: 10px;
  }

  .dashboard .user.usernew .uphead {
    padding-left: 0;
  }

  .dash_topmain.dash2 {
    padding-right: 0;
  }

  .dash_topmain.dash2 .psotiv_right2 {
    width: 100%;
    position: relative;
    left: 0;
  }

  .addpost {
    padding: 20px 20px 80px 20px;
  }

  .addpost .addhead ul {
    bottom: -70px;
  }

  .addpost .btn {
    bottom: -60px;
  }

  .addhead textarea {
    font-size: 16px;
    border: 1px solid #e6eaee;
  }

  .in_center .head form input {
    padding: 10px 20px;
  }

  .maindiv {
    padding: 0;
  }

  .in_center {
    padding: 20px;
  }

  .in_center.help {
    padding-right: 20px;
  }

  .maindiv .sidbar_left {
    display: none;
  }

  .maindiv.main .sidbar_left {
    display: block;
    z-index: 99;
  }

  .sidbar_left ul li a span img {
    max-height: 20px;
  }

  .side_b {
    border: 2px solid #016afb;
    padding: 9px;
    color: #016afb;
    /* position: absolute; */
    left: 20px;
    top: 22px;
    font-size: 21px;
    border-radius: 4px;
    display: inline-flex;
    margin: 0 0 10px;
  }

  .create_post .create_in ul.select_in li label {
    font-size: 16px;
  }

  footer.main_footer {
    padding: 25px;
  }

  .membership .next {
    padding: 12px;
    font-size: 16px;
    margin: 60px auto 0;
  }

  .loginpage p {
    font-size: 16px;
  }

  .messages a.close img,
  .singlepost a.close img,
  .my_followers a.close img {
    width: 16px;
  }

  .messages {
    margin-top: 45px;
  }

  .messages a.close,
  .singlepost a.close,
  .my_followers a.close {
    top: -38px;
  }

  .in_center {
    min-height: auto;
  }

  .messages .test {
    margin-bottom: 15px;
  }

  .messages a.seemore,
  .my_followers a.seemore {
    font-size: 16px;
  }

  .inbanner {
    height: 270px;
  }

  .Steve .userimg {
    height: 150px;
    width: 150px;
  }

  .Steve {
    margin-top: -80px;
  }

  .Steve ul li {
    padding: 8px 20px;
    margin: 4px 3px;
    min-width: 195px;
    font-size: 16px;
  }

  .Steve .report_btni {
    left: 64%;
    top: 55px;
  }

  .messages.chats .list {
    margin-top: 0;
  }

  .dashboard .user.usernew .uphead a.help {
    font-size: 16px;
    margin-bottom: 0;
    margin-top: 210px;
  }

  .dashboard .user ul li {
    margin: 4px 5px;
  }

  .nwekaptest {
    max-height: 520px;
  }

  .chats .innchat .dddd {
    max-height: 390px;
  }

  .dashboard .user.usernew {
    margin-top: -125px;
  }

  .likeshare form input {
    padding: 10px 15px;
  }

  .dashboard {
    min-height: auto;
    padding-bottom: 30px;
  }

  .dash_topmain {
    padding-bottom: 0px;
  }

  .dashboard .user.usernew .uphead .userimg {
    top: -90px;
  }

  .dashboard .user.usernew .uphead ul li {
    font-size: 16px;
  }

  .listusr {
    margin-top: 35px;
  }

  .my_followers .test h5 {
    font-size: 16px;
  }

  .listusr.favoriteclass .test {
    margin-bottom: 0;
  }

  .create_post .create_in ul.save_draft {
    margin: 20px 0 0;
  }

  .create_post .create_in {
    border-radius: 15px;
  }

  .create_post .create_in h4 {
    margin: 10px 0;
    font-size: 16px;
  }
  .dashboard .user.usernew .uphead h5.socialicon {
    width: 100%;
    float: left;
  }

  .dash_topmain .dash_sidebar {
    display: none;
  }

  .bus_det {
    padding: 0 0;
  }

  .bus_det .tes {
    padding: 12px 0;
  }

  .dash_topmain .side_b {
    color: #fff;
    left: 12px;
    top: -50px;
    background: #016afb;
    cursor: pointer;
  }

  .dash_topmain.main .dash_sidebar .side_b.close {
    border: none;
    left: 176px;
  }

  .dash_topmain.main .dash_sidebar {
    display: block;
    background: #2d48d8;
    position: fixed;
    left: 0;
    z-index: 9999;
    height: 100%;
    top: 0;
    padding: 30px 15px;
    width: 210px;
    border-radius: 0;
  }

  .dashboard .user {
    padding: 65px 24px 10px;
    text-align: center;
    margin-left: 0;
  }

  .dash_topmain .dash_sidebar ul li a {
    padding: 10px 16px;
    font-size: 16px;
    border-radius: 6px;
    font-weight: 500;
  }

  .dashboard .tab-content {
    margin-top: 0;
    border-top: 1px solid #ffffff4d;
  }

  .dash_topmain {
    padding-left: 0;
  }

  .dashboard .user .userimg {
    left: 0;
    right: 0;
    margin: auto;
    top: -90px;
  }

  .dashboard .user2 {
    width: 100%;
    text-align: center;
  }

  .dashboard .user2 ul {
    display: inline-block;
  }

  .dashboard .user2 ul li {
    margin: 0 1%;
    width: 23%;
  }

  .user .uphead .report_btni {
    text-align: center;
    top: -108px;
    margin: auto;
  }

  .dashboard .user h6 {
    margin-top: 18px;
    margin-bottom: 25px;
  }

  .randem_profile .psotiv ul.alltk li {
    margin: 7px 17px;
  }

  .bus_det .tes h4 span {
    font-size: 16px;
  }

  .bus_det .tes img.cart {
    width: 100%;
  }

  .randem_profile .psotiv {
    padding-top: 35px;
    min-height: auto;
  }

  .randem_profile {
    margin-bottom: 45px;
  }

  .randem_profile .psotiv_left2,
  .randem_profile .psotiv_left {
    display: none;
  }

  .randem_profile {
    padding: 0;
  }

  .loginpagetow .nextbutton {
    width: 100%;
  }

  .messages .test h4 {
    font-size: 16px;
    margin-top: 30px;
  }

  .loginpagetow .signtwo {
    padding: 40px 25px;
  }

  .loginpagetow .signone {
    padding: 30px 15px;
  }

  .side_right {
    width: 100%;
    position: relative;
    padding: 0 0;
    padding-bottom: 30px;
    border-top: 1px solid #016afa;
    padding-top: 30px;
  }

  .loginpage .imglogin {
    width: 100%;
    top: inherit;
    margin: 30px 0;
    position: relative;
  }

  .loginpage {
    height: 100%;
    background: #016afb;
  }

  .loginpage .right_login {
    padding: 30px;
    width: 400px;
    bottom: 20px;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 15px;
    overflow-y: auto;
    position: relative;
  }

  .listusr {
    margin-top: 0;
  }

  .loginpage .imglogin img {
    width: 120px;
    margin-top: -68px;
  }

  .loginpage .right_login h3 {
    font-size: 16px;
  }

  .loginpage .right_login .btn {
    padding: 10px 8px;
    font-size: 16px;
    margin-bottom: 0;
  }

  .loginpage .right_login form {
    margin-top: 20px;
  }

  .loginpage .form-group .form-control {
    padding: 8px 15px;
  }

  .messages a.close,
  .singlepost a.close,
  .my_followers a.close {
    position: absolute;
    right: 0;
    top: -54px;
  }

  .listusr .asuser h5 span {
    font-size: 16px;
  }

  .sidbar_left ul li a span {
    width: 58px;
  }

  .side_b.close {
    color: #fff;
    border: none;
    position: absolute;
    width: 34px;
    top: 13px;
    left: 16px;
    border-radius: 0;
    font-size: 18px;
    margin-bottom: 0 !important;
  }
  #plist .input-group {
    margin-top: 20px;
  }

  .in_center .head form input {
    font-size: 16px;
  }

  .in_center .head form {
    height: 48px;
  }

  .in_center .head a.hpl {
    padding: 7px 27px;
    font-size: 16px;
  }

  .in_center .head {
    padding-left: 00px;
    padding-right: 215px;
  }

  .addpost h3 {
    font-size: 16px;
  }

  #Advertisement p {
    font-size: 16px;
  }
}

@media only screen and (min-width: 100px) and (max-width: 575px) {
  .singleposttest .contants a {
    position: relative;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .viewprofile .help .singleposttest .contants {
    margin-bottom: 0px !important;
  }
  .dashboard .user .uphead .report_btni {
    left: 0;
    text-align: center;
    width: 100%;
  }
  .dashboard .user .uphead .report_btni img {
    margin-left: 0px;
  }
  .SocialIcons {
    display: block;
  }
}

@media only screen and (max-width: 575.98px) {
  .ticket-top.main_menu {
    padding: 14px 10px !important;
    overflow-x: scroll;
  }

  .main_menu .side_right span.logout {
    background: #016afb;
    padding: 7px 0;
    min-width: 77px;
  }

  .chat-popup .appendchatuser {
    width: 100%;
    height: 100%;
  }

  .chatstart {
    height: 100%;
  }

  .chat-popup.main {
    width: 100%;
    top: 0;
    height: 100%;
  }

  .side_right .asuser h5 {
    display: none;
  }

  .listusr ul.likecomment li {
    font-size: 16px;
    padding-left: 25px;
    margin-right: 15px;
  }

  .listusr ul.likecomment li.group_user img {
    height: 20px;
  }

  .listusr ul.likecomment li.group_user {
    padding-left: 30px;
    font-size: 16px;
  }

  .side_right .asuser img.vip {
    height: 15px;
  }

  .side_right .asuser img.vip {
    height: 13px;
    margin-top: -13px;
  }

  .side_right .asuser img.vip {
    height: 15px;
  }

  .side_right .asuser .dropdown img {
    height: 20px;
  }

  .side_right .asuser .userimg {
    width: 40px;
    height: 40px;
  }

  .side_right .asuser {
    padding-left: 40px;
    height: 44px;
  }

  .main_menu ul li {
    margin-right: 16px;
  }

  .side_right .asuser h5 {
    font-size: 16px;
    margin-top: 2px;
  }

  .main_menu ul li span {
    font-size: 16px;
  }

  .dashboard .user {
    padding: 40px 15px 10px;
    border-radius: 15px;
  }

  .listusr .test {
    padding: 15px 15px;
  }

  .messages.chats::after {
    display: none;
  }

  .addpost .btn {
    padding: 7px 22px;
  }

  .loginpage .imglogin {
    margin: 0 0 30px;
  }

  .bus_det .tes .btn2ul {
    margin-top: 2px;
  }

  .listusr .asuser {
    padding: 0px 0px 10px 62px;
  }

  .listusr .asuser .userimg {
    width: 40px;
    height: 40px;
  }

  .loginpage .imglogin {
    padding-top: 6%;
  }

  .listusr .testin {
    margin-bottom: 10px;
  }

  .side_right .popular_user .btns {
    top: 0;
    float: left;
    position: relative;
  }
  img.vip-feed {
    height: 30px;
  }

  .Ticket-header2 {
    display: block;
    text-align: center !important;
  }
  .main_menu_new ul li {
    margin-right: 0;
    width: 100%;
    display: block;
    margin-top: 9px;
  }

  .side_right .test p {
    display: inline-flex;
  }

  .side_right .popular_user {
    padding-top: 0;
  }

  .dashboard .user2 ul li {
    padding: 6px 0;
  }

  .allcomment .commentin {
    padding: 12px 0px 10px 52px;
  }

  .dash_topmain .side_b {
    left: 16;
  }

  .dashboard {
    padding: 0 5px;
  }

  .allcomment .commentin span.userimg {
    width: 40px;
    height: 40px;
  }

  .allcomment .commentin span.userimg {
    top: 15px;
  }

  .likeshare form {
    padding-right: 110px;
  }

  .likeshare .comment {
    font-size: 16px;
    padding: 0px 18px;
    height: 40px;
  }

  .side_right {
    padding-bottom: 15px;
    padding-top: 15px;
  }

  .likeshare form input {
    height: 40px;
    font-size: 16px;
    border-radius: 6px;
  }

  .listusr .asuser h5 .d_report,
  .allcomment .commentin h5 .reportbtn {
    font-size: 16px;
    padding: 4px 6px;
  }

  .listusr .asuser .dropdown {
    right: 0px;
  }

  .messages.chats {
    padding-left: 0;
  }

  .messages.chats .list {
    width: 100%;
    position: relative;
  }

  .chats .innchat {
    margin-left: 0;
  }
  /* 
    .bus_det .tes ul li:first-child {
        width: 100%;
    } */

  .bus_det .tes ul li {
    line-height: 55px;
  }

  .bus_det .tes ul li:nth-child(2):after {
    display: none;
  }

  .messages .test p {
    display: block;
  }

  .chats .innchat {
    width: 100%;
  }

  .listusr .singleposttest .asuser h5 .d_report {
    float: right;
    width: auto;
  }

  .lgn {
    padding: 0;
  }

  .loadingicon img {
    width: 85px;
  }

  .create_post .add_create textarea {
    height: 150px;
    padding: 15px;
    border-radius: 10px;
  }

  .Postall .singleposttest .contants p {
    min-height: auto;
  }

  .bus_det .tes h4 {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .bus_det .tes {
    padding: 5px 0;
  }

  .bus_det.editInformation {
    padding-bottom: 30px;
  }

  .editInformation button {
    font-size: 16px;
    margin-top: 10px;
  }

  #Membership .testup {
    padding: 20px;
    border-radius: 10px;
  }

  .showposts li {
    width: 49%;
    margin: 0 0.5%;
  }

  ul.ul_discussion {
    margin-bottom: 10px;
    margin-top: 0;
  }

  .showposts {
    margin-top: 0;
  }

  .listusr.discussion {
    margin-top: 20px;
  }

  .listusr.discussion .listpro {
    margin-bottom: 10px;
  }

  .dashboard .user .uphead .report_btni img {
    height: 30px;
  }

  #Membership .testup ul li {
    margin-bottom: 6px;
  }

  .nwekaptest {
    padding: 0;
  }

  .allctg {
    margin-bottom: 0;
  }

  .in_center .head form {
    height: 42px;
  }

  .in_center .head a.hpl {
    height: 42px;
    line-height: 42px;
    font-size: 16px;
    font-weight: 600;
  }

  .in_center .head .btn img {
    width: 18px;
  }

  .in_center .head a.hpl img {
    width: 24px;
    margin-top: -4px;
  }

  .listusr .test {
    margin-bottom: 28px;
  }

  .listusr ul.likecomment {
    margin-top: 20px;
  }

  .listusr ul.likecomment li {
    height: 22px;
    line-height: 22px;
  }

  .in_center {
    padding-top: 10px;
  }
  .dashboard .user2 h3 {
    margin-top: 20px;
  }

  .Steve a.report_btn {
    position: inherit;
    right: inherit;
    top: inherit;
    margin-top: 8px;
    display: inline-block;
  }

  .Steve .report_btni {
    left: inherit;
    top: inherit;
    position: relative;
    margin-top: 12px;
  }

  .messages .test p,
  .messages .test h6 {
    font-size: 16px;
  }

  .loginpagetow h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .messages .test h6 img {
    width: 20px;
    margin-right: 0 !important;
  }

  .messages .test h6 {
    position: relative;
    right: inherit;
    top: inherit;
    margin-top: 8px;
  }

  .messages .test h4 {
    margin-top: 12px;
  }

  .loginpage .right_login {
    padding: 20px !important;
    width: 90%;
  }

  .loginpage.vipsignup .right_login form .form-group {
    margin-bottom: 10px;
  }

  .loginpagetow .signtwo {
    padding: 30px 15px;
  }

  .loginpagetow .nextbutton {
    padding: 10px 8px;
    font-size: 16px;
  }

  .loginpagetow .images img {
    width: 75px;
  }

  .membership .test .head_me {
    padding: 30px 15px 10px 15px;
  }

  .membership .test .head_me h5 {
    font-size: 16px;
  }

  .membership .next {
    width: 100%;
    margin: 40px auto 0;
  }

  .loginpage .imglogin img {
    margin-top: 0;
  }

  .membership h2 {
    font-size: 24px;
    margin: 10px 0 45px;
  }

  .create_post .create_in ul.select_in {
    margin-bottom: 8px;
  }

  .create_post .create_in ul.add_tag li {
    min-width: 114px;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .create_post .create_in .url {
    padding-left: 50px;
  }

  .create_post .create_in .url img {
    width: 34px;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .create_post .create_in .input {
    font-size: 16px;
  }

  .create_post .create_in ul.save_draft {
    margin: 15px 0 0;
  }

  .create_post .create_in ul.save_draft li {
    width: 100%;
    margin: 5px 0;
  }

  footer.main_footer {
    padding: 20px;
  }

  .create_post .create_in p {
    font-size: 16px;
  }

  .create_post .create_in ul.save_draft li a {
    padding: 11px;
  }

  .create_post .create_in ul.select_in li {
    float: left;
    margin: 0 1%;
    text-align: center;
    width: 48%;
  }

  .create_post .create_in ul.select_in li select {
    padding: 9px 20px;
    font-size: 16px;
    width: 100%;
  }

  .create_post .create_in ul.select_in li label {
    font-size: 16px;
  }

  .create_post .create_in {
    padding: 20px 15px;
  }

  .listusr .asuser .join {
    width: 60px;
    right: 0;
    font-size: 16px;
  }

  .listusr .asuser .join img {
    width: auto;
    height: 22px;
  }

  .discussion .categoryhd h6 ul {
    display: inline-block;
  }

  .discussion .categoryhd h6 ul li {
    margin-bottom: 10px;
  }

  .discussion .categoryhd h3 {
    font-size: 16px;
  }

  .listusr .asuser h5 {
    font-size: 16px;
    margin-bottom: 2px;
  }
  .dash_topmain .dash_sidebar ul li a {
    padding: 2px 10px;
    font-size: 16px;
    border-radius: 2px;
    font-weight: 500;
  }

  .side_right .asuser .dropdown .dropdown-menu {
    top: 38px !important;
  }

  .allctg .mb-3 {
    margin-bottom: 10px !important;
  }

  .listusr .test img.hearticon {
    width: 32px;
    margin-top: -30px;
  }

  .allcomment .commentin ul.likecomment li {
    height: 20px;
    line-height: 20px;
  }

  .listusr .asuser p {
    margin-bottom: 0;
  }

  footer.main_footer {
    padding: 12px;
  }

  .listusr {
    margin-top: 22px;
  }

  .addpost {
    margin-top: 20px;
  }

  .addpost {
    padding: 20px 15px 70px 15px;
    border-radius: 12px;
  }

  .addpost .addhead ul {
    bottom: -62px;
  }

  .addpost .btn {
    bottom: -53px;
  }

  .addpost h3 {
    font-size: 16px;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }

  .listusr .asuser h5 {
    font-size: 16px;
    word-break: break-all;
  }

  .allcomment .commentin ul.likecomment {
    margin-top: 6px;
  }

  .in_center .likeshare {
    padding-top: 20px;
  }

  .messages .test {
    padding-left: 60px;
    min-height: 50px;
  }

  .messagesmain {
    padding: 0px !important;
  }

  .chats .intype {
    padding: 10px 65px 10px 5px;
  }

  .norecord {
    margin-top: 40px;
  }

  .my_followers .test {
    margin-bottom: 15px;
  }

  .my_followers .test {
    border-radius: 6px;
    padding-left: 120px;
    min-height: 120px;
  }

  .my_followers .test .userimg {
    width: 106px;
    border-radius: 10px;
  }

  .listusr.favoriteclass .test {
    padding: 12px;
    border-radius: 6px;
  }

  .listusr.favoriteclass ul.likecomment li img {
    height: 20px;
  }

  .listusr.favoriteclass ul.likecomment li {
    padding-left: 27px;
    margin-right: 20px;
    height: 20px;
  }

  .listusr.favoriteclass .test img.hearticon {
    width: 28px;
  }

  .listusr.favoriteclass .test .asuser {
    padding: 0 0px 0px 64px;
    min-height: 52px;
  }

  .dashboard .user2 h3 {
    font-size: 16px;
    font-weight: 600;
  }
  .bottomreport a {
    font-size: 12px;
  }
  .dash_topmain .tab-content h3 {
    font-size: 16px;
    margin-top: 24px;
    margin-bottom: 8px;
    font-weight: 600;
  }

  .main_menu .side_right {
    width: 50px;
  }

  .main_menu ul {
    margin-left: 50px;
  }

  .chat-popup .appendchatuser {
    padding: 50px 0 155px;
    background: #fff;
    overflow-y: auto;
  }

  .chat-popup h1 {
    background: #fff;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
  }

  .form-container textarea {
    min-height: 100px;
    position: fixed;
    bottom: 42px;
    left: 0;
  }

  .form-container .btn {
    position: fixed;
    bottom: 0;
    left: 0;
    margin-bottom: 0;
    opacity: 1;
  }

  .chat-popup h1 span {
    display: none;
  }
}
@media only screen and (min-width: 100px) and (max-width: 480px) {
  .addfrbfil .testfrnd ul.followmessage li a {
    font-size: 14px;
  }
  .chatstart .container_left span {
    font-size: 14px;
  }
  .flowmsg {
    display: flex;
    flex-direction: row !important;
  }
  .dropdown-menu.show {
    width: 200px !important;
  }

  .show li {
    width: 100%;
  }

  .show ul {
    margin-left: 0 !important;
  }
  .followmessage {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .my_followers ul.followmessage li a {
    padding: 3px 0px;
    font-size: 14px;
  }
  .my_followers .test .userimg img {
    max-width: 60px;
  }
  .showchatt .testin .images {
    width: 45px;
    height: 45px;
  }
  .chatuser {
    display: block;
  }
  .chat-btn {
    width: 30px;
    height: 30px;
  }
  .addpost .addhead ul li img {
    height: 20px;
  }

  .main_menu .side_right {
    right: 15px;
    top: -38px;
  }

  .addpost .btn {
    padding: 7px 20px;
  }

  .in_center {
    padding: 20px 10px !important;
  }

  .main_menu {
    border-bottom: 1px solid #ccc;
  }

  .side_right .asuser .dropdown img {
    height: 16px;
  }

  .my_followers .test {
    min-height: 100px;
  }

  .main_menu ul {
    margin-left: 0;
    margin-top: 50px;
    width: 100%;
    text-align: left;
  }

  .allctg .mb-3 {
    margin-bottom: 8px !important;
  }

  .listusr.favoriteclass .test {
    padding: 12px 12px 8px;
    border-radius: 6px;
  }

  .addpost {
    padding: 15px 15px 60px 15px;
  }

  .addpost .addhead ul {
    bottom: -55px;
  }

  .addpost .btn {
    padding: 2px 17px;
    bottom: -45px;
  }

  .addpost .addhead {
    padding-left: 0;
    padding-top: 100px;
  }

  .in_center aside.emoji-picker-react {
    margin-left: 15px;
    width: 264px;
    margin-top: -18px;
  }

  .likeshare form {
    padding-right: 0;
  }
  .likeshare .comment.comment2 {
    position: absolute !important;
    top: -8px !important;
    width: unset !important;
  }
  .likeshare .comment.comment2 .send {
    filter: brightness(0) invert(1) !important;
    margin-right: 0 !important;
  }

  .likeshare .comment {
    display: block;
    position: initial !important;
    float: none;
    width: 100%;
    max-width: 100px;
    margin-top: 9px;
    background: #016afb;
    border-radius: 30px;
  }

  .likeshare .comment .send {
    display: block;
  }

  .likeshare .comment .send img {
    width: 20px;
  }

  .Postall .singleposttest {
    border-radius: 15px;
  }

  .dashboard .user .userimg {
    top: -171px;
    width: 90px;
    height: 90px;
  }

  .addfrbfil .testfrnd ul.followmessage li {
    width: 100%;
  }

  .addfrbfil .testfrnd {
    min-height: 140px;
  }

  .side_b {
    top: 14px;
  }

  .side_right .asuser .dropdown {
    top: 5px;
  }

  .listusr .singleposttest .asuser h5 {
    font-size: 16px;
  }
  .main_menu {
    padding: 18px 15px 5px;
  }

  .likeshare .comment span {
    display: none;
  }

  .allcomment .commentin .replyid .comment span {
    display: none;
  }

  .allcomment .commentin .replyid .comment span.send {
    display: block;
  }

  .allcomment .commentin .replyid button.comment {
    right: 15px;
    background: #d5d5d5;
    padding: 6px;
    height: 44px;
    border-radius: 0;
    width: 44px;
    bottom: 0;
    margin: auto;
  }

  .allcomment .commentin .replyid .comment span.send img {
    width: 20px;
  }

  .likeshare form input {
    padding: 10px 48px 10px 15px;
    box-shadow: 0 5px 30px 0 #d6d6d6;
  }

  .allcomment .commentin .replyid {
    padding-right: 180px;
    position: relative;
    margin-top: 10px;
    position: fixed;
    background: white;
    padding: 15px;
    left: 0;
    bottom: 0;
    z-index: 99;
    width: 100%;
    box-shadow: 0 0 15px #ccc;
  }

  .allcomment .commentin .replyid input {
    height: 44px;
    border-radius: 6px;
    padding: 10px 52px 10px 15px;
    font-size: 16px;
  }

  .allcomment .commentin h5 .reportbtn {
    font-size: 16px;
  }

  .listusr .test {
    margin-bottom: 22px;
  }

  .listusr .asuser .join {
    width: 50px;
    font-size: 16px;
    padding: 4px;
    border-radius: 6px;
  }

  .listusr .test {
    padding: 20px 10px 10px;
  }

  .listusr .asuser .join img {
    height: 19px;
  }

  .main_menu .side_right {
    width: 203px;
    position: relative;
  }

  .main_menu ul {
    margin-left: 40px;
    margin-top: 50px;
  }

  .main_menu ul li a {
    font-size: 13px;
  }

  .side_right .asuser .userimg {
    width: 36px;
    height: 36px;
  }

  .main_menu ul li {
    margin-right: 12px;
    float: none;
    display: inline-block;
  }

  .side_right .asuser {
    padding-left: 45px;
    height: 40px;
  }

  .side_right .asuser h5 {
    display: none;
  }

  .side_right .asuser img.vip {
    height: 14px;
    margin-left: -17px;
    position: relative;
    margin-top: -28px;
  }

  .main_menu .side_right {
    width: 40px;
    float: right;
    position: absolute;
    /* height: 35px; */
  }

  .side_b {
    left: 19px;
    top: 20px;
  }

  .side_b {
    padding: 3px;
  }

  .HelpForm .modal-body {
    padding: 15px;
  }

  .discussion .categoryhd h3 {
    margin-bottom: 15px;
  }

  ul.ul_discussion select {
    margin-left: 10px;
  }

  .listpro .list1 ul li {
    margin-right: 8px;
    font-size: 16px;
  }

  .listpro .list1 ul li img {
    height: 20px;
    margin-right: 3px;
  }

  .listpro .list1 {
    padding-left: 120px;
  }

  .listpro .list1 span.userimg {
    width: 105px;
  }

  .listpro .list1 h6 {
    font-size: 16px;
  }

  .listpro .list1 p {
    font-size: 16px;
  }

  .create_post .create_in {
    border-radius: 10px;
  }

  .listusr .asuser h5 {
    font-size: 16px;
  }

  .notification .col-sm-6,
  .favoriteclass .col-sm-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .allcomment {
    margin-top: 0;
  }

  footer.main_footer {
    padding: 14px 0;
  }

  .allcomment .commentin h5 .reportbtn {
    font-size: 16px;
  }

  .allcomment .commentin ul.likecomment li img {
    height: 16px;
  }

  .allcomment .commentin ul.likecomment li {
    margin-right: 10px;
    padding-left: 25px;
    font-size: 16px;
  }

  .addhead textarea {
    font-size: 16px;
    padding: 10px 15px;
  }

  .dashboard .user.usernew .uphead .userimg {
    top: -145px;
  }
  .dashboard .user.usernew .uphead .report_btni {
    left: 0;
    top: -53px;
  }
  .Postall .singleposttest {
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    background: #fff;
    display: block;
    position: relative;
  }
  .addpost .addhead span.userimg {
    height: 50px;
    width: 50px;
    top: 0;
    right: 0;
    margin: auto;
  }
  .heading-line {
    font-size: 21px;
  }
  .likecomment {
    margin: 0px 10px;
  }
  .dashboard .user.usernew .uphead ul li {
    padding: 8px 6px;
    width: 31.33%;
    margin: 6px 1%;
    min-width: 31.33%;
  }

  .dashboard .user.usernew .uphead ul {
    display: block;
    margin-top: 32px;
  }

  .addpost .addhead span.userimg {
    height: 50px;
    width: 50px;
    top: 0;
    right: 0;
    margin: auto;
  }

  .allcomment .commentin h5 .reportbtn {
    font-size: 12px;
    padding: 3px 4px;
  }

  .listusr .asuser .dropdown {
    top: 7px;
  }

  .in_center .head {
    padding-right: 50px;
  }

  .in_center .head a.hpl span {
    display: none;
  }

  .in_center .head a.hpl {
    padding: 0;
    font-size: 16px;
    min-width: 42px;
    height: 42px;
    text-align: center;
    line-height: 42px;
  }

  .in_center .head a.hpl img {
    width: 24px;
  }

  .addpost .addhead ul li {
    margin-right: 15px;
  }
}
.addmore {
  background: #fff;
  border: navajowhite;
  padding: 0px 8px;
  margin-top: 40px;
  font-weight: 400;
  border-radius: 5px;
  height: 40px;
  font-size: 16px;
}

/* .zoom-image-container {
   
    overflow: hidden;
  } */

.zoom-image {
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease;
}

.zoom-image.zoomed {
  transform: scale(1.5); /* Adjust the scale factor for zoom level */
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -1px;
}
.dropdown-menu {
  position: relative;
}

/* Styles for the submenus */
.testul {
  display: none;
  position: absolute;
  top: 0;
  left: 100%; /* Adjust this value based on your layout */
}

/* Additional styles for better visibility, adjust as needed */
.testul li {
  white-space: nowrap;
}

.dropdown-menu.show ul {
  direction: rtl;
  overflow: auto;
  height: auto;
  width: 267px;
  min-height: 150px;
  max-height: 150px;
}
.Postall .col-sm-6.col-lg-6.mb-3 {
  display: table;
}
.Postall .singleposttest {
  display: table-cell !important;
}
.viewprofile .help .singleposttest .contants {
  flex-direction: column;
  margin-bottom: 60px;
}
@media only screen and (min-width: 1201px) and (max-width: 1400px) {
  .allctg .text img {
    height: 48px;
  }
}

.viewprofile .singleposttest .contants {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
.viewprofile .Postall .singleposttest .contants p {
  min-height: auto;
  font-size: 16px;
  max-height: none;
  overflow: visible;
  margin: 0;
}
.viewprofile .singleposttest .contants a {
  background: #f0f0f0;
  display: block;
  text-align: center;
  color: #1a1919;
  padding: 8px 10px;
  margin: 0;
  font-weight: 600;
  text-decoration: none;
  border-radius: 0;
  font-size: 12px;
}
.viewprofile .main {
  display: flex;
  justify-content: space-between;
  gap: 11px;
}
.viewprofile .listusr .singleposttest .asuser h5 {
  font-size: 15px;
  margin-bottom: 2px;
}
.viewprofile .listusr .singleposttest .asuser p {
  font-size: 15px;
  padding: 0;
}
.viewprofile .main-btn a.d_report {
  background: #016afa;
  color: #fff !important;
  font-size: 14px;
  padding: 4px 16px;
  border-radius: 5px;
}
.viewprofile .Postall .singleposttest {
  display: block;
}
.viewprofile .Postall .singleposttest .contants p {
  font-size: 14px;
  max-height: none;
  margin: 0;
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0;
}
.viewprofile .addfrbfil .testfrnd .userimg {
  width: 130px;
  position: absolute;
  left: 12px;
  height: 100%;
  overflow: hidden;
  border-radius: 12px;
  top: 11px;
}

.viewprofile .addfrbfil .testfrnd ul.followmessage li {
  width: 100%;
  float: left;
}
.viewprofile .followmessage a.mg {
  width: 100%;
}
.viewprofile .addfrbfil .testfrnd {
  min-height: 100px;
}
.usersec .col-sm-6.col-lg-4.mb-2 {
  display: table;
}
.userprofile .listusr .singleposttest .asuser h5 {
  font-size: 15px;
  margin-bottom: 2px;
}
.userprofile .listusr .singleposttest .asuser p {
  font-size: 15px;
  padding: 0;
}
.Postall .singleposttest p {
  padding-left: 10px;
  margin: 0;
}
.userprofile .Postall .singleposttest .contants p {
  font-size: 14px;
  max-height: none;
  margin: 0;
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px 0 0;
}
.userprofile .addfrbfil .testfrnd .userimg {
  width: 130px;
  position: absolute;
  left: 12px;
  height: 100%;
  overflow: hidden;
  border-radius: 12px;
  top: 11px;
}
.addfrbfil .Followers {
  min-height: 111px;
}
.addfrbfil .Following {
  min-height: 111px;
}
.my_followers .test .userimg {
  width: 130px;
  position: absolute;
  left: 12px;
  height: 100%;
  overflow: hidden;
  border-radius: 12px;
  top: 11px;
}
.my_followers .test .userimg img {
  width: 100%;
  height: auto;
  border-radius: 50%;
  max-width: 100px;
  max-width: 70px;
}

/* ============= linkedin activity sec css Start ================*/

.linkedin-activity-tab ul.nav.nav-tabs {
  gap: 15px;
  padding: 0 0 10px;
}
.linkedin-activity-tab ul.nav.nav-tabs a.nav-link {
  border-radius: 40px;
  padding: 8px 20px;
  min-width: 90px;
  text-align: center;
  line-height: normal;
  background: #fff;
  color: #000;
  font-size: 15px;
  border: none;
}
.linkedin-activity-tab ul.nav.nav-tabs a.nav-link.active {
  border: none;
  background: #0678f3;
  color: #fff;
}
.tab-news {
  overflow: auto;
}
.linkdin-post-inner {
  background: #fff;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 20px;
}
.posted-time span {
  font-weight: 700;
  padding-right: 10px;
  color: #000;
}
.posted-time p {
  color: #444;
  font-size: 14px;
  margin: 0;
}
.posted-name-contant {
  display: flex;
  gap: 20px;
}
.linkdin-post-img img {
  max-width: 90px;
}
.linkd-content-inner p span {
  font-weight: 700;
  padding-right: 10px;
  color: #000;
  word-break: break-all;
}
.linkd-content-inner p {
  color: #444;
  line-height: 20px;
  font-size: 14px;
  margin: 0;
}
.posted-time {
  border-bottom: 1px solid #eee;
  padding: 0 0 5px;
  margin-bottom: 15px;
}
.see-more {
  text-align: right;
}
.see-more a {
  font-size: 14px;
  font-weight: 600;
}
.posted-video-info {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.posted-video-inner {
  width: calc(25% - 15px);
  display: flex;
}
.posted-video-inner iframe,
.posted-video-inner video {
  width: 100%;
  height: auto;
  background: #ccc;
}
.posted-video-inner img {
  max-width: 100%;
  width: 100%;
}
.dash_topmain .dash_sidebar ul.nav-tabs::-webkit-scrollbar {
  width: 6px;
}
.dash_topmain .dash_sidebar ul.nav-tabs::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.dash_topmain .dash_sidebar ul.nav-tabs::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
.dash_top button.btn.btn-success {
  background: #366cdc !important;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 6px;
  outline: transparent !important;
  box-shadow: none !important;
}
ul.nav.nav-tabs.viewprofile {
  border: none;
  height: auto;
  overflow: auto;
}
.in_center.in_centeredit {
  min-height: auto;
}

/* ============= linkedin activity sec css End ================*/

@media (max-width: 1200px) {
  .posted-video-inner {
    width: calc(33% - 12px);
  }
}

@media (max-width: 767px) {
  .sarech-filed.in_center {
    padding-bottom: 0 !important;
  }
  .testimg {
    width: 260px;
  }
  .send-paper-upload {
    padding: 0 5px;
    font-size: 12px;
  }
  .mb-3,
  .my-3 {
    margin-bottom: 1rem !important;
  }
  .linkedin-activity-tab .tab-content {
    margin-top: 20px;
  }
  .posted-video-info {
    gap: 15px;
  }
  .posted-video-inner {
    width: calc(50% - 8px);
  }
  .linkedin-activity-tab ul.nav.nav-tabs {
    width: 600px;
  }
  .linkedin-activity-tab ul.nav.nav-tabs li.nav-item {
    width: auto;
  }
  .linkedin-activity-tab {
    padding-top: 15px;
  }
}

.vip2 {
  height: 20px;
  width: 30px;
  object-fit: contain;
  left: 49px !important;
  bottom: 30px !important;
  position: absolute;
}

.verfiy {
  width: 20px;
  position: absolute;
  bottom: 29px;
  left: 42px;
}

.verfiy2 {
  width: 20px;
  position: absolute;
  bottom: 65px;
  left: 35px;
}
.verfiy3 {
  width: 20px;
  position: absolute;
  top: 30px;
  left: 30px;
}
.verfiymsg {
  width: 20px;
  position: absolute;
  bottom: 28px;
  left: 40px;
}
.verfiy5 {
  width: 30px;
  position: absolute;
  bottom: 95px;
  left: 31px;
}

.story-section {
  display: flex;
  overflow-x: scroll;
  padding: 6px;
  background-color: white;
  border-radius: 10px;
  margin-bottom: 15px;
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); */
}

.create-story,
.story {
  width: 100px;
  height: 170px;
  margin-right: 10px;
  position: relative;
  border-radius: 10px;
  background-color: #f0f0f0;
  overflow: hidden;
  cursor: pointer;
  text-align: center;
}

.create-story {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #635dff;
  color: white;
  height: 100%;
  border-radius: 7px;
}
.create-story img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border: 1px solid #eee;
}

/* .create-story-icon {
    font-size: 40px;
    margin-bottom: 10px;
  }
  
  .create-story p {
    font-weight:600;
    font-size: 15px;
  } */

.story img {
  width: 100%;
  height: 130px;
  object-fit: cover;
  border-radius: 10px;
}

.story p {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 2px 5px;
  border-radius: 5px;
}

/* .send-paper {
    position: absolute;
    right: 0;
    top: 3px;
    z-index: 9999   ;
}

.send-paper span {
    background: transparent;
    border: 0;
    color: #016afa;
    font-size: 19px;
} */

.send-paper-upload {
  border: 0;
  position: absolute;
  right: 0;
  top: -1px;
  background: #016afa;
  color: #fff;
  height: 38px;
  padding: 0px 18px;
}

.popup-menu {
  position: absolute;
  background-color: white;
  border: 1px solid #efefef;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 1000;
  left: auto;
  right: 20px;
  border-radius: 8px;
  top: 40px;
}

.popup-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.popup-menu ul li {
  padding: 8px 16px;
  cursor: pointer;
}

.popup-menu ul li:hover {
  background-color: #f0f0f0;
}
.create-post-popup button {
  border: 0;
  font-size: 12px;
  padding: 2px 6px;
  border-radius: 4px;
}

.create-post-popup h4 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
}
.create-post-popup .profile-info {
  width: 48px;
  height: 48px;
  color: rgb(250, 250, 250);
  font-size: 16px;
  background-color: rgb(89, 83, 172);
  border-radius: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border: 1px solid #ffffff;
  margin-right: 10px;
}

.text-area-part {
  position: relative;
}
.text-area-part textarea {
  border: 0;
  background-color: transparent;
}

.text-area-part a {
  position: absolute;
  right: 0;
  top: 5px;
}
.footer-post ul {
  padding: 10px;
  list-style: none;
  display: flex;
  gap: 10px;
  margin-bottom: 0;
}

.footer-post li a {
  text-decoration: none;
}

.footer-post ul li i {
  font-size: 12px;
  width: 30px;
  text-align: center;
  background: #4f31f7;
  border-radius: 50px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-decoration: none;
}
.footer-post ul li i:hover {
  background-color: #4f31f724;
  color: #4f31f7;
}

.footer-post button {
  border: 0;
  width: 90px;
  height: 35px;
  background: #4f31f7;
  color: #fff;
  border-radius: 4px;
  font-weight: 600;
}
.post-body {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}
.main-wapper-popup {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px,
    rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
  background-color: rgba(255, 255, 255, 0.77);
  border-radius: 8px;
  transition: max-height 1s ease-in-out;
}

.custom-drop .dropdown-menu.show {
  background-color: rgba(255, 255, 255, 0.77);

  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 8px;
  padding: 8px 0px;
  margin: 4px 0px;
  border: 0;
}

.custom-drop .dropdown-menu.show li a {
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  font-size: 0.9375rem;
  line-height: 1.33;
  text-decoration: none;
  min-height: 48px;
  padding: 6px 16px;
  box-sizing: border-box;
  white-space: nowrap;
  color: rgb(5, 5, 5);
  font-size: 13px;
}

input.addimagepost {
  position: absolute;
  opacity: 0;
  width: 100%;
  left: 0;
  height: 100%;
}

.popupli li {
  float: left;
  margin-right: 26px;
  position: relative;
}
.img-section {
  height: 450px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.img-section img {
  max-width: 100%;
  height: auto;
  display: block;
}

.private-policy {
  padding: 20px !important;
  box-shadow: 0 0 20px 0 #eaeff4;
  background: #fff;
  border-radius: 20px;
}
.private-policy h1 {
  font-size: 32px;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 25px;
}
.private-policy p {
  font-size: 16px;
  line-height: 29px;
  margin-bottom: 24px;
}

.private-policy a {
  font-size: 16px;
  color: #016afa;
  line-height: 29px;
}

.chat-list {
  overflow-y: scroll;
  height: 400px;
}

/* userprofile */
.dash_sidebar.tabsection-rel {
  position: relative;
  width: 100%;
  display: flex;
  margin-top: 20px;
  padding: 10px;
}

ul.nav.nav-tabs.tabs-line {
  display: flex;
  height: unset;
  flex-wrap: nowrap;
  white-space: pre;
  /* height: 6px; */
  /* overflow-y: scroll; */
  scrollbar-color: #ffffff36 #ffffff36;
  overflow: unset;
}

.dash_topmain .dash_sidebar ul li {
  float: unset !important;
  width: unset !important;
}

.dash_topmain .dash_sidebar ul li a {
  border-radius: 25px !important;
  padding: 10px 20px !important;
}

.theme-colordiv .user2 {
  width: 100%;
}

.theme-colordiv {
  width: 200px;
  float: right;
}

.theme-colordiv .socialicon span {
  width: 30px;
  height: 30px;
}

.theme-colordiv .socialicon span a {
  line-height: 30px;
}

.theme-colordiv ul {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0;
}

.theme-colordiv .socialicon {
  width: 100%;
  text-align: right;
}

.theme-colordiv .user2 h3 {
  margin-top: 20px;
  text-align: right;
}

/* .drop-more ul {
    background: #00000094;
    border: 1px solid #ffffff2e;
    border-radius: 14px!important;
    max-width: -webkit-max-content;
    max-width: max-content;
    min-width: 308px;
    padding: 8px 4px;
    position: absolute;
    right: 0;
    z-index: 99999999;
} */

.tab-content.userprofile {
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 3px 7px 0 #00000061;
}

.listusr.help.Postall li a {
  color: #fff;
}

.listusr.help.Postall .nav-link.active {
  color: #0175e2;
}

@media (max-width: 760px) {
  .drop-more ul {
    left: -240px !important;
  }
}

@media (max-width: 520px) {
  .drop-more ul {
    left: -290px;
  }
  .dash_topmain .dash_sidebar ul li a {
    padding: 10px 10px !important;
  }
}
.private-policy {
  padding: 50px 0px;
}
.private-policy h1 {
  font-size: 32px;
  font-weight: 700;
  margin-top: 0px;
}
.private-policy p {
  font-size: 16px;
  line-height: 29px;
  margin-bottom: 24px;
}
.dash_sidebar.tabsection-rel {
  position: relative;
  width: 100%;
  display: flex;
  margin-top: 20px;
  padding: 10px;
}

ul.nav.nav-tabs.tabs-line {
  display: flex;
  height: unset;
  flex-wrap: nowrap;
  white-space: pre;
  /* height: 6px; */
  /* overflow-y: scroll; */
  scrollbar-color: #ffffff36 #ffffff36;
  overflow: unset;
}

.dash_topmain .dash_sidebar ul li {
  float: unset !important;
  width: unset !important;
}

.dash_topmain .dash_sidebar ul li a {
  border-radius: 25px !important;
  padding: 10px 20px !important;
}

.theme-colordiv .user2 {
  width: 100%;
}

.theme-colordiv {
  width: 200px;
  float: right;
}

.theme-colordiv .socialicon span {
  width: 30px;
  height: 30px;
}

.theme-colordiv .socialicon span a {
  line-height: 30px;
}

.theme-colordiv ul {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0;
}

.theme-colordiv .socialicon {
  width: 100%;
  text-align: right;
}

.theme-colordiv .user2 h3 {
  margin-top: 20px;
  text-align: right;
}

.drop-more ul {
  padding: 8px 4px;
  max-width: max-content;
  background: #000000d1;
  border: 1px solid #ffffff2e;
  border-radius: 14px !important;
  z-index: 99999999;
  position: absolute;
  right: 0;
  min-width: 308px;
}

.tab-content.userprofile {
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 3px 7px 0 #00000061;
}

.listusr.help.Postall li a {
  color: #fff;
}

.listusr.help.Postall .nav-link.active {
  color: #0175e2;
}

.overdiv .userimg {
  width: 80px !important;
  height: 80px !important;
}

.overdiv .camerai {
  width: 30px !important;
  right: 0px;
  position: relative;
  top: 6px;
}

.overdiv .verfiy5 {
  width: 30px;
  bottom: 58px;
  left: 16px;
}

.user.pro_user.overdiv {
  margin-left: 45px;
  padding-left: 65px;
  margin-top: -90px;
}

.mean-pro {
  left: -40px !important;
}

.report_btni.report_btni2 {
  left: -120px !important;
}
.report_btni.report_btni23 {
  text-align: left !important ;
}

.report_btni2 img {
  height: 30px !important;
}

.dashboard .user.usernew .uphead ul li {
  padding: 5px 8px;
  min-width: 110px;
}

.socialicon span {
  width: 30px !important;
  height: 30px !important;
}

.socialicon span a {
  line-height: 32px;
}

.profile-title .userimg {
  width: 80px !important;
  height: 80px !important;
}

.dashboard .user.usernew .uphead .userimg {
  z-index: 9;
}

.main-row {
  align-items: end;
}
.profile-title-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
}

.help2 {
  background: #ff0000;
  color: #fff !important;
  padding: 9px 40px;
  border-radius: 30px;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: 600;
}

.help2 span {
  position: absolute;
  margin-top: -50px;
}

.viewprofile-tabs {
  border-radius: 15px;
  box-shadow: 0 3px 7px 0 #00000061;
  padding: 15px;
}

.btn-popup {
  border: 0;
  font-size: 16px;
  background: #366cdc;
  padding: 6px 11px;
  margin-left: 10px;
  color: #fff;
  border-radius: 6px;
}
.inbanner {
  max-width: 1140px;
  margin: 0 auto;
}
.blurdiv {
  width: 100%;
  height: 400px;
  position: absolute;
  filter: blur(60px);
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  top: 0;
}
.testfrnd.Followers img {
  max-width: 50px !important;
  height: 50px !important;
  object-fit: contain;
}

.testfrnd.Following img {
  max-width: 50px !important;
  height: 50px !important;
  object-fit: contain;
}

@media (max-width: 767px) {
  .user.usernew.pl-0.ml-0.profile-title-right {
    margin-top: 50px;
  }
  .main-rel {
    position: relative;
  }
  .overdiv .verfiy5 {
    position: absolute;
    top: 50%;
    left: 60%;
    transform: translate(-50%, -180%);
  }
  .user.pro_user.overdiv {
    margin-left: 0;
    padding-left: 0;
    margin-top: -90px;
    padding-right: 0;
  }
  .overdiv .camerai {
    width: 30px !important;
    position: relative;
    top: 0px;
  }

  .dashboard .user .userimg {
    left: 0 !important;
  }
  .report_btni.report_btni2 {
    left: 0 !important;
  }
  .report_btni2 img {
    margin-left: 0 !important;
  }
}

@media (max-width: 760px) {
  .drop-more ul {
    left: -240px;
  }
  .userimg.newset {
    top: -240px !important;
  }
  .help-part {
    flex-wrap: wrap;
  }
}

@media (max-width: 550px) {
  .join.p-2 {
    width: max-content !important;
    margin-bottom: 20px;
  }
  .row-join {
    display: block !important;
  }
  .masseage-area .secondright .inputarea {
    width: 72% !important;
    margin-left: auto;
  }
  .chat .chat-history {
    max-height: 65vh !important;
    min-height: 65vh !important;
  }
  .masseage-area {
    margin: 5px !important;
    padding: 5px !important;
  }
  .secondright {
    gap: 0px !important;
  }
  .masseage-area ul input {
    width: 75% !important;
    margin-left: auto !important;
    margin-right: 0 !important;
  }
  .report_btni.report_btni23 {
    top: 15px !important;
    text-align: center !important;
  }
  .report_btni.report_btni23 img {
    width: 30px !important;
    height: 20px !important;
    object-fit: contain;
  }
  .allcomment .commentin.commentset {
    padding: 10px 0px 10px 50px;
  }
  .showposts li {
    margin-top: 8px !important;
    font-size: 15px;
  }

  .listusr .asuser h5 {
    word-break: break-all;
  }
  .Discussion-start {
    background: #016afb;
    border-radius: 50px !important;
    padding: 0px !important;
    min-width: unset !important;
    width: 40px !important;
    height: 40px !important;
  }

  .Discussion-start img {
    width: 22px;
    line-height: 25px;
    margin-right: 0 !important;
    margin-top: 5px !important;
  }
  .verfiy5 {
    width: 20px;
    bottom: 115px;
    left: 51px;
  }
  span.userimg.new-user-yo {
    top: -18px !important;
    left: 20px !important;
  }
  .phone-icon i {
    color: #016afa;
    font-size: 20px;
    font-weight: 600;
  }
  .phone-icon {
    background: #ffffff;
    border-radius: 8px;
    font-weight: 600;
    min-width: 35px;
    text-align: center;
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: 35px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  .hidepgo {
    display: none;
  }
  .phone-icon {
    display: block;
  }
  .admin-right .side_right {
    max-width: 185px;
    padding-top: 0;
    align-items: center;
    padding-bottom: 0;
  }
  .overdiv .verfiy5 {
    top: 50%;
    left: 50%;
    transform: translate(60%, -220%);
  }
  .userimg.mean-pro {
    top: -120px !important;
  }
  .viewprofile-tabs {
    margin-top: 20px;
  }
  .profile-title-right ul {
    justify-content: center;
    display: flex;
  }
  .profile-title-right {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .viewprofile-tabs {
    margin-top: 20px !important;
  }
}

@media (max-width: 520px) {
  .drop-more ul {
    left: -290px;
  }
  .dash_topmain .dash_sidebar ul li a {
    padding: 6px 6px !important;
    font-size: 15px;
  }
  .dash_sidebar.tabsection-rel {
    margin-bottom: 20px;
    padding: 0;
  }
  .theme-colordiv {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .theme-colordiv .user2 h3 {
    margin-top: 20px;
    text-align: left;
  }
  .dashboard .user2 ul li span {
    height: 30px;
    width: 30px;
    margin-right: 0px;
  }
  .dashboard .user2 ul {
    display: flex;
  }
}

.shorts-section {
  margin: 0 auto;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.header-shorts h6 {
  font-weight: 600;
}
.comments-section img {
  border-radius: 50%;
}

.comments-right-shorts span {
  font-weight: 600;
  font-size: 15px;
  color: #000;
}
.comments-right-shorts a {
  font-weight: 400 !important;
  font-size: 11px;
  color: #757171;
  text-decoration: none;
}
.comments-right-shorts p {
  font-size: 12px;
  color: #534f4f;
  margin-bottom: 8px;
}
.comments-right-shorts ul li i {
  color: #444141;
}
.comments-right-shorts ul {
  display: flex;
  gap: 20px;
  margin-bottom: 5px;
}
.comments-right-shorts ul span {
  font-size: 12px;
}
.comments-right-shorts ul li {
  list-style: none;
  display: flex;
  gap: 12px;
  align-items: center;
}
.comments-right-shorts ul li :hover {
  color: #5a2ff9;
  cursor: pointer;
}
.comments-section {
  padding-bottom: 20px !important;
}
.comments-section button {
  background-color: transparent;
  font-size: 12px;
  color: #5a2ff9;
  font-weight: 600;
  text-align: left;
  padding: 4px 10px;
  border-radius: 50px;
  margin-top: 8px;
}
.comments-section button:hover {
  background: #5a2ff929;
  color: #5a2ff9;
}
.shorts-wapper {
  display: flex;
  justify-content: space-between;
}
.side-content {
  padding-left: 10px;
}
.second-comments-button {
  display: none;
}
#replySection img {
  width: 40px !important;
  height: 40px !important;
}
#replySection {
  padding-left: 30px !important;
  padding-top: 0 !important;
}
.reports-section a {
  color: #000;
}

/* Notification popup */
/* Custom Scrollbar Styling */
.box::-webkit-scrollbar-track {
  -webkit-box-shadow: unset !important;
  background-color: #fff;
  border-radius: 5px;
}

.box::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
  border-radius: 5px;
}

.box::-webkit-scrollbar-thumb {
  background-color: #fff;
  border: 0px solid #fff;
  border-radius: 5px;
}

.icons {
  display: inline;
  float: right;
}

.number {
  height: 16px;
  width: 16px;
  background-color: #62e1fb;
  border-radius: 20px;
  color: white;
  text-align: center;
  position: absolute;
  top: 16px;
  left: 62px;
  padding: 2px;
  font-size: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.number:empty {
  display: none;
}

.notBtn .fas {
  font-size: 30px;
  color: #fff;
  margin-right: 40px;
  margin-left: 40px;
}

/* Notification Dropdown Box */
.box {
  width: 400px;
  height: 0px;
  border-radius: 10px;
  transition: 0.5s;
  position: absolute;
  overflow-y: scroll;
  padding: 0;
  left: -300px;
  margin-top: 5px;
  background-color: #f4f4f4;
  cursor: context-menu;
}

.notBtn:hover > .box {
  height: 60vh;
}

/* Notification Content */
.content {
  padding: 20px;
  color: black;
  vertical-align: middle;
  text-align: left;
}

.display {
  position: relative;
}

.cont {
  /* position: absolute;
        top: 0; */
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;
  position: relative;
}

.cont:empty {
  display: none;
}

.stick {
  text-align: center;
  display: block;
  font-size: 50pt;
  padding-top: 70px;
  padding-left: 80px;
}

.stick:hover {
  color: black;
}

.cent {
  text-align: center;
  display: block;
}

.sec {
  padding: 5px;
  background-color: #f4f4f4;
  transition: 0.5s;
}

.profCont {
  padding-left: 15px;
}

.profile {
  width: 50px;
  float: left;
  margin-right: 15px;
  height: 50px;
  object-fit: cover;
  border-radius: 50px;
  border: 1px solid #eee;
}

.txt {
  font-size: 13px;
  padding: 5px 10px 0 25px;
  color: #000;
  font-weight: 600;
}

.sub {
  font-size: 10px;
  color: grey;
}

.new {
  border: 1px solid #eee;
}

.new a {
  text-decoration: none;
}

.notification {
  position: relative;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .row-ver {
    position: relative;
    width: 130px;
    margin: 0 auto;
    height: 30px;
  }
  .verfiy5 {
    bottom: 0;
    right: 0;
  }
  .box {
    width: 100%;
    left: 0;
  }
  .notBtn:hover > .box {
    height: 60vh;
    width: 290px;
    left: -160px;
  }

  .notification {
    width: 100%;
    float: none;
  }

  /* nav phone */
  .logo-site {
    display: none;
  }
  .Mainheader-wapper.mobile-wapper .hpl span {
    display: none;
  }
  .Mainheader-wapper.mobile-wapper .in_center .head a.hpl {
    min-width: unset;
    padding: 0px;
    height: 40px;
    width: 40px !important;
    line-height: 40px;
  }

  .Mainheader-wapper.mobile-wapper .in_center .head a.hpl img {
    width: 20px;
    margin: 0;
  }

  .Mainheader-wapper.mobile-wapper .in_center .head {
    padding-left: 00px;
    padding-right: 55px;
    width: min-content;
    float: right;
  }
  i.fas.fa-bars.side_b {
    font-size: 26px;
    border: 0;
    padding: 0px;
    color: #fff !important;
    margin-bottom: 0;
  }

  /* popupli */
  .popupli li {
    margin-right: 15px;
  }
  .footer-post ul {
    gap: 0;
    padding: 0;
  }
}

/* messages */

@media (max-width: 550px) {
  .vip2 {
    bottom: 11px !important;
    left: 54px !important;
    position: absolute;
    width: 35px;
    object-fit: contain;
  }
  .search-box {
    position: absolute;
    width: 100%;
    z-index: 9;
    background: #fff;
    padding: 10px;
    top: 70px;
    left: 0;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    max-width: 100%;
  }
  .in_center .head a.hpl {
    line-height: 30px !important;
  }
  .addinfo-new {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    align-items: center;
  }
  .maindivkap aside {
    width: 270px !important;
  }
}

@media (max-width: 350px) {
  .main-wapper-popup {
    max-width: 310px;
  }

  .popupli li {
    margin-right: 5px;
  }
  .drop-more ul {
    min-width: 278px;
  }
}

.chat-about small {
  color: #542f2f;
}
.overarea-sreach {
  position: relative;
}
.overarea-sreach input {
  border-radius: 6px !important;
  width: 100%;
}

.overarea-sreach i {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #016afa;
  z-index: 999;
}
.freind-list-ap h3 {
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 18px;
}
.list-icon-top {
  display: none;
}
.chatbox-line input {
  border: 0;
  box-shadow: unset !important;
  height: unset;
}
.freind-list-ap {
  position: relative;
}

.freind-list-ap .list-icon-top {
  width: 100%;
}

.freind-list-ap .closeicon-button {
  position: absolute;
  right: 0;
  top: 5px;
  color: #fff;
  font-size: 20px;
  border: 0;
  background-color: transparent !important;
  margin-bottom: 10px;
  display: none;
}

.people-list .chat-list img {
  width: 40px !important;
}
.people-list .about {
  width: 150px;
}
.people-list .status {
  display: flex;
  justify-content: space-between;
  min-width: 180px;
  width: 100%;
}
.people-list .chat-list li:hover {
  background: #d1d1d130;
  cursor: pointer;
}

.people-list .chat-list {
  height: 100%;
  overflow-x: hidden;
}

.chat-list {
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: #016afa #016afa;
}
.people-list .chat-list li .name {
  font-size: 14px;
  color: #fff;
}
.people-list .status {
  color: #f2f2f2;
  font-size: 13px;
}
.UserList {
  width: 150px;
}

.posted-video-inner {
  border: 1px solid #eee;
  border-radius: 8px;
  overflow: hidden;
  padding: 4px;
  box-shadow: 0 3px 4px 0 #00000021;
  height: 190px;
  width: 190px;
  transition: transform 0.8s;
}
.posted-video-inner:hover {
  -ms-transform: scale(1.1); /* IE 9 */
  -webkit-transform: scale(1.1); /* Safari 3-8 */
  transform: scale(1.1);
}

.posted-video-info {
  gap: 15px;
}

.posted-video-inner img {
  height: 100%;
}
.card.chat-app {
  margin-bottom: 0;
  height: 100%;
  border-radius: 6px !important;
  overflow: hidden;
}

.chat .chat-history {
  max-height: 70vh;
  min-height: 70vh;
}

.chat-app .people-list {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  height: 100%;
  background-color: #016afa;
  padding: 11px !important;
}
.btn-set {
  font-size: 14px;
  color: #016afa;
  margin: 0px 5px;
  cursor: pointer;
}

.deletenotification {
  position: absolute;
  right: -30px;
  top: 20px;
  font-size: 14px !important;
  color: red !important;
  z-index: 9999;
}

.allcomment .commentin.commentset {
  padding: 10px 0px 10px 80px;
  position: relative;
}
.allcomment .commentin.commentset ul.likecomment {
  margin-top: 5px;
}

.commentin.commentset:hover {
  background: #eee8e840;
}
.dropdown-menu.show ul {
  direction: rtl;
  overflow: auto;
  height: auto;
  width: 267px;
  min-height: auto;
  max-height: 400px;
}
.maindiv.main .sidbar_left {
  display: block;
  z-index: 99;
}
.Mainheader-wapper {
  z-index: 999;
}

.pagination {
  width: 100%;
  display: flex;
  gap: 8px;
  overflow-x: scroll;
  padding-bottom: 10px;
  justify-content: flex-end;
}
.story-sec {
  position: relative;
}
.inner-story {
  margin-right: 10px;
  position: relative;
}
.story-text {
  position: absolute;
  bottom: 10px;
  left: 44px;
  transform: translate(-50%, 0);
}
.story-section p {
  margin-bottom: 0;
  color: #fff;
  word-break: break-all;
  line-height: 16px;
  font-size: 15px;
  text-align: left;
  font-weight: 700;
  text-shadow: 1px 1px 2px black;
  text-transform: capitalize;
}

.modal-story p {
  position: inherit;
  transform: none;
}
.top-img {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 3px solid #fff;
  top: 5px;
  left: 5px;
}

.message-data .message-data-time {
  font-size: 10px !important;
  margin-right: 10px;
}
.message.other-message.float-right {
  padding: 10px !important;
}
.message.other-message.float-right p {
  font-size: 18px;
}

.Ticket-header2 .dropdown-item {
  padding: 10px 15px !important;
  display: block;
  color: #626262;
  font-weight: 400;
  font-size: 16px;
}

.masseage-area {
  margin: 10px;
  background: #efefef;
  padding: 10px;
  border-radius: 50px;
  justify-content: space-between;
}
.masseage-area ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.masseage-area ul input {
  background-color: #ddd;
  border: 1px solid #eee;
  border-radius: 50px;
  font-size: 12px;
  height: 32px;
  margin: 0 5px;
  padding: 5px 25px 5px 10px;
  width: 100%;
}

.firstleft {
  width: 20%;
}

.secondright {
  justify-content: space-between;
}

.secondright2 {
  width: 100%;
}
.secondright .inputarea {
  width: 100% !important;
}
.masseage-area ul {
  gap: 10px;
}
.masseage-area li {
  list-style: none;
  width: 28px;
  text-align: center;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .masseage-area  textarea{
  
        height: auto;
    } */
.masseage-area li i {
  color: #016afa;
  font-size: 15px;
  line-height: 27px;
}

.masseage-area li:hover {
  background-color: #016afa;

  width: 28px;
}

.masseage-area li:hover i {
  color: #fff !important;
}

.masseage-area li:hover button {
  background-color: transparent !important;
}

.masseage-area .fa-solid.fa-plus {
  color: #fff;
  background-color: #016afa;
  border-radius: 50px;
  text-align: center;
  margin: 0 auto;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.masseage-area .fa-solid.fa-plus:hover {
  color: #fff !important;
}
.inputarea {
  width: unset !important;
  position: relative;
}
.inputarea textarea {
  height: auto !important;
  background: #d8d8d8;
  border-radius: 10px;
  font-size: 12px;
  height: 100% !important;
}
.inputarea:hover {
  background-color: unset !important;
}

.inputarea i {
  position: absolute;
  right: 10px;
  top: 3px;
}
.masseage-area {
  margin: 10px;
}

.secondright button {
  padding: 0;
  border: 0;
}

.main-contant img {
  width: 80px !important;
  object-fit: contain;
}

.main-contant p {
  max-height: unset !important;
  min-height: unset !important;
  font-size: 20px !important;
  font-weight: 700;
  text-transform: capitalize;
}

.main-contant a {
  font-weight: 700;
  font-size: 16px;
  color: #016afa;
}

.main-contant a:hover {
  color: #016afa !important;
  background-color: transparent !important;
}

.help-part {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  align-items: end;
}

.help-part .userimg {
  background: #fff;
  border: 50px;
  padding: 7px;
  border: 1px solid #ddd;
}

.newsarea p {
  font-size: 15px !important;
  margin-bottom: 5px !important;
}

.story-hight {
  border: 1px solid #eee;
  border-radius: 8px;
  overflow: hidden;
  width: 110px;
  text-align: center;
  margin-right: 6px;
}

.story-hight img {
  width: 100%;
  height: 135px;
  object-fit: cover;
}
.story-add {
  position: relative;
  display: flex;
  height: 80px;
  flex-direction: column;
  justify-content: end;
  align-items: center;
}
.story-add h3 {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 10px;
}
.story-add button {
  position: absolute;
  background: #016afa;
  font-size: 18px;
  color: #fff;
  border-radius: 50px;
  width: 40px;
  height: 40px;
  border: 5px solid #fff;
  top: -20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.inner-story {
  margin-right: 6px;
  position: relative;
  border: 1px solid #eee;
  border-radius: 6px;
  overflow: hidden;
  width: 110px;
}

.title-report {
  padding: 12px;
  text-align: center;
  border-bottom: 1px solid #eee;
  margin-bottom: 0;
  position: relative;
}

.title-report h4 {
  font-size: 20px;
  color: #000;
  font-weight: 700;
}

.report-section ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.report-section ul li {
  padding: 15px 18px;
  border-bottom: 1px solid #eee;
}
.report-section ul li a {
  color: #333;
  font-size: 18px;
  text-decoration: none;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.testimg {
  width: 100%;
  max-width: 350px;
}
.row-join .dropdown {
  position: unset !important;
  right: 0;
}
.row-join .join {
  position: unset !important;
  right: 0;
}

.row-join {
  align-items: end;
  justify-content: space-between;
}
.listusr .test {
  position: relative;
}
a.reportbtn.reportbtnflag {
  position: absolute;
  right: 48px;
  z-index: 99;
}
.report-section h3 {
  color: #333;
  font-size: 18px;
  text-decoration: none;
  font-weight: 700;
}

.report-section ul li i {
  color: #000;
  font-size: 22px;
  transform: rotate(270deg);
}
.content-report {
  padding: 10px 18px;
  border-bottom: 1px solid #eee;
}
.content-report h4 {
  font-size: 20px;
  color: #000;
  font-weight: 700;
}

.content-report p {
  font-size: 15px;
  color: #818181;
  margin-bottom: 0;
}
.report-section {
  align-items: flex-end;
  display: flex;
  width: 100%;
  justify-content: center;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  overflow: hidden;
  margin: 0 auto;
}

.inner-report {
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  background-color: #fff;
  /* border-top-left-radius: 30px;
        border-top-right-radius: 30px; */
  overflow-y: scroll;
  height: 70vh;
  /* border: 1px solid #eee; */
  min-width: 100%;
}
.title-report i {
  position: absolute;
  right: 20px;
  top: 16px;
  color: #000;
  cursor: pointer;
}
#report-ul {
  display: none;
}
button.usersearch {
  background: #fff;
  padding: 4px 10px;
  margin-left: 15px;
}
.usersearch img {
  width: 16px;
}
.listusr .asuser .wap-text a {
  font-size: 12px !important;
  text-decoration: unset !important;
}

.wap-text {
  display: flex;
  gap: 10px;
  padding-top: 9px;
  margin: 0 !important;
  flex-wrap: wrap;
}
.strip_promoteKit {
}
.strip_promoteKit_data {
}
.strip_promoteKit_data p {
  margin-bottom: 5px;
  color: #fff;
}
.strip_promoteKit_data a {
  background: #fff;
  border: navajowhite;
  padding: 10px 44px;
  font-weight: 600;
  border-radius: 5px;
  font-size: 16px;
  display: inline-block;
}
.strip_promoteKit_data_input {
  margin-top: 20px;
  margin-bottom: 50px;
}
.strip_promoteKit_data_input p {
  display: inline;
  margin-bottom: 0;
  color: #fff;
  margin-right: 10px;
}

.strip_promoteKit_data_input input {
  display: inline;
  width: 200px;
  height: 33px;
  background: transparent;
  color: #ffffff;
}

.strip_promoteKit_data_input button {
  margin-left: 10px;
  display: inline;
  /* width: 100px; */
  background: #fff;
  border: navajowhite;
  padding: 6px 20px;
  font-weight: 600;
  border-radius: 5px;
  font-size: 16px;
  display: inline-block;
}
